import React, {useState,useRef} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "../Img";
import SimpleBar from 'simplebar-react';
import Button from "../Button";
import {
    LightgalleryProvider,
    LightgalleryItem,
} from "react-lightgallery";
import ModalVideo from "react-modal-video";
import ReactHtmlParser from "react-html-parser";
import moment from 'moment';
import Modal from "react-bootstrap/Modal";
const ProjectList = ({data}) => {

    let [open, setOpen] = useState(false);
    let [videoId, setVideo] = useState('');

    let handelOpen = (open, id) => {
        setOpen(open);
        setVideo(id);
        setShow(true)
    };
    const modalRef = useRef(null);



    const [show, setShow] = useState(false);
    const [popupId, setPopupId] = useState()
    const [showVideo, setShowVideo] = useState(false)

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = (e) => {
        setShow(true)
        setPopupId(e)
        setVideo(true)
    }
    const handleCloseModal = () => {
        setOpen(false);
        setVideo('');
        handleClose();
    };




    return (
        <StyledProjectList>
            <Container>
                <Row>
                    {/*<ModalVideo channel='youtube' isOpen={open}*/}
                    {/*            videoId={'wT3cpXL7HiE'}*/}
                    {/*            onClose={() => handelOpen(false, '')}/>*/}
                    <Col md={12} onClick={() => handelOpen(true, data?.url)} key={0}>
                        <div className='project fade-up'>
                            <div className='project__img'>
                                <Img src={data?.thumb}></Img>
                                <div className="video__box__inner__click">
                                    <svg width="80" height="80" viewBox="0 0 60 60">
                                        <g id="Group_14000" data-name="Group 14000" transform="translate(0.49)">
                                            <g
                                                id="Ellipse_381"
                                                data-name="Ellipse 381"
                                                transform="translate(-0.49)"
                                                fill="#ffffff"
                                                stroke="none"
                                                stroke-width="1"
                                            >
                                                <circle cx="30" cy="30" r="30" stroke="none"></circle>
                                                <circle className="video-hover" cx="30" cy="30" r="30.5"
                                                        fill="#E50019"></circle>
                                            </g>
                                            <path
                                                id="Polygon_2"
                                                data-name="Polygon 2"
                                                d="M8.143,1.429a1,1,0,0,1,1.715,0l7.234,12.056A1,1,0,0,1,16.234,15H1.766a1,1,0,0,1-.857-1.514Z"
                                                transform="translate(39.51 21) rotate(90)"
                                                fill="#E50019"
                                            ></path>
                                        </g>
                                    </svg>
                                </div>

                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Modal
                show={show}
                // item={item}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                className="gph_modal modal_video_popup popup-version-one"
                dialogClassName="custom-modal-dialog"
            >
                <SimpleBar className="main_scroll " style={{height: '100vh'}}>
                    <Modal.Body>
                        <Container>
                            <Row className={'for-close'}>
                                <div onClick={handleCloseModal} className="modal-close ">

                                    <svg id="Button_-_Close" data-name="Button - Close" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                        <g id="Ellipse_18" data-name="Ellipse 18" fill="none" stroke="#3c3c3b" stroke-width="1" opacity="0.3">
                                            <circle cx="22" cy="22" r="22" stroke="none"/>
                                            <circle cx="22" cy="22" r="21.5" fill="none"/>
                                        </g>
                                        <g id="Ellipse_19" data-name="Ellipse 19" fill="none" stroke="#3c3c3b" stroke-width="1" stroke-dasharray="0 142" opacity={'0'}>
                                            <circle cx="22" cy="22" r="22" stroke="none"/>
                                            <circle cx="22" cy="22" r="21.5" fill="none"/>
                                        </g>
                                        <g id="Group_18979" data-name="Group 18979" transform="translate(-3149 -104.5)">
                                            <line id="Line_4" data-name="Line 4" x2="8" y2="8" transform="translate(3167 122.5)" fill="none" stroke="#3c3c3b" strokeLinecap="round" stroke-width="1"/>
                                            <line id="Line_3877" data-name="Line 3877" x1="8" y2="8" transform="translate(3167 122.5)" fill="none" stroke="#3c3c3b" strokeLinecap="round" stroke-width="1"/>
                                        </g>
                                    </svg>

                                </div>

                            </Row>
                        </Container>
                        <Container>
                            <Row>


                                <div className="modal-data d-flex">
                                    <ModalVideo channel='youtube' isOpen={open}
                                                videoId={data?.url}
                                                onClose={handleCloseModal}/>

                                </div>
                            </Row>
                        </Container>
                    </Modal.Body>
                </SimpleBar>
            </Modal>


        </StyledProjectList>
    );
};
const StyledProjectList = styled.section`
  background-color: #FFFFFF;
  position: relative;
  overflow: hidden;

  .overflow {
    overflow: hidden;
  }
  

  .col-lg-6 {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 70px;

    :nth-child(odd) {
      padding-right: 35px;
      padding-left: 15px;
    }

    :nth-child(even) {
      padding-right: 15px;
      padding-left: 35px;

    }

    @media (max-width: 991px) {
      :nth-child(odd) {
        padding-right: 15px;
      }

      :nth-child(even) {
        padding-left: 15px;

      }
    }
    //@media (max-width: 767px) {
    //  :nth-child(odd) {
    //    padding-right: 0px;
    //  }
    //  :nth-child(even) {
    //    padding-left: 0px;
    //  }
    //}
  }

  @media (max-width: 991px) {

    .shadow-right {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateX(75%);

      svg {
        width: 40% !important;
      }
    }

    .shadow-right {
      position: absolute;
      bottom: 30px;
      right: -20px;
      transform: none;

      svg {
        width: 40%;
      }
    }

    .shadow-left {
      transform: translateX(-20%);
      left: 0;

      svg {
        width: 50%;
      }
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 0;
    .col-lg-6 {
      margin-bottom: 40px;

      :nth-child(even) {
        padding-right: 15px;
        padding-left: 15px;
      }

      :nth-child(odd) {
        padding-right: 15px;
        padding-left: 15px;
      }
    }

    .shadow-right {
      position: absolute;
      bottom: 30px;
      right: -20px;
      transform: none;

      svg {
        width: 150px;
        height: 150px;
      }
    }

    .shadow-left {
      transform: none;
      left: 0;
      bottom: 30px;
      top: auto;

      svg {
        width: 50px;
        height: 50px;
      }
    }

    .dc-btn {
      margin-top: 30px !important;
      margin-bottom: 0 !important;
    }

  }
  @media (max-width: 1200px) {
    .shadow-right {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: none;

      svg {
        width: 40% !important;
      }
    }
  }

  @media (max-width: 767px) {
    .project__img__content {
      left: 20px;
    }
  };

  
  
  
  //project 
  .project {
    &__img {
      position: relative;
      padding-top: calc(700/1170*100%);
      //overflow: hidden;
      z-index: 4;
      cursor: pointer;

      a.wrapped {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        /* background: red; */
        z-index: 5;
      }

      &:after {
        width: 100%;
        height: 100%;
        position: absolute !important;
        top: 0px;
        content: '';
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
      }


      &__content {
        position: absolute;
        bottom: 35px;
        left: 40px;
        right: 40px;
        z-index: 1;
        //transform: translateY(calc(100% - 30px));
        @media (max-width: 992px) {
          font-size: 18px;
          line-height: 27px;
        };

        h2 {
          color: #F2EEE8;
          opacity: 1;
          font-weight: 400;
          font-size: 32px;
          line-height: 32px;
          //bottom: 36px;
          //transition: 0.5s;
        }


        p {
          font-size: 20px;
          color: #F2EEE8;
          line-height: 28px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 400;
          margin-bottom: 7px;
          opacity: 0.6;
          //transition: 0.5s;

        }

        // &__bottom {
        //   opacity: 1;
        // }
      }
    }
    &:hover {
      svg {
        .video-hover {
          cx: 30px;
          opacity: 1;
          transition: all 0.8s cubic-bezier(0.76, 0, 0.24, 1);
        }
        path{
          fill:#ffffff;
          transition: all 0.5s ease;
        }
      }
    }
  }

  .video__box__inner__click {
    position: absolute;
    z-index: 8;
    inset: 0px;
    width: fit-content;
    height: fit-content;
    margin: auto;
    overflow: unset !important;
    


    svg {
      overflow: hidden;
      border-radius: 50%;

      .video-hover {
        cx: -30px;
        opacity: 0;
        transition: all 1s cubic-bezier(0.76, 0, 0.24, 1);
      }
      path{
        transition: all 0.5s ease;
      }
    }

    

    p {
      font-size: 12px;
      font-weight: bold;
      line-height: 12px;
      color: #f2eee8;
      text-transform: uppercase;
      text-align: center;
      opacity: 0;
      position: absolute;
      transition: all .3s ease;
      transform: translateY(10px);
    }

   

  }


  @media (max-width: 768px) {
    .project__img__content__bottom {
      display: none;

    }

    .project__img__content {
      transform: none;
      bottom: 26px;

    }
  }

`

export default ProjectList;