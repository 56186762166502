import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link, Route} from "react-router-dom";

import {HelmetProvider, Helmet} from 'react-helmet-async';
import InnerBanner from "../../components/InnerBanner";
import Overview from "../../components/About/Overview";
import MissionVision from "../../components/About/MissionVision";
import CorporateValue from "../../components/About/CorporateValue";
import OurHeritage from "../../components/About/OurHeritage";
import Bod from "../../components/About/Bod";
import Chairperson from "../../components/About/Chairperson";
import Cta from "../../components/Cta";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchAbout} from "../../api/redux/About";

const MyComponent = ({offset}) => {
    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.ABOUT
        dispath(fetchAbout([api_url]))
    }, [])

    let getPost = useSelector(state => state.about);

    const page_title = getPost?.posts?.page_data?.[0]?.post_title;
    const banner = getPost?.posts?.page_data?.[0]?.banner;
    const counter = getPost?.posts?.page_data?.[0]?.counter;
    const mission_vision = getPost?.posts?.page_data?.[0]?.mission_vision;
    const about_value = getPost?.posts?.page_data?.[0]?.about_value;
    const heritage_slider = getPost?.posts?.page_data?.[0]?.heritage_slider;
    const about_bod = getPost?.posts?.page_data?.[0]?.about_bod;
    const chairperson = getPost?.posts?.page_data?.[0]?.chairperson;
    const about_career = getPost?.posts?.page_data?.[0]?.about_career;

    // Define the images for different viewports
    const largeImage = banner?.image;
    const mediumImage = banner?.image_tab;
    const smallImage = banner?.image_mobile;

    // Determine the viewport size and set the image accordingly
    let imgUrl;
    if (window.innerWidth >= 992) {
        imgUrl = largeImage;
    } else if (window.innerWidth >= 768) {
        imgUrl = mediumImage ? mediumImage : largeImage;
    } else {
        imgUrl = smallImage ? smallImage : largeImage;
    }
    let data = getPost?.posts?.page_data?.[0];
    let innerBanner = data?.banner?.image;
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${data?.meta?.meta_title ? data?.meta?.meta_title : 'Partex Star Group'}`}</title>
                {
                    data?.meta?.meta_desc &&
                    <meta name="description" content={data?.meta?.meta_desc}/>

                }
                {
                    data?.meta?.og_title &&
                    <meta property="og:title" content={data?.meta?.og_title}/>

                }
                {
                    data?.meta?.og_desc &&
                    <meta property="og:description" content={data?.meta?.og_desc}/>
                }
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={innerBanner?.image}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>
                <InnerBanner
                    img={imgUrl}
                    breadcrumbs={banner?.breadcrumbs}
                    title={banner?.title}
                    subtitle={banner?.description}
                />

                <Overview data={counter} />
                <MissionVision id={'mission-vision'} offset={offset} data={mission_vision} />
                <CorporateValue id={'core-value'} data={about_value} />
                <OurHeritage id={'our-heritage'} data={heritage_slider} />
                <Bod offset={offset} data={about_bod} />
                <Chairperson data={chairperson} />
                <Cta src={'/career'}
                    imgSrc={about_career?.background_img}
                    upperTitle={about_career?.title}
                    subTitle={about_career?.subtitle}
                    desc={about_career?.description}
                    btntxt={'Join Our Team'}
                    btnColor={'#323232'}
                    btnBg={'#E8E8E8'}
                />
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
  
`;

export default MyComponent;
