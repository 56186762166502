import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Navigation, Pagination, Scrollbar, A11y, Autoplay, Mousewheel} from 'swiper';
import Img from "../Img";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {LightgalleryItem, LightgalleryProvider} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";
import {CSSPlugin, gsap} from "gsap";
import {useLocation} from "react-router-dom";
import {SplitText} from "gsap/SplitText";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import reactHtmlParser from "react-html-parser";


const GalleryHome = ({data,id}) => {
const location = useLocation();

    gsap.registerPlugin(ScrollTrigger);

// GSAP

    // GSAP

    useEffect(() => {
        document.fonts.ready.then(() => {
            gsap.utils.toArray('.split-up-banner').forEach((item, i) => {
                const parentSplit = new SplitText(item, {
                    linesClass: "split-parent"
                })
                const childSplit = new SplitText(item, {
                    type: "lines, chars",
                    linesClass: "split-child"
                })
                gsap.from(parentSplit.lines, {
                    duration: 1,
                    delay: .8,
                    xPercent: 100,
                    stagger: 0,
                    scrollTrigger: {
                        trigger: item,
                        toggleActions: "restart none none reset",
                        start: "top 95%",
                        // markers: true
                    }
                })
            });

            gsap.utils.toArray('.split-up-banner-des').forEach((item, i) => {
                const parentSplit = new SplitText(item, {
                    linesClass: "split-parent"
                })
                const childSplit = new SplitText(item, {
                    type: "lines, chars",
                    linesClass: "split-child"
                })
                gsap.from(parentSplit.lines, {
                    duration: 0.8,
                    delay: 1,
                    xPercent: 100,
                    stagger: 0,
                    scrollTrigger: {
                        trigger: item,
                        toggleActions: "restart none none reset",
                        start: "top 95%",
                        // markers: true
                    }
                })
            });

        }, [location.pathname])
    });



    // <width control

    const sourceDivRef = useRef(null);
    const targetDivRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            const fullWidth = sourceDivRef.current.offsetWidth;
            const adjustedWidth = fullWidth - 30;
            document.documentElement.style.setProperty('--target-width', `${adjustedWidth}px`);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // height

    const sourceDivReff = useRef(null);
    useEffect(() => {
        const handleResize = () => {
            const fullWidth = sourceDivReff.current.offsetWidth;
            const adjustedWidth = fullWidth - 100;
            const adjustedhieght = fullWidth / 1.44;
            document.documentElement.style.setProperty('--height', `${adjustedhieght}px`);
            document.documentElement.style.setProperty('--target-width-height', `${adjustedWidth}px`);
            document.documentElement.style.setProperty('--target-width-height-body', `${adjustedWidth + 300}px`);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);




    const PhotoItem = ({image, group}) => (
        <LightgalleryItem group={group} src={image}>
            <img alt='' src={image} style={{width: "100%"}}/>
        </LightgalleryItem>
    );
    const next = () => {
        document.querySelector('.mySwiper .swiper-button-next').click();
        console.log("Clicked");
    }

    const prev = () => {
        document.querySelector('.mySwiper .swiper-button-prev').click();
    }
    const [offset, setOffset] = useState(100)


    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)

        window.addEventListener('load', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
    }, [])

    return (
        <StyledGallery id={'our-heritage'} className="gallery-slider" offset={offset}>
            <Container>
                {data?.subtitle && <p className={'split-left'} style={paragraphStyle}>{(data?.subtitle)}</p>}
                <Row>
                    <Col md={8} ref={sourceDivRef} className="gallery-slider__text">

                        <div className={'gallery-slider__text__top'}>
                            <div className="gallery-slider__text__subtitle">
                                {data?.title && <h3 className={'split-up'}>{reactHtmlParser(data?.title)}</h3>}
                            </div>
                        </div>
                    </Col>
                    <Col md={4} ref={sourceDivReff} >
                            <div className="gallery-slider__text__title-arrow">
                                <ul className="gallery-slider__text__title-arrow__arrow">
                                    <li onClick={prev} className="left">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 52 52">
                                            <g id="Button_-_Nav_Button" data-name="Button - Nav Button"
                                               transform="translate(52 52) rotate(180)">
                                                <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                        fill="#323232"></circle>
                                                <circle id="Ellipse_4378" data-name="Ellipse 437" cx="-26" cy="26" r="26"
                                                        fill="#E50019"></circle>
                                                <g id="Group_15984" data-name="Group 15984"
                                                   transform="translate(-96 -5894.5)">
                                                    <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                                          transform="translate(120.5 5915.5)" fill="none" stroke="#D80028"
                                                          stroke-linecap="round" stroke-width="2"></line>
                                                    <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                          transform="translate(120.5 5920.5)" fill="none" stroke="#D80028"
                                                          stroke-linecap="round" stroke-width="2"></line>
                                                </g>
                                            </g>
                                        </svg>
                                    </li>
                                    <li  onClick={next} className="right">
                                        <svg id="Button_-_Nav_Button" data-name="Button - Nav Button"
                                             xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 52 52">
                                            <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                    fill="#323232"></circle>
                                            <circle id="Ellipse_4378" data-name="Ellipse 437" cx="-26" cy="26" r="26"
                                                    fill="#323232"></circle>
                                            <g id="Group_15984" data-name="Group 15984" transform="translate(-96 -5894.5)">
                                                <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                                      transform="translate(120.5 5915.5)" fill="none" stroke="#D80028"
                                                      stroke-linecap="round" stroke-width="2"></line>
                                                <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                      transform="translate(120.5 5920.5)" fill="none" stroke="#D80028"
                                                      stroke-linecap="round" stroke-width="2"></line>
                                            </g>
                                        </svg>
                                    </li>
                                </ul>
                            </div>
                    </Col>
                </Row>
            </Container>

            <div className="clearfix"></div>
            <Container fluid>
                <LightgalleryProvider>
                    {
                        data?.heritage &&
                    <Swiper
                        loop={true}
                        spaceBetween={15}
                        speed='1000'
                        slidesPerView={5}
                        keyboardControl={true}
                        initialSlide={1}
                        modules={[ Pagination, Navigation, Mousewheel]}
                        navigation
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 0,

                            },
                            1024: {
                                slidesPerView: 1,
                                spaceBetween: 15,

                            },
                            1025: {
                                slidesPerView: 5,
                                spaceBetween: 30,
                            },
                        }}
                        className="mySwiper"
                    >
                        {data?.heritage?.map((item, index) => (
                            <SwiperSlide key={index}>
                                <div className={'image-flex'}>
                                    <div className="image_wrapper">
                                        <PhotoItem image={item?.image ? item?.image : 'images/static/blur.jpg'} alt=""/>
                                    </div>
                                    <div className={'image_wrapper__text-col'}>
                                        <div className={'image_wrapper__text'}>
                                            <h3>{item?.year}</h3>
                                            <p>{item?.description}</p>
                                        </div>
                                    </div>
                                </div>

                            </SwiperSlide>
                        ))}

                    </Swiper>
                    }
                </LightgalleryProvider>
            </Container>

        </StyledGallery>
    );
};


const paragraphStyle = {
    fontWeight: 500,
    color: '#323232',
    fontSize: '20px',
    lineHeight: '24px',
    paddingBottom: '20px',
};

const StyledGallery = styled.section`

  //padding-bottom: 100px;
  overflow: hidden;
  position: relative;

  .gallery-slider__text{
    &__top{
      display: flex;
      justify-content: space-between;
      @media(max-width: 767px){
        flex-direction: column;
      }
    }
   
    &__subtitle h3{
      margin: 0px 0px 60px;
      color: black;
      margin-bottom: 80px;
      @media (max-width: 767px){
        margin-bottom: 40px;
      }
      span{
        color:#E50019;
      }
    }
    &__title-arrow{
      display: flex;
      margin-bottom: 60px;
      justify-content: flex-end;

      @media (max-width: 767px){
        flex-direction: column;
      }
      &__title {
        margin-right: 20px;
      }
      &__arrow{
        display: flex;
        gap: 20px;
      }
    }
  }
  svg {
    border-radius: 50px;
    #Ellipse_4378 {
      transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
      r: 26;
      cursor: pointer;
    }

    line {
      stroke: white;
    }

    &:hover {
      #Ellipse_4378 {
        fill: #E50019;
        transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
        cx: 26;
      }

      g {
        line {
          stroke: #f9f9f9;
        }
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1024px){
    padding-bottom: 150px;
  }
  

  .swiper{
    height: var(--target-width-height-body);
    @media (max-width: 1024px){
      padding-left: unset;
    }
    @media (max-width: 787px){
      height: 100%;
    }
  }

  .image_wrapper__text-col{
    overflow: hidden;
    padding: unset;
    .image_wrapper__text{
      overflow: hidden;
      h3,p{
        transform: translateY(-100px);
        opacity: 0;
        
      }
    }
  }
  


  @media (max-width:767px){
    .gallery-slider__right p{
      padding-bottom: 40px;
    }
    .gallery-slider__text__subtitle h3{
      margin: unset;
      padding-bottom: 40px;
    }
    .image_wrapper__text-col{
      padding: unset;
      width: unset;
      height: unset;
    }
  }

  .container-fluid{
    padding: 0;
    @media (min-width: 768px) and (max-width: 1024px){
      padding: 0 30px;
      .swiper-wrapper{
        padding-left: unset;
      }
    }
  }
 
  .swiper-wrapper {
    padding-left: ${props => props.offset ? props.offset + 15 + 'px' : '30px'};
    @media (max-width: 991px){
      padding-left: 0;
    }
    @media (max-width: 767px){
      padding-left: 0px;
    }
  }
  .swiper-slide{
    display: flex;
    flex-direction: column;
    .image_wrapper{
      position: relative;
      padding-top: calc(290/270*100%);
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height:var(--height)!important;
        object-fit: cover;
      }
    }
    .col{
      padding: unset!important;
    }
    @media (max-width: 991px){
      padding-right: 15px !important;
      padding-left: 15px !important;
    }
    @media (max-width: 767px){
      width: 100% !important;
      padding-right: 15px !important;
      padding-left: 15px !important;
    }
    @media (min-width: 768px) and (max-width: 1024px){
      width:100%!important;
      .image_wrapper{
        padding-top: calc(290/700*100%);
        img{
          height: 100%!important;
        }
      }
    }
  }
  .swiper-slide.swiper-slide-active {
    width: var(--target-width)!important;
    .image_wrapper{
      position: relative;
      padding-top: 36.5%;
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .image_wrapper__text-col{
      position: relative;
      padding-top: 40px;
    }
    .image_wrapper__text{
      display: flex;
      flex-direction: unset;


      h3{
        transform: translateY(0px);
        opacity: 1;
        transition: transform 1s ease, opacity 1s ease;
        transition-delay: .5s;
        padding-right: 30%;
        color: #E50019;
        @media(max-width: 767px){
          padding-right: unset;
        }
      }

      p{
        transform: translateY(0px);
        opacity: 1;
        transition: transform 1s ease, opacity 1s ease;
        transition-delay: .5s;
        font-weight: 500;
      }

      @media (max-width: 767px){
        padding-top: unset;
        flex-direction: column;
      }

    }
  
    @media (max-width: 767px){
      width: 100% !important;
      margin-right: 0px !important;
      flex-direction: column;
    }
    @media (min-width: 768px) and (max-width: 1024px){
      width:100%!important;
      .image_wrapper{
        padding-top: calc(290/700*100%);
        img{
          height: 100%!important;
        }
      }
    }
  }
  .swiper-button-next, .swiper-button-prev  {
    display: none;
  }
  

  .swiper-slide {
    transition: 0.7s all ease-in-out;
  }
  @media (max-width: 870px) {
    .gallery-slider__text__title-arrow ul{
      align-items: center!important;
    }
  }

  @media (min-width: 768px){
    .mobile-flex-svg{
      display: none;
    }
  }
  @media (max-width: 767px) {
    padding-bottom: 150px;

    .mobile-hide {
      display: none;
    }

    .mobile-flex {
      display: flex;
      justify-content: space-between;

      a {
        margin-left: auto;
      }

      ul {
        display: flex;

        li {
          margin-right: 20px;
        }
      }

      .mobile-flex-svg {
        display: block;
      }
    }

    .swiper-slide {
      height: 300px;
    }

    .global-image {
      img {
        object-fit: contain;
        top: 0;
        margin-top: 40%;
      }
    }
    .swiper-slide{
      height: 360px;
      .image_wrapper{
        img{
          height: 160px!important;
        }
      }
    }
    .image_wrapper{
      padding-top: calc(290/770*100%)!important;
      &__text{
        overflow: hidden;
        h3{
          color: #E50019;
          font-size: 48px;
          font-weight: 500;
          line-height: 56px;
          padding-bottom: 20px;
        }
        p{
          font-weight: 500;
        }
      }
    }
  }

`;

export default GalleryHome;
