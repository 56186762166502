import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import {Link} from "react-router-dom";

const SisterConcernProduct = () => {
    return (
        <StyledComponent className={'pt-150 pb-150'}>

            <Container>
                <Img src={'images/dynamic/banner/banner-2.jpg'} alt={'background-image'}/>
                <Row>
                   <Col>
                       <Row>
                           <Col lg={4} md={6} className={'left'}>
                               <a target={'_blank'} href={'http://www.danishbd.com/'}>
                                   <div className={'image-wrapper'}>
                                       <img src={'images/dynamic/danish.png'} alt={'logo'}/>
                                       <img src={'images/dynamic/danish.jpg'} alt={'bg'}/>
                                   </div>
                               </a>
                           </Col>
                           <Col lg={4} md={6} className={'right'}>
                               <a target={'_blank'} href={'http://www.partexpaper.com/'}>
                                   <div className={'image-wrapper'}>
                                       <img src={'images/dynamic/partex-paper.png'} alt={'logo'}/>
                                       <img src={'images/dynamic/partex-paper.jpg'} alt={'logo'}/>
                                   </div>
                               </a>
                           </Col>
                           <Col lg={4} md={6} className={'left'}>
                               <a target={'_blank'} href={'https://www.amber.com.bd/'}>
                                   <div className={'image-wrapper'}>
                                       <img src={'images/dynamic/partex-amber.png'} alt={'logo'}/>
                                       <img src={'images/dynamic/partex-amber.jpg'} alt={'logo'}/>
                                   </div>
                               </a>
                           </Col>
                           <Col lg={4} md={6} className={'right'}>
                               <a target={'_blank'} href={'http://www.partex.net/'}>
                                   <div className={'image-wrapper'}>
                                       <img src={'images/dynamic/partex-beverage.png'} alt={'logo'}/>
                                       <img src={'images/dynamic/partex-beverage.jpg'} alt={'logo'}/>
                                   </div>
                               </a>
                           </Col>
                           <Col lg={4} md={6} className={'left'}>
                               <a target={'_blank'} href={'http://www.partexpetro.com/'}>
                                   <div className={'image-wrapper'}>
                                       <img src={'images/dynamic/partex_petro.png'} alt={'logo'}/>
                                       <img src={'images/dynamic/partex_petro.jpg'} alt={'logo'}/>
                                   </div>
                               </a>
                           </Col>
                       </Row>
                   </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  min-height: 100vh;
  position: relative;

  .container {
    height: 100%;

    .row {
      align-items: center;
      height: 100%;
      //gap: 30px;

      .image-wrapper {
        position: relative;
        padding-top: calc(300 / 300 * 100%);
        transform: scale(1);
        transition: 0.5s ease transform;
        &:after {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          z-index: 1;
          background: rgba(255, 255, 255, 0.75);
        }

        img {
          position: absolute;
          z-index: 10;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;

          &:last-child {
            z-index: 1;
          }
        }

        &:hover {
          transform: scale(1.05);
          transition: 0.5s ease transform;
          border: unset;
          will-change: transform;
        }
      }

      .col-lg-4:nth-child(-n+3) {
        padding-bottom: 30px;
      }
    }
  }

  @media (max-width: 767px) {
    .row {
      align-items: center;
      gap: 15px;
      justify-content: center;

      .col-lg-4{
        padding-bottom: 0!important;
      }
      .left, .right {
        width: calc(50% - 30px);
        padding: 0;
      }
    }
  }
  @media (min-width: 767px) and (max-width: 991px) {
    .row {
      align-items: center;
      height: 100%;
      gap: 30px !important;
      justify-content: center;

      .left, .right {
        padding: 0;
        width: calc(50% - 30px);
        max-width: calc(50% - 30px);
      }
    }
  }
`;

export default SisterConcernProduct;
