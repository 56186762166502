import {configureStore} from "@reduxjs/toolkit";
import postReducer from '../redux/post';
import homeReducer from '../redux/Home';
import aboutReducer from '../redux/About';
import livinglegendReducer from '../redux/LivingLegend';
import mediacenterReducer from '../redux/MediaCenter';
import contactReducer from '../redux/Contact';
import careerReducer from '../redux/Career';
import bodReducer from '../redux/Bod';
import keyexecutivesReducer from '../redux/KeyExecutives';
import csrReducer from '../redux/Csr';
import tvcReducer from '../redux/Tvc';
import concernReducer from '../redux/Concern';
import productlistReducer from '../redux/ProductList';
import dealerListReducer from '../redux/DealerList';
import concernexternalReducer from '../redux/ConcernExternal';


const store = configureStore({
    reducer: {
        // globalReducer,
        posts: postReducer,

        // shawon
        home: homeReducer,

        // shuvo
        about: aboutReducer,
        livinglegend: livinglegendReducer,
        contact: contactReducer,
        career: careerReducer,
        mediacenter: mediacenterReducer,
        bod: bodReducer,
        keyexecutives: keyexecutivesReducer,
        csr: csrReducer,
        tvc: tvcReducer,
        concern: concernReducer,
        productlist: productlistReducer,
        concernexternal: concernexternalReducer,
        dealerlist: dealerListReducer,

    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
})

export default store

