import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import reactHtmlParser from "react-html-parser";

const Overview = ({data,id}) => {
    return (
        <StyledComponent id={`${id ? id : 'core-value'}`}  className='pt-200 pb-200 horizontal-scroll' data-speed-right={50} data-speed-left={-50}>
            <p className={'scroll-right opacity-30 bg-text-one'}>CORPORATE</p>
            <p className={'scroll-left opacity-30 bg-text-two'}>VALUES</p>
            <Container>
                <Row>
                    <Col lg={{span:9, offset:2}}>
                        {data?.title && <h4 className={'split-up'}>{reactHtmlParser(data?.title)}</h4>}
                    </Col>

                    <Col lg={{span:8, offset:4}}>
                        <Row>
                            {data?.value?.length > 0 && data?.value?.map((item, index) => (
                                <Col lg={6} className={'counter-item'} key={index}>
                                    {item?.title && <h4 className={'split-left'}>{reactHtmlParser(item?.title)}</h4>}
                                    {item?.description && <p>{reactHtmlParser(item?.description)}</p>}
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  
  
  .container{
    padding-top: 200px;
  }
  .row {
    position: relative;
  }

  .bg-text-one, .bg-text-two {
    font-size: 240px;
    line-height: 240px;
    font-weight: 700;
    margin: 0;
    color: rgba(232, 232, 232);
    position: absolute;
    white-space: nowrap;
  }

  .bg-text-one {
    left: -15%;
    top:200px;
  }

  .bg-text-two {
    right: -5%;
    top:400px;
  }

  h4 {
    margin: 0 0 80px 0;

    span {
      color: #E50019;
    }
  }

  p {
    margin: 0 0 80px 0;
  }

  .counter-item {
    margin-bottom: 80px;
    counter-increment: count;
    position: relative;
    padding: 0 15px 0 100px;

    &:before {
      content: counter(count, decimal-leading-zero);
      font-size: 72px;
      line-height: 84px;
      font-weight: 400;
      color: #E50019;
      position: absolute;
      left: 0;
      top: 0;
    }
    
    h4 {
      margin: 0 0 20px 0;
    }

    p {
      color: #323232;
      margin: 0;
    }
    
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
    
    &:nth-last-child(2) {
      margin-bottom: 0;
    }
  }

  @media (max-width: 991px) {
    .bg-text-one, .bg-text-two {
      font-size: 120px;
      line-height: 120px;
    }
    
    .bg-text-one {
      top: -60px;
      bottom: unset;
    }

    .bg-text-two {
      right: -5%;
      top: 120px;
      bottom: unset;
    }
    
    .counter-item {
      padding: 0 15px 0 115px;

      &:nth-child(3) {
        margin-bottom: 80px;
      }

      &:before {
        left: 15px;
      }
    }
    .container{
      padding-top: 0px;
    }
  }

`;

export default Overview;
