export const apiEndPoints = {
    PAGE_DATA:'page-data',
    POSTS: 'posts',


    CAREER_FORM: 'contact-form-7/v1/contact-forms/231/feedback',
    CONTACT_FORM: 'contact-form-7/v1/contact-forms/222/feedback',
    HOME: 'page/home',
    ABOUT: 'page/partex-star-group',
    CONTACT: 'page/contact',
    CAREER: 'page/career',
    MEDIA: 'page/media-center',
    MEDIA_DETAIL: 'media',
    LIVINGLEGENDS: 'page/a-visionarys-legacy',
    BOARDOFDIRECTORS: 'page/board-of-directors',
    KEYEEXCUTIVES: 'page/key-executives',
    CSR: 'page/csr',
    TVC: 'page/tvc',
    PRODUCTLIST: 'page/product-list',
    CONCERN: 'concern',
    CONCERNPDF: 'concern/pdf-list',
    CONCERN_EXTERNAL: 'external-concern',
    DEALERLIST: 'getDealer/all',
    DEALERSEARCH: 'search/dealer',
    PRODUCTSEARCH: 'search/product'
    
};
