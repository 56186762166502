import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link, Route} from "react-router-dom";

import {HelmetProvider, Helmet} from 'react-helmet-async';
import TvcList from "../../components/Tvc/TvcList";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchTvc} from "../../api/redux/Tvc";
import DirectorsGallery from "../../components/BoardOfDirectors/DirectorsGallery";


const MyComponent = () => {

    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.TVC
        dispath(fetchTvc([api_url]))
    }, [])

    let getPost = useSelector(state => state.tvc); //store
    const page_title = getPost?.posts?.page_data?.[0]?.post_title;
    const videolist = getPost?.posts?.page_data?.[0]?.tvc;

    let data = getPost?.posts?.page_data?.[0];
    let innerBanner = data?.banner?.image;
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${data?.meta?.meta_title ? data?.meta?.meta_title : 'Partex Star Group'}`}</title>
                {
                    data?.meta?.meta_desc &&
                    <meta name="description" content={data?.meta?.meta_desc}/>

                }
                {
                    data?.meta?.og_title &&
                    <meta property="og:title" content={data?.meta?.og_title}/>

                }
                {
                    data?.meta?.og_desc &&
                    <meta property="og:description" content={data?.meta?.og_desc}/>
                }
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={innerBanner?.image}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>
                {videolist?.length > 0 && <TvcList data={videolist}/> }
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
