import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {Route} from "react-router-dom";
import LivingLegacy from "../../components/LivingLegend/LivingLegacy";
import LivingLegendSlider from "../../components/LivingLegend/LivingLegendSlider";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchLivingLegend} from "../../api/redux/LivingLegend";
import DirectorsGallery from "../../components/BoardOfDirectors/DirectorsGallery";

const LivingLegendd = () => {
    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.LIVINGLEGENDS
        dispath(fetchLivingLegend([api_url]))
    }, [])

    let getPost = useSelector(state => state.livinglegend); //store
    const page_title = getPost?.posts?.page_data?.[0]?.post_title;
    const founderImage = getPost?.posts?.page_data?.[0]?.founder;
    const founderSlider = getPost?.posts?.page_data?.[0]?.legend_slider;

    let data = getPost?.posts?.page_data?.[0];
    let innerBanner = data?.banner?.image;

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${data?.meta?.meta_title ? data?.meta?.meta_title : 'Partex Star Group'}`}</title>
                {
                    data?.meta?.meta_desc &&
                    <meta name="description" content={data?.meta?.meta_desc}/>

                }
                {
                    data?.meta?.og_title &&
                    <meta property="og:title" content={data?.meta?.og_title}/>

                }
                {
                    data?.meta?.og_desc &&
                    <meta property="og:description" content={data?.meta?.og_desc}/>
                }
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={innerBanner?.image}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>
                <LivingLegacy img={founderImage?.image} desc={founderImage?.description}
                              designation={founderImage?.designation} name={founderImage?.name}/>
                {founderSlider?.slider?.length > 0 &&
                    <LivingLegendSlider data={founderSlider?.slider} title={founderSlider?.title}/>}
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default LivingLegendd;
