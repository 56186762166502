import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import {BsChevronDown} from "react-icons/bs";

import Button from "../Button";
import reactHtmlParser from "react-html-parser";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {postForm} from "../../api/redux/Contact";
import {apiEndPoints} from "../../api/network/apiEndPoints";

const ContactForm = () => {
    const dispatch = useDispatch();
    const responseData = useSelector(state => state.contact);

    const {register, handleSubmit, formState, reset} = useForm({mode:'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const handleBlur = () => {
        // Perform onBlur logic here
    };

    const handleChange = () => {
        // Perform onChange logic here
    };

    const onSubmit = (e) => {
        let api_services = apiEndPoints.CONTACT_FORM;

        var formData = new FormData();
        formData.append('your-name', e?.name);
        formData.append('your-email', e?.email);
        formData.append('your-phone', e?.phone);
        formData.append('your-message', e?.message);

        if (e.email !== '' && e.name !== '' && e.phone !== '') {
            dispatch(postForm([api_services, formData]));
            success('Successfully Submitted')
            reset();
        }
    };
    let count=0;
    const onError = (errors) => {
        console.log(errors)
        Object.values(errors).forEach((error) => {
            count++;
        });
        if(count>0){
            toast.error('please fill out the correct inputs');
        }
        count=0;
    };
    useEffect(() => {
        if (responseData && responseData?.error !== '') {
            error(responseData?.error)
        }
        if (responseData && responseData?.success !== '') {
            // success(responseData?.success)
        }

    }, [responseData])


    return (
        <StyledContactForm className='pt-200 pb-200 horizontal-scroll' data-speed-right={50} data-speed-left={-50}>
            <Container >
                <Row>
                    <p className={'scroll-right opacity-30 bg-text-one'}>CUSTOMER</p>
                    <p className={'scroll-left opacity-30 bg-text-two'}>SUPPORT</p>

                    <Col md={{span:9, offset:3}}>
                        <h4 className={'split-left'}>{reactHtmlParser('Get in touch today!')}</h4>

                        <Form onSubmit={handleSubmit(onSubmit, onError)}>
                            <input name={'spam_protector'} type='hidden' />

                            <Form.Control
                                className={formState?.errors?.name?.message ? 'has-error' : ''}
                                {...register("name", {
                                    required:{
                                        value:true,
                                        message:'please enter your name first'
                                    },
                                    pattern: {
                                        value: /^[^\d]+$/, // This regular expression allows only non-digit characters
                                        message: "Name cannot contain digits",
                                    }
                                })}
                                type="text"
                                placeholder="Name*"
                            />


                        <div className="form__phoneEmail">

                                <Form.Control className={formState?.errors?.phone?.message ? 'has-error' : ''}
                                              {...register("phone",{
                                                  required:{
                                                      value:true,
                                                      message:'please enter your phone first'
                                                  },
                                                  pattern:{
                                                      value:/^01[0-9]{9}$/,
                                                      message:'please enter a valid 11 digit phone number'
                                                  }
                                              })}
                                              type="number"
                                              placeholder="Phone Number*"/>
                                <Form.Control  className={formState?.errors?.email?.message ? 'has-error' : ''}
                                               {...register("email",{
                                                   required:{
                                                       value:true,
                                                       message:'please enter your email'
                                                   },
                                                   pattern:{
                                                       value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i,
                                                       message:'please enter a valid email address'
                                                   }
                                               })}
                                               type="email"
                                               placeholder="Email*"/>
                            </div>

                            <Form.Control className={formState?.errors?.email?.message ? 'has-error' : ''}
                                          {...register("message",{
                                              required:{
                                                  // value:true,
                                                  message:'please enter your message'
                                              },

                                          })}
                                          type="textarea"
                                          placeholder="Message"/>


                            <div onClick={handleSubmit(onSubmit,onError)} className={'button-wrapper'}>
                                <Button text={'Submit Message'} color={'#323232'} background={'#E8E8E8'}/>
                            </div>

                        </Form>
                    </Col>
                </Row>


            </Container>
        </StyledContactForm>
    );
};

const StyledContactForm = styled.section`
  //position: relative;
  //background: #ffffff;

  @media (max-width: 767px) {
    &.pt-200 {
      padding-top: 150px;
    }

    &.pb-200 {
      padding-bottom: 150px;
    }
  }

  .row {
    position: relative;
  }

  .bg-text-one, .bg-text-two {
    font-size: 240px;
    line-height: 240px;
    font-weight: 700;
    margin: 0;
    color: #E8E8E8;
    position: absolute;
    white-space: nowrap;


    @media (max-width: 767px) {
      font-size: 120px;
      line-height: 120px;
    }
  }

  .bg-text-one {
    left: 0;
    bottom: 240px;

    @media (max-width: 767px) {
      bottom: unset;
      top: -40px;
    }
  }

  .bg-text-two {
    right: -20%;
    bottom: unset;
    top:100px;
  }

  h4 {
    color: #E50019;
    margin-bottom: 80px;
    //padding-bottom: 10px;
  }

  .form-control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #323232;
    margin-bottom: 40px;
    color: #323232;
    padding-left: 0;

    &::placeholder {
      color: #323232;
    }
  }
  
  .button-wrapper{
    width: fit-content;
  }

  .form__phoneEmail {
    display: flex;
    gap: 20px;

    input {
      width: 49% !important;
    }

    @media (max-width: 767px) {
      gap: 0;
      flex-direction: column;
      width: 100%;

      input {
        width: 100% !important;
      }
    }
  }

  input[type="textarea"] {
    padding-top: 10px;
    padding-bottom: 80px;
  }

  .button-group {
    display: flex;
    gap: 40px;
    margin-bottom: 10px;

    .attach-cv {
      background-color: #323232;
      width: 178px;
      cursor: pointer;
      border: 1px solid #fff;
      border-radius: 28px;
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      label {
        padding-top: 5px;
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        color: #FFFFFF;
        margin-bottom: 0;
        cursor: pointer;
      }
    }

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .dc-btn {
      width: 162px;
      height: 49px;
    }
  }

  .form-control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(22, 31, 19, 0.2);
    margin-bottom: 40px;
    //color: #161F13;
    padding-left: 0;

    &::placeholder {
      //color: #161F13;
    }

    &:focus {
      border-bottom: 1px solid rgba(22, 31, 19, 0.2);
    }
  }


  p {
    color: #FFFDFB;
    margin-bottom: 60px;

    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }
`;

export default ContactForm;
