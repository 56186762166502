import {useEffect, useLayoutEffect, useRef} from "react";
import {gsap} from "gsap";
import {useLocation} from "react-router-dom";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
export const Parallax = () => {
    const location = useLocation();

    useEffect(() => {
            ScrollTrigger.refresh();
        gsap.utils.toArray('.global-image-parallax').forEach(container => {
            const img = container.querySelector('img');
            let parallaxSpeed = container.getAttribute('speed');
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: container,
                    scrub: true,
                    pin: false,
                }
            });

            tl.fromTo(img, {
                yPercent: parallaxSpeed ? -parallaxSpeed : -30,
                scale: 1.2,
                ease: 'none'
            }, {
                yPercent: parallaxSpeed ? parallaxSpeed : 30,
                scale: 1,
                ease: 'none'
            });
        });
    }, [location.pathname])
    return null; // Assuming you are not rendering anything in this component
}



