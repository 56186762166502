import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Navigation, Pagination, Scrollbar, A11y, Autoplay, Mousewheel} from 'swiper';
import Img from "../Img";
import { Swiper, SwiperSlide } from 'swiper/react';
import reactHtmlParser from "react-html-parser";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {hover} from "../../styles/globalStyleVars";
import {LightgalleryItem, LightgalleryProvider} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";
import Button from "../Button";
import {Link, useLocation} from "react-router-dom";

import { TweenLite } from 'gsap';

const GalleryHome = ({data}) => {

    const sourceDivRef = useRef(null);
    const containerRef = useRef(null);

    const [swiperInstance, setSwiperInstance] = useState(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    swiperInstance?.autoplay?.start();
                } else {
                    swiperInstance?.autoplay?.stop();
                }
            },
            { threshold: 0.1 } // Adjust this threshold as needed
        );

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, [swiperInstance]);

    useEffect(() => {
        const handleResize = () => {
            const fullWidth = sourceDivRef.current.offsetWidth;
            const adjustedWidth = fullWidth-30;
            const adjustHeight = (fullWidth) / (1.23);
            document.documentElement.style.setProperty('--target-width', `${adjustedWidth}px`);
            document.documentElement.style.setProperty('--target-height', `${adjustHeight}px`);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        if (window.innerWidth > 1400){
            var heightblogSingle = document.querySelector('.blog-single__inner')?.clientHeight / 2;
            var total = heightblogSingle / 2;
            var totall = total / 2;
            var totalHeight = (totall + 620);

            document.querySelector('.bc-image ').style = {};
            document.querySelector('.bc-image ').style.height = (totalHeight) + 'px';
        }else{
            var heightblogSingle = document.querySelector('.blog-single__inner')?.clientHeight / 2;
            var total = heightblogSingle / 2;
            var totall = total / 2;
            var totalHeight = (totall + 630);

            document.querySelector('.bc-image ').style = {};
            document.querySelector('.bc-image ').style.height = (totalHeight) + 'px';
        }

    })



// offset

    const [offset, setOffset] = useState(100)


    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)

        window.addEventListener('load', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
    }, [])


    return (
        <StyledGallery className="gallery-slider pt-200 pb-200" offset={offset} ref={containerRef}>
            <div className={'bc-image'}>
                <img src={'/images/static/gallery-bg.jpg'}/>
            </div>
            <Container>
                <div className={'gallery-slider__subtitle'}>
                    {data?.subtitle && <p className="sub-title split-left">{data?.subtitle}</p>}
                </div>
                <Row>
                    <Col md={6}  className="gallery-slider__text">
                        <div className="gallery-slider__text__subtitle">
                            {data?.title && <h3 className={'split-up'}>{reactHtmlParser(data?.title)}</h3>}
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={'gallery-slider__right'}>

                            {data?.description && <p>{data?.description}</p>}

                            <div className={'mobile-flex'}>
                                <div className="gallery-slider__text__title-arrow mobile-flex-svg">
                                    <ul>
                                        <li id={'prev'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 52 52">
                                                <g id="Button_-_Nav_Button" data-name="Button - Nav Button"
                                                   transform="translate(52 52) rotate(180)">
                                                    <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                            fill="#323232"></circle>
                                                    <circle id="Ellipse_4378" data-name="Ellipse 437" cx="-26" cy="26" r="26"
                                                            fill="#E50019"></circle>
                                                    <g id="Group_15984" data-name="Group 15984"
                                                       transform="translate(-96 -5894.5)">
                                                        <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                                              transform="translate(120.5 5915.5)" fill="none" stroke="#D80028"
                                                              stroke-linecap="round" stroke-width="2"></line>
                                                        <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                              transform="translate(120.5 5920.5)" fill="none" stroke="#D80028"
                                                              stroke-linecap="round" stroke-width="2"></line>
                                                    </g>
                                                </g>
                                            </svg>
                                        </li>
                                        <li  id={'next'} className="right">
                                            <svg id="Button_-_Nav_Button" data-name="Button - Nav Button"
                                                 xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 52 52">
                                                <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                        fill="#323232"></circle>
                                                <circle id="Ellipse_4378" data-name="Ellipse 437" cx="-26" cy="26" r="26"
                                                        fill="#323232"></circle>
                                                <g id="Group_15984" data-name="Group 15984" transform="translate(-96 -5894.5)">
                                                    <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                                          transform="translate(120.5 5915.5)" fill="none" stroke="#D80028"
                                                          stroke-linecap="round" stroke-width="2"></line>
                                                    <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                          transform="translate(120.5 5920.5)" fill="none" stroke="#D80028"
                                                          stroke-linecap="round" stroke-width="2"></line>
                                                </g>
                                            </svg>
                                        </li>
                                    </ul>
                                </div>
                                <div className={'desktop-hide'}>
                                    {/*<Button color={'white'} background={'#323232'} text={'View All'} borderRadius={'50'} />*/}
                                </div>
                            </div>
                        </div>
                    </Col>
                    {/*<Col>*/}
                    {/*    <Button color={'white'} background={'#323232'} text={'View All'} borderRadius={'50'} />*/}
                    {/*</Col>*/}
                </Row>
                <Row>
                    <Col md={6} ref={sourceDivRef} style={{ width: '100%'}} >
                        <div className="gallery-slider__text__title-arrow">
                            <ul className="gallery-slider__text__title-arrow__arrow">
                                <li id={'prev'} className="left">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 52 52">
                                        <g id="Button_-_Nav_Button" data-name="Button - Nav Button"
                                           transform="translate(52 52) rotate(180)">
                                            <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                    fill="#323232"></circle>
                                            <circle id="Ellipse_4378" data-name="Ellipse 437" cx="-26" cy="26" r="26"
                                                    fill="#E50019"></circle>
                                            <g id="Group_15984" data-name="Group 15984"
                                               transform="translate(-96 -5894.5)">
                                                <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                                      transform="translate(120.5 5915.5)" fill="none" stroke="#D80028"
                                                      stroke-linecap="round" stroke-width="2"></line>
                                                <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                      transform="translate(120.5 5920.5)" fill="none" stroke="#D80028"
                                                      stroke-linecap="round" stroke-width="2"></line>
                                            </g>
                                        </g>
                                    </svg>
                                </li>
                                <li  id={'next'} className="right">
                                    <svg id="Button_-_Nav_Button" data-name="Button - Nav Button"
                                         xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 52 52">
                                        <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                fill="#323232"></circle>
                                        <circle id="Ellipse_4378" data-name="Ellipse 437" cx="-26" cy="26" r="26"
                                                fill="#323232"></circle>
                                        <g id="Group_15984" data-name="Group 15984" transform="translate(-96 -5894.5)">
                                            <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                                  transform="translate(120.5 5915.5)" fill="none" stroke="#D80028"
                                                  stroke-linecap="round" stroke-width="2"></line>
                                            <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                  transform="translate(120.5 5920.5)" fill="none" stroke="#D80028"
                                                  stroke-linecap="round" stroke-width="2"></line>
                                        </g>
                                    </svg>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col sm={6} className={'mobile-hide'}>
                        {/*<Button color={'white'} background={'#323232'} text={'View All'} borderRadius={'50'} />*/}
                    </Col>
                </Row>
            </Container>

            <div className="clearfix"></div>
            <Container fluid>
                <LightgalleryProvider >

                    {
                        data?.concern_slider &&
                        <Swiper
                            onSwiper={setSwiperInstance}
                            loop={true}
                            slidesPerView={3}
                            spaceBetween={15}
                            speed='1500'
                            slidesPerGroup={1}
                            keyboardControl={true}
                            initialSlide={1}
                            modules={[ Pagination, Navigation, Mousewheel,Autoplay]}
                            navigation={{
                                prevEl: '#prev',
                                nextEl: '#next',
                            }}
                            autoplay={{
                                delay: 3500, // Delay between transitions (in milliseconds)
                                disableOnInteraction: false, // Whether autoplay should stop on user interactions
                            }}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 30,

                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,

                                },
                                991: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                            }}
                            className="mySwiper"

                        >
                            {data?.concern_slider && data?.concern_slider?.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <div className="blog-single" >
                                        <div className="blog-single__inner">
                                            { item?.link !== '' ?
                                                <Link to={`/concern/${item?.link}`}></Link>
                                                :
                                                <Link to={`/${item?.extlink}`}></Link>
                                            }

                                            <div className="blog-single__inner__content">
                                                <div className={'home-slider'} >
                                                    <img src={item?.image ? item?.image : 'images/static/blur.jpg'}/>
                                                </div>
                                                <div className="blog-single__inner__content__top">
                                                    <div className="upper">

                                                        <p>{reactHtmlParser(item?.description)}</p>
                                                    </div>

                                                    <h2>{reactHtmlParser(item?.title)}</h2>
                                                    <div className={'image-wrapper'}>
                                                        <img src={item?.logo ? item?.logo :'images/static/menu/logo-black.svg'}/>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>

                    }
                </LightgalleryProvider>
            </Container>

        </StyledGallery>
    );
};

const StyledGallery = styled.section`

  background-color: #f5f5f5;
  overflow: hidden;
  position: relative;
  @media (min-width: 768px) and (max-width: 991px) {
    padding-bottom: 150px;
  }

  .global-image {
    img {
      object-fit: contain;
    }
  }

  .bc-image {
    position: absolute;
    height: 100%;
    width: 100%;
    inset: 0px;
    margin: 0px;
    overflow: hidden;
    background-repeat: repeat !important;

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      inset: 0px;
      object-fit: cover;
    }

    @media (max-width: 767px) {
      height: 70% !important;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      height: 65% !important;
    }
    //@media (min-width: 992px) and (max-width: 1024px){
    //  height: 62%!important;
    //}
    //@media (min-width: 1024px) and (max-width: 1350px){
    //  height: 58%!important;
    //}

  }

  .gallery-slider__text {
    &__subtitle h3 {
      margin: 0px 0px 60px;
      color: #323232;
      padding-bottom: 20px;

      span {
        color: #E50019;
      }

      @media (max-width: 767px) {
        margin-bottom: 40px;
      }
    }

    &__title-arrow {
      display: flex;
      margin-bottom: 80px;
      justify-content: space-between;

      @media (max-width: 767px) {
        flex-direction: column;
        margin-bottom: 40px;
      }

      &__title {
        margin-right: 20px;
      }

      h2 {
        font-weight: 500;
        margin: 0px;
        color: rgb(240, 237, 227);
        font-size: 36px;
        line-height: 40px;
        @media (max-width: 767px) {
          margin-bottom: 40px;
          font-size: 24px;
          line-height: 28px;
        }

      }

      &__arrow {
        display: flex;
        gap: 20px;
      }
    }
  }


  .gallery-slider__subtitle p {
    position: relative;
    z-index: 10;
    color: #323232;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    padding-bottom: 20px;
  }

  svg {
    border-radius: 50px;

    #Ellipse_4378 {
      transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
      r: 26;
      cursor: pointer;
    }

    line {
      stroke: white;
    }

    &:hover {
      #Ellipse_4378 {
        fill: #E50019;
        transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
        cx: 26;
      }

      g {
        line {
          stroke: #f9f9f9;
        }
      }
    }
  }

  .gallery-slider__right {

    p {
      padding-bottom: 80px;
      color: #323232;
      font-size: 20px;
      line-height: 24px;
      font-weight: 300;
    }
  }

  //slider
  .blog-single {
    .blog-single__inner {
      padding-top: calc(312 / 370 * 100%);
      position: relative;

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 3;
      }

      &__content {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;

        .home-slider {
          height: 100%;
          width: 100%;

          img {
            width: 99.9%;
            height: 100%;
            object-fit: unset;
            position: absolute;
            opacity: 1;
            bottom: 0;
            left: 0;
            transition: opacity 0.5s ease-in-out;
          }

          &:after {
            content: '';
            height: 100%;
            width: 100.5%;
            background-color: #FFFFFF;
            top: 0;
            left: -0.9px;
            right: 0;
            position: absolute;
            transition: all 0.5s ease-in-out;
            //@media (min-width: 768px) and (max-width: 991px){
            //  display: none;
            //}
          }
        }


        &__top {
          .upper {
            position: absolute;
            left: 40px;
            top: 40px;
            z-index: 2;
            opacity: 0;
            right: 30px;
            margin: 0;
            //transition: all 0.6s ease;

            p {
              font-size: 16px;
              color: #FFFFFF;
              transition: color .3s ease;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          img {
            width: 197px;
            height: 100px;
            opacity: 1;
            position: absolute;
            left: 20px;
            top: 40px;
            transition: all 0.5s ease;
          }


          h2 {
            position: relative;
            top: -55px;
            left: 40px;
            right: 30px;
            z-index: 2;
            font-size: 20px;
            line-height: 24px;
            color: black;
            transition: all 1s ease;
            font-weight: 500;
            margin: 0;
            width: 85%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @media (max-width: 767px) {
              font-size: 20px;
              line-height: 24px;
            }
          }
        }

      }

      &__bottom {
        position: absolute;
        margin: 0;
        left: 30px;
        right: 30px;
        bottom: 24px;
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        z-index: 2;

        h3 {
          color: #FFFFFF;
          transition: color .3s ease;

          p {
            display: block;
            color: #FFFFFF;
          }
        }

        p {
          color: #FFFFFF;
          text-align: right;
          transition: color .3s ease;

          span {
            display: block;
            color: #FFFFFF;
          }
        }
      }
    }

    &:hover {
      .blog-single__inner__content {
        .home-slider {
          img {
            height: 100%;
            opacity: 1;
          }

          &:after {
            height: 0%;
          }
        }

        &__top {
          h2 {
            overflow: hidden;
            color: #FFFFFF;
            transition: color .8s ease;
          }

          .upper {
            opacity: 1;
            transition: 0.6s opacity ease;
            transition-delay: 0.5s;
          }

          img {
            opacity: 0;
          }

        }

        .blog-single__inner__content__bottom {
          border-color: #FFF;

          h4 {
            color: #ffffff;
          }

          p {
            color: #ffffff;

            span {
              color: #ffffff;
            }
          }
        }
      }
    }
  }

  &:hover {
    .blog-single__inner__content:after {
      height: 100%;
    }
  }
}


.swiper-slide-active {
  .blog-single {
    &__inner {
      //padding-top: calc(431 / 470 * 100%);
      position: relative;

      &__content {
        .home-slider {
          &:after {
            content: '';
            height: 100%;
            width: 100.5%;
            background-color: transparent;
            top: 0;
            left: -0.9px;
            right: 0;
            position: absolute;
            transition: all 0.5s ease-in-out;
            @media (max-width: 767px) {
              background-color: white;
            }
          }
        }

        &__top {
          .upper {
            transform: translateY(0px);
            opacity: 1;
            transition: 0.5s ease opacity;
            transition-delay: 0.5s;
            @media (max-width: 767px) {
              opacity: 0;
            }
          }

          h2 {
            font-size: 36px;
            font-weight: 500;
            line-height: 40px;
            top: -65px;
            opacity: 1;
            color: white;
            transition: all 1s ease;

            @media (max-width: 767px) {
              font-size: 20px;
              line-height: 24px;
              color: black;

            }
            @media (min-width: 768px) and (max-width: 911px) {
              font-size: 36px;
              line-height: 40px;
              color: #fff;
            }
          }

          p {
            font-size: 20px !important;
          }

          img {
            opacity: 0;
            @media (max-width: 767px) {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .gallery-slider__right p {
    padding-bottom: 40px;
  }

  .gallery-slider__text__subtitle h3 {
    margin: unset;
    padding-bottom: 40px;
  }

  .blog-single .blog-single__inner__content__top {
    h2 {
      color: white;
      left: 15px;
    }

    .upper {
      opacity: 1;
      height: 190px;
      overflow: hidden;
      top: 15px;
      left: 15px;
    }

    img {
      opacity: 0;
    }
  }

  .swiper-slide-active .blog-single__inner__content .home-slider::after {
    height: 0;
  }

  .container-fluid {
    padding: 0;
  }
}

.mySwiper {
  padding-left: ${props => props.offset ? props.offset + 15 + 'px' : '100px'} !important;

  @media (max-width: 991px) {
    padding-left: unset !important;
  }
}

.swiper-wrapper {
  @media (max-width: 991px) {
    padding-left: unset;
  }
  @media (max-width: 767px) {
    padding-left: unset;
  }
}

.swiper-slide {
  width: 100%;
  height: var(--target-height) !important;
  transition: 0.7s all ease;
  @media (min-width: 768px) and (max-width: 991px) {
    height: 100% !important;
  }
  @media (min-width: 991px) and (max-width: 1400px) {
    height: calc(var(--target-height) - 8px) !important;
  }
  @media(max-width: 767px){
    width: 100%!important;
  }
}

.swiper-slide.swiper-slide-active {
  width: var(--target-width) !important;
  @media (min-width: 768px) and (max-width: 991px) {
    width: 60% !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  @media(max-width: 767px) {
    width: 100% !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
  }


}

.swiper-button-next, .swiper-button-prev {
  display: none;
}

.image_wrapper {
  position: relative;
  padding-top: calc(312 / 370 * 100%);

  img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 870px) {
  .gallery-slider__text__title-arrow ul {
    align-items: center !important;
  }
}

@media (min-width: 768px) {
  .mobile-flex-svg {
    display: none;
  }

  .desktop-hide {
    display: none;
  }
}

@media (max-width: 767px) {
  padding-bottom: 150px;
  .gallery-slider__text__title-arrow {
    display: none;
  }

  .mobile-hide {
    display: none;
  }

  .mobile-flex {
    display: flex;
    justify-content: space-between;

    a {
      margin-left: auto;
    }

    ul {
      display: flex;

      li {
        margin-right: 20px;
      }
    }

    .mobile-flex-svg {
      display: block;
    }
  }
}
  

`;

export default GalleryHome;
