import React from 'react';
import styled from "styled-components";
import {Container, Row, Col, Accordion} from "react-bootstrap";
import {BsChevronDown} from "react-icons/bs";

import reactHtmlParser from "react-html-parser";
import {hoverr} from "../../styles/globalStyleVars";

const Address = ({contact_info, office_locations}) => {
    return (
        <StyledComponent className='pt-200 pb-200'>
            <Container>
                <Row>
                    <Col sm={12}>
                        {contact_info?.subtitle &&
                            <p className={'upper-title split-left'}>{reactHtmlParser(contact_info?.subtitle)}</p>}
                    </Col>

                    <Col md={9}>
                        {contact_info?.title && <h3 className={'split-up'}>{reactHtmlParser(contact_info?.title)}</h3>}
                    </Col>
                </Row>

                {office_locations?.map((item, index) => (
                    <Row className="accordion-row">
                        <Col md={2}>
                            {item?.post_title &&
                                <p className={'address-title split-up'}>{reactHtmlParser(item?.post_title)}</p>}
                        </Col>

                        <Col md={{span: 9, offset: 1}}>
                            <Accordion defaultActiveKey={index == '' && '0'}>

                                {item?.office_location?.map((itemSingle, indexItem) => (
                                    <Accordion.Item key={indexItem} eventKey={`${indexItem}`}>
                                        <Accordion.Header>
                                            {reactHtmlParser(itemSingle?.title)}<span><BsChevronDown/></span>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div>
                                                {itemSingle?.description && reactHtmlParser(itemSingle?.description)}
                                            </div>
                                            {itemSingle?.map_image &&
                                                <div className="map">
                                                    <a href={itemSingle?.map_url} target='_blank'></a>
                                                    <img src={itemSingle?.map_image} alt=""/>
                                                </div>
                                            }

                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}

                            </Accordion>
                        </Col>
                    </Row>
                ))}

            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: #F5F5F5;

  .accordion-row {
    margin: 40px 0 0 0;
  }

  .upper-title {
    font-weight: 500;
    margin: 0 0 20px 0;
  }

  h3 {
    margin: 0 0 40px 0;

    span {
      color: #E50019;
    }
  }


  .office-address {
    display: flex;
    margin-bottom: 40px;

    .office-address-inner {
      width: 370px;
      margin-right: 20px;
    }
  }

  .detail-down {
    display: flex;
    margin-bottom: 40px;

    .office-fax {
      width: 370px;
      margin-right: 20px;
    }
  }


  .address-title {
    font-weight: 500;
  }

  .info {
    margin: 0 0 40px 0;
  }

  .info-title {
    font-weight: 500;
    margin: 0 0 20px 0;
  }

  .info-desc {
    margin: 0;
  }

  .accordion-header {
    position: relative;
    font-size: 28px;
    line-height: 32px;
    font-weight: 500;
    color: ${hoverr};
    margin: 0 0 40px 0;

    button {
      border: none;
      background-color: transparent;
      padding-left: 0;
      padding-bottom: 20px;
      color: ${hoverr};
      font-size: 28px;
      line-height: 32px;
      font-weight: 500;
      border-bottom: 1px solid ${hoverr};
      width: 100%;
      text-align: left;
      transition: all .4s ease;

      &.collapsed {
        color: #323232;
        border-color: #323232;

        span {
          background-color: #323232;

          svg {
            transform: rotate(0deg);
          }

          &:after {
            background-color: ${hoverr} !important;
          }
        }
      }
    }

    span {
      position: absolute;
      height: 28px;
      width: 28px;
      border-radius: 50%;
      color: #ffffff;
      background-color: ${hoverr};
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 20px;

      &:after {
        height: 0;
        width: 0;
        background-color: ${hoverr};
        border-radius: 50%;
        opacity: 0;
        transition: all .4s ease;
        content: '';
        position: absolute;
      }

      svg {
        font-size: 13px;
        z-index: 2;
        transform: rotate(180deg);
      }

    }

    &:hover {
      span {
        &:after {
          height: 100%;
          width: 100%;
          opacity: 1;
        }
      }

      button {
        color: ${hoverr};
        border-color: ${hoverr};
      }
    }
  }

  .accordion-body {
    a {
      font-weight: 300;
    }

    .map {
      margin-bottom: 80px;
      position: relative;
      padding-top: calc(420 / 870 * 100%);

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        z-index: 2;
        cursor: pointer;
      }

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        z-index: 1;
      }
    }
  }

  @media (max-width: 767px) {
    
    .accordion-row {
      margin: 0;
      .col-md-2,.col-md-9{
        padding:0;
        .map{
          margin-bottom: 0;
        }
      }
    }
    .address-title {
      padding-bottom: 40px;
    }

    .office-address {
      flex-direction: column;

      .office-address-inner {
        width: unset;
        margin-right: 0;
        margin-bottom: 40px;
      }
    }

    .detail-down {
      flex-direction: column;

      .office-fax {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }
  }
`;

export default Address;
