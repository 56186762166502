import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Navigation, Pagination, Scrollbar, A11y, Autoplay, Mousewheel} from 'swiper';
import Img from "../Img";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {hover} from "../../styles/globalStyleVars";
import {LightgalleryItem, LightgalleryProvider} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";
import Button from "../Button";
import {Link, useLocation} from "react-router-dom";
import ProductPopUp from "./ProductPopUp";
import reactHtmlParser from "react-html-parser";

const GalleryHome = ({data}) => {
    const location = useLocation()

    const [offset, setOffset] = useState(100);
    useEffect(() => {
        setOffset(document.querySelector(' .container').offsetLeft);
    }, []);

    const [show, setShow] = useState(false);
    const [winWidth, setWinWidth] = useState(true);
    const [popupId, setPopupId] = useState();
    const [selectedData, setSelectedData] = useState({});
    const handleClose = () => setShow(false);
    const handleShow = (item) => {
        setShow(true);
        setSelectedData(item);
    }
    // <width control

    const sourceDivRef = useRef('');

    useEffect(() => {
        const handleResize = () => {
            const fullWidth = sourceDivRef.current.offsetWidth;
            const adjustedWidth = fullWidth * 1.2;
            document.documentElement.style.setProperty('--target-width', `${adjustedWidth}px`);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const next = () => {
        document.querySelector('.mySwiper .swiper-button-next').click();
    }

    const prev = () => {
        document.querySelector('.mySwiper .swiper-button-prev').click();
    }

    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true);
        } else {
            setWinWidth(false);
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true);
            } else {
                setWinWidth(false);
            }
        });
    }, []);

    return (
        <>
            <StyledGallery offset={offset} className="gallery-slider pt-200 pb-200">
                <Container fluid>
                    <Row className={'gallery-slider__tablet'}>
                        <Col sm={'4'} className={'left-section'} ref={sourceDivRef}>
                            <div>
                                <div className={'gallery-slider__subtitle'}>
                                    <p className={'split-left'}>{data?.subtitle}</p>
                                </div>
                                <div className={'gallery-slider-left'}>
                                    <Col sm={12} className="gallery-slider__text">
                                        <div className="gallery-slider__text__subtitle">
                                            <h3>{reactHtmlParser(data?.title)}</h3>
                                            <p>{reactHtmlParser(data?.description)}</p>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                            <div className={'gallery-slider-arrow'}>
                                <div className="gallery-slider__text__title-arrow">
                                    <ul className="gallery-slider__text__title-arrow__arrow">
                                        <li onClick={prev} className="left">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49"
                                                 viewBox="0 0 52 52">
                                                <g id="Button_-_Nav_Button" data-name="Button - Nav Button"
                                                   transform="translate(52 52) rotate(180)">
                                                    <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26"
                                                            cy="26" r="26" fill="#323232"></circle>
                                                    <circle id="Ellipse_4378" data-name="Ellipse 437" cx="-26"
                                                            cy="26" r="26" fill="#E50019"></circle>
                                                    <g id="Group_15984" data-name="Group 15984"
                                                       transform="translate(-96 -5894.5)">
                                                        <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                                              transform="translate(120.5 5915.5)" fill="none"
                                                              stroke="#D80028" stroke-linecap="round"
                                                              stroke-width="2"></line>
                                                        <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                              transform="translate(120.5 5920.5)" fill="none"
                                                              stroke="#D80028" stroke-linecap="round"
                                                              stroke-width="2"></line>
                                                    </g>
                                                </g>
                                            </svg>
                                        </li>
                                        <li onClick={next} className="right">
                                            <svg id="Button_-_Nav_Button" data-name="Button - Nav Button"
                                                 xmlns="http://www.w3.org/2000/svg" width="49" height="49"
                                                 viewBox="0 0 52 52">
                                                <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26" cy="26"
                                                        r="26" fill="#323232"></circle>
                                                <circle id="Ellipse_4378" data-name="Ellipse 437" cx="-26" cy="26"
                                                        r="26" fill="#323232"></circle>
                                                <g id="Group_15984" data-name="Group 15984"
                                                   transform="translate(-96 -5894.5)">
                                                    <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                                          transform="translate(120.5 5915.5)" fill="none"
                                                          stroke="#D80028" stroke-linecap="round"
                                                          stroke-width="2"></line>
                                                    <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                          transform="translate(120.5 5920.5)" fill="none"
                                                          stroke="#D80028" stroke-linecap="round"
                                                          stroke-width="2"></line>
                                                </g>
                                            </svg>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <Button src={`/product-list?product_type=${data?.type}`}
                                            color={'white'} background={'#323232'} text={'View All'}
                                            borderRadius={'50'}/>
                                </div>
                            </div>
                        </Col>
                        <Col sm={{span: 7, offset: 1}}>
                            {data &&
                                <Swiper
                                    loop={true}
                                    slidesPerView={3}
                                    spaceBetween={15}
                                    speed='1000'
                                    slidesPerGroup={1}
                                    keyboardControl={true}
                                    initialSlide={0}
                                    modules={[Pagination, Navigation, Mousewheel]}
                                    navigation
                                    breakpoints={{
                                        320: {
                                            slidesPerView: 1,
                                            spaceBetween: 0,
                                        },
                                        768: {
                                            slidesPerView: 3,
                                            spaceBetween: 0,
                                        },
                                        1024: {
                                            slidesPerView: 3,
                                            spaceBetween: 30,
                                        },
                                        1025: {
                                            slidesPerView: 3,
                                            spaceBetween: 30,
                                        },
                                    }}
                                    className="mySwiper"
                                >
                                    {data?.product?.map((item, index) => (
                                        <SwiperSlide key={index} onClick={() => handleShow(item)}>
                                            <div className="blog-single">

                                                <div className="blog-single__inner">
                                                    <div className={'blog-single__inner__wrapper'}>
                                                        <img
                                                            src={item?.post_feature_image ? item?.post_feature_image : 'images/static/blur.jpg'}
                                                            alt={'product image'}/>
                                                    </div>
                                                </div>

                                                <div>
                                                    <p>{item?.product_sub_type}</p>
                                                    <h5>{item?.post_title}</h5>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            }
                        </Col>
                    </Row>
                </Container>
            </StyledGallery>
            <ProductPopUp show={show} handleClose={handleClose} data={selectedData}/>
        </>
    );
};


const StyledGallery = styled.section`


  background-color: #FFFFFF;
  overflow: hidden;
  padding-bottom: 200px;

  .global-image {
    img {
      object-fit: contain;
      top: -200px;
    }
  }

  @media (max-width: 767px) {
    .gallery-slider__text__subtitle {
      padding-bottom: 40px;
    }

    .gallery-slider-arrow {
      padding: 0 15px 40px 15px;
    }
  }

  .container-fluid {
    margin-left: ${props => props.offset ? props.offset + 15 + 'px' : '100px'} !important;
  }

  .gallery-slider__text {
    &__subtitle h3 {
      color: #323232;
      padding-bottom: 40px;

      span {
        color: #E50019;
      }

      @media (max-width: 767px) {
        margin-bottom: 40px;
      }
    }

    &__title-arrow {

      @media (max-width: 767px) {
        flex-direction: column;
      }

      &__title {
        margin-right: 20px;
      }

      h2 {
        font-weight: 500;
        margin: 0px;
        color: rgb(240, 237, 227);
        font-size: 36px;
        line-height: 40px;
        @media (max-width: 767px) {
          margin-bottom: 40px;
          font-size: 24px;
          line-height: 28px;
        }
      }

      &__arrow {
        display: flex;
        gap: 20px;
        z-index: 2;
      }
    }
  }


  .gallery-slider-arrow {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    @media (max-width: 767px) {
      margin-top: 0;
      position: relative !important;
    }
  }

  .gallery-slider-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .gallery-slider__subtitle {
    padding: 0 15px;

    p {
      color: #323232;
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      padding-bottom: 20px;
    }
  }

  svg {
    border-radius: 50px;

    #Ellipse_4378 {
      transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
      r: 26;
      cursor: pointer;
    }

    line {
      stroke: white;
    }

    &:hover {
      #Ellipse_4378 {
        fill: #E50019;
        transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
        cx: 26;
      }

      g {
        line {
          stroke: #f9f9f9;
        }
      }
    }
  }

  .gallery-slider__right {

    p {
      color: #323232;
      font-size: 20px;
      line-height: 24px;
      font-weight: 300;
    }
  }

  .gallery-slider__tablet {
    .col-sm-4 {
      height: auto;
      padding: 0;
    }

    .left-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .col-sm-7 {
      height: fit-content;

      .swiper {
        height: fit-content;
      }
    }
  }

  //slider
  .blog-single {
    position: relative;
    height: calc(100%);

    p {
      padding-top: 30px;
      font-size: 16px;
      font-weight: 300;
      line-height: 20px;
    }

    h5 {
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
    }

    .blog-single__inner {
      border: 20px solid #F5F5F5;

      &__wrapper {
        //padding-top: calc(540 / 270 * 100%);
        //position: relative;

        //img {
        //  position: absolute;
        //  top: 0;
        //  left: 0;
        //  height: 100%;
        //  width: 100%;
        //}

        a {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          z-index: 3;
        }
      }

      &__content {
        &__top {
          h2 {

            position: absolute;
            bottom: 30px;
            left: 40px;
            right: 30px;
            z-index: 2;
            font-size: 36px;
            font-weight: 500;
            line-height: 40px;
            color: black;
            transition: color .6s ease;
            margin: 0;
            @media (max-width: 767px) {
              font-size: 20px;
              line-height: 24px;
            }
          }
        }

        &__bottom {
          position: absolute;
          margin: 0;
          left: 30px;
          right: 30px;
          bottom: 24px;
          display: flex;
          justify-content: space-between;
          padding-top: 20px;
          z-index: 2;

          h3 {
            color: #FFFFFF;
            transition: color .3s ease;

            p {
              display: block;
              color: #FFFFFF;
            }
          }

          p {
            color: #FFFFFF;
            text-align: right;
            transition: color .3s ease;

            span {
              display: block;
              color: #FFFFFF;
            }
          }
        }
      }

    }

    .blog-single__inner-agro {
      padding-top: calc(370 / 270 * 100%);
      position: relative;

      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border: 20px solid #F5F5F5;
        object-fit: cover;
      }

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 3;
      }

      &__content {
        &__top {
          h2 {

            position: absolute;
            bottom: 30px;
            left: 40px;
            right: 30px;
            z-index: 2;
            font-size: 36px;
            font-weight: 500;
            line-height: 40px;
            color: black;
            transition: color .6s ease;
            margin: 0;
            @media (max-width: 767px) {
              font-size: 20px;
              line-height: 24px;
            }
          }
        }

        &__bottom {
          position: absolute;
          margin: 0;
          left: 30px;
          right: 30px;
          bottom: 24px;
          display: flex;
          justify-content: space-between;
          padding-top: 20px;
          z-index: 2;

          h3 {
            color: #FFFFFF;
            transition: color .3s ease;

            p {
              display: block;
              color: #FFFFFF;
            }
          }

          p {
            color: #FFFFFF;
            text-align: right;
            transition: color .3s ease;

            span {
              display: block;
              color: #FFFFFF;
            }
          }
        }
      }

    }

  }
}

//tablet

@media (min-width: 768px) and (max-width: 1024px) {

  .left-section {
    padding-right: ${props => props.offset ? (props.offset + 15) * 2 + 'px' : '100px'} !important;
  }

  .gallery-slider {
    &__tablet {
      display: block;

      .col-sm-4 {
        max-width: 100%;

        .gallery-slider-arrow {
          position: relative;
          padding: 40px 15px;
        }
      }

      .col-sm-7 {
        max-width: 100%;
        margin-left: unset;
        padding-left: unset;

        .swiper {
          width: 100%;
          margin-left: 15px;
          margin-right: 15px;
          height: unset !important;
        }

        .blog-single__inner {
          img {
            width: 95%;
          }
        }
      }
    }
  }
}

.container-fluid {
  padding: 0;
}

.mySwiper {
  cursor: pointer;
  width: 100%;
  height: var(--target-width);
}


@media (max-width: 767px) {
  padding-bottom: 150px;

  .gallery-slider-left {
    display: block;
  }

  .left-section {
    padding: 0 15px !important;
  }

  .container-fluid {
    margin-left: unset !important;
  }

  .gallery-slider__right p {
    padding-bottom: 40px;
  }

  .gallery-slider__text__subtitle h3 {
    margin: unset;
    padding-bottom: 40px;
  }

  .mySwiper {
    height: 100%;
    padding-left: 0px;
    width: 100%;
    padding-right: 90px;
  }

  .swiper-slide {
    padding-left: 15px;
  }
}


// .swiper-wrapper {
//   padding-left: ${props => props.offset ? props.offset + 15 + 'px' : '100px'};
// }

//.swiper-slide {
//  @media (max-width: 991px) {
//    padding-right: 15px !important;
//    padding-left: 15px !important;
//  }
//  @media (max-width: 767px) {
//    width: 100% !important;
//    padding-right: 15px !important;
//    padding-left: 15px !important;
//  }
//}

.swiper-slide {
  transition: 0.7s all ease;
}

.swiper-button-next, .swiper-button-prev {
  display: none;
}

.image_wrapper {
  position: relative;
  padding-top: calc(312 / 370 * 100%);


  img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 870px) {
  .gallery-slider__text__title-arrow ul {
    align-items: center !important;
  }
}

@media (min-width: 768px) {
  .mobile-flex-svg {
    display: none;
  }

  .desktop-hide {
    display: none;
  }
}

@media (max-width: 767px) {
  .mobile-hide {
    display: none;
  }

  .mobile-flex {
    display: flex;
    justify-content: space-between;

    a {
      margin-left: auto;
    }

    ul {
      display: flex;

      li {
        margin-right: 20px;
      }
    }

    .mobile-flex-svg {
      display: block;
    }
  }

  .global-image {
    img {
      object-fit: contain;
      top: 0;
      margin-top: 40%;
      @media (max-width: 767px) {
        top: unset;
      }
    }
  }


`;

export default GalleryHome;
