import {Route, Switch, useLocation} from 'react-router-dom';
import Error from './pages/404';
import GlobalStyle from "./styles/globalStyle";
import { RevealAnimation} from "./components/animations/imageReveal";
import {gsap} from "gsap";
import {ScrollSmoother} from "gsap/ScrollSmoother";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import 'react-toastify/dist/ReactToastify.css';
import pageTransition from "./components/animations/PageTransition";
import 'bootstrap/dist/css/bootstrap.min.css';

// page imports
import {SplitUp, SplitUpP, useTextSplit, useTextSplitBanner} from "./components/animations/TextAnimation";
import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import About from './pages/about';
import Home from './pages/home';
import Product from './pages/product';
import ProductDetail from './pages/product/single';
import LivingLegendd from "./pages/living-legend";
import BoardDirectors from "./pages/board-of-directors";
import KeyExecutives from "./pages/key-executives";
import MediaCenter from "./pages/media-center";
import MediaCenterDetail from './pages/media-center/single';
import Career from "./pages/career";
import Contact from "./pages/contact";
import Menu from "./components/Menu";
import FooterLarge from "./components/FooterLarge";
import FooterSmall from "./components/FooterSmall";
import MobileMenu from "./components/MobileMenu";
import Tvc from "./pages/tvc";
import DealerList from "./pages/dealer-list";
import CSR from "./pages/csr";
import ProductList from "./pages/product-list";
import FloatingButton from "./components/FloatingButton";
import {ToastContainer} from "react-toastify";
import {useSelector} from "react-redux";
import {SectionReveal} from "./components/animations/SectionReveal";
import {Parallax} from "./components/animations/parallax";
import ParcticleBoardMill from "./pages/starparticleboardmill";
import SisterConcernindex from "./pages/sisterconcern";


function App() {

    const location = useLocation();
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
    const getPost = useSelector((state) => state)
    const [offset, setOffset] = useState(0)
    const [menuColor, setMenuColor] = useState('white');
    const [menuColorMobile, setMenuColorMobile] = useState('transaprent');
    // offset
    useEffect(() => {

        if (window.innerWidth > 767) {
            setOffset(document.querySelector('.container').offsetLeft + 15)
        }
        window.addEventListener('resize', () => {
            if (window.innerWidth > 767) {
                setOffset(document.querySelector('.container').offsetLeft + 15)
            }
        })
    }, [location.pathname])

    const [isLoadingContent, setIsLoadingContent] = useState(true);
    // Simulate loading your content (e.g., data fetching)
    useEffect(() => {
        setIsLoadingContent(true); // Show preloader when route changes
        // Simulate a delay (e.g., fetching data from an API)
        setTimeout(() => {
            setIsLoadingContent(false); // Set isLoadingContent to false when content is ready
        }, 1500); // Adjust the delay duration as needed
    }, [getPost]);
    // menu color change on pathname
    useEffect(() => {
        // Update menu color based on the current route
        if (
            location.pathname === '/tvc'
            || location.pathname === '/news-events/:slug'
            || location.pathname.startsWith(`/media-center/`)
            || location.pathname.startsWith(`/dealer-list`)

        ) {
            setMenuColor('#000020');
        } else {
            setMenuColor('white');
        }
    }, [location.pathname]);


    useEffect(() => {
        // Update menu color based on the current route
        if (
            location.pathname === '/tvc'
            || location.pathname === '/news-events/:slug'
            || location.pathname.startsWith(`/media-center/`)

        ) {
            setMenuColorMobile('#323232');
        } else {
            setMenuColorMobile('transparent');
        }
    }, [location.pathname]);


    useEffect(() => {
        if (location.pathname === '/key-executives'
            || location.pathname === '/dealer-list'
            || location.pathname === '/product-list') {
            document.body.classList.add('key');
        }
    }, [getPost]);


    // smooth scroll init
    const el = useRef();
    useLayoutEffect(() => {
        let smoother;

        // Initialize ScrollSmoother for all devices, including mobile and tablet
        smoother = ScrollSmoother.create({
            smooth: 2,
            smoothTouch: window.innerWidth >= 768 && window.innerWidth <= 991 ? 1.5 : '',
            effects: true
        });

        if (location.hash) {
            setTimeout(() => {
                const target = document.querySelector(location.hash);
                if (target) {
                    const offsetTop = target.offsetTop - 120; // Adjust for top offset
                    smoother.scrollTo(offsetTop, { duration: 3 });
                }
            }, 400);
        } else {
            window.scroll(0, 0);
        }

        return () => {
            if (smoother) smoother.kill();
        };
    }, [location.pathname, location.hash, getPost]);




    // route change scroll top & page class
    useEffect(() => {
        if (location.pathname === '/') {
            document.body.classList.add('in-home-page');
        } else {
            document.body.classList.remove('in-home-page');
        }
        if (location.pathname !== '/product-list'
            || location.pathname !== '/dealer-list'
        ) {
            window.scroll(0, 0);
        }
    }, [location.pathname]);



    const Cursor = () => {
        const followerRef = useRef(null);

        useEffect(() => {
            const follower = followerRef.current;

            let posX = 0,
                posY = 0;

            let mouseX = 0,
                mouseY = 0;

            gsap.to({}, 0.016, {
                repeat: -1,
                onRepeat: function () {
                    posX += (mouseX - posX) / 9;
                    posY += (mouseY - posY) / 9;

                    gsap.set(follower, {
                        left: posX - 12,
                        top: posY - 12,
                    });
                },
            });

            const handleMouseMove = (e) => {
                mouseX = e.clientX;
                mouseY = e.clientY;
            };

            window.addEventListener('mousemove', handleMouseMove);

            return () => {
                window.removeEventListener('mousemove', handleMouseMove);
            };
        }, []);

        return <div className="cursor-follower" ref={followerRef}></div>;
    };

    // smooth scroll

    // animation functions
    Parallax()
    SplitUp()
    RevealAnimation()
    SectionReveal()
    pageTransition()


    return (

        <>
            <ToastContainer position="top-right" autoClose={4000} closeOnClick hideProgressBar={true}/>
            <GlobalStyle/>
            <Menu color={menuColor}/>
            <MobileMenu color={menuColorMobile}/>

            <div className="page-transition">
                <img className={'logo'} src="/images/static/fav-white.svg" alt=""/>
            </div>
            <Cursor/>
            <div className="App" ref={el} id="smooth-wrapper">
                {/*<FloatingButton/>*/}
                <div id="smooth-content">

                    <Switch location={location} key={'location.pathname'}>
                        <Route exact path="/"><Home offset={offset}/></Route>
                        <Route exact path="/about"><About offset={offset}/></Route>
                        <Route exact path="/a-visionarys-legacy"><LivingLegendd/></Route>
                        <Route exact path="/board-of-directors"><BoardDirectors offset={offset}/></Route>
                        <Route exact path="/csr" component={CSR}/>
                        <Route exact path="/key-executives" component={KeyExecutives}/>
                        <Route exact path="/media-center" component={MediaCenter}/>
                        <Route exact path={`/media-center/:slug`} component={MediaCenterDetail}/>
                        <Route exact path="/tvc" component={Tvc}/>
                        <Route exact path="/dealer-list" component={DealerList}/>
                        <Route exact path="/career" component={Career}/>
                        <Route exact path="/contact" component={Contact}/>
                        <Route exact path="/product-list" component={ProductList}/>
                        <Route exact path="/concern/:slug"><Product offset={offset}/></Route>
                        <Route exact path="/star-particle-board-mill"><ParcticleBoardMill/></Route>
                        <Route exact path="/sister-concerns"><SisterConcernindex/></Route>
                        <Route exact path={`/product/:slug`} component={ProductDetail}/>
                        {/*<Route exact path={`/concern-external/:slug`} component={ConcernExternal}/>*/}
                        <Route component={Error}/>
                    </Switch>

                    {!isLoadingContent &&
                        <FooterLarge/>
                    }
                    {!isLoadingContent &&
                        <FooterSmall/>
                    }
                </div>
            </div>
        </>


    );
}

export default App;
