import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import Button from "../Button";
import Img from "../Img";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import reactHtmlParser from "react-html-parser";
import {ImgCta} from "../ImgCta";
import {ImgMap} from "../ImgMap";

const AboutPartex = ({data}) => {
    return (
        <StyledComponent  className='about-partex pt-200 pb-200 parallax-img'>
            <Container >
                <Row>
                        <ImgMap className={'opacity-30'} src={'images/static/map.svg'}/>
                    <Col sm={12}>
                        {data?.subtitle && <p className={'upper-title split-left'}>{reactHtmlParser(data?.subtitle)}</p>}
                    </Col>

                    <Col lg={9}>
                        {data?.title && <h3 className={'split-up'}>{reactHtmlParser(data?.title)}</h3>}
                    </Col>

                    <Col lg={{span:4, offset:2}} className={'btn-col'}>
                        {data?.description && <p>{reactHtmlParser(data?.description)}</p>}
                        <Button src={'/about'} text={'Learn More'} color={'#323232'} background={'#F5F5F5'}/>
                    </Col>

                    <Col lg={{span:6, offset:6}}>
                        <Row>
                            <Col lg={6} className={'counter-item'}>
                                <h3>
                                    <span>
                                        <CountUp start={0} end={data?.year}>
                                            {({countUpRef, start}) => (
                                                <VisibilitySensor onChange={start}
                                                                  partialVisibility={{top: 0, bottom: 20}}
                                                                  delayedCall>
                                                    <span ref={countUpRef}/>
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>
                                        +
                                    </span>
                                </h3>
                                {data?.year_title && <p className={'split-left'}>{reactHtmlParser(data?.year_title)}</p>}
                            </Col>


                            <Col lg={6}></Col>

                            <Col lg={6} className={'counter-item'}>
                                <h3>
                                    <span>
                                        <CountUp start={0} end={data?.worker}>
                                            {({countUpRef, start}) => (
                                                <VisibilitySensor onChange={start}
                                                                  partialVisibility={{top: 0, bottom: 20}}
                                                                  delayedCall>
                                                    <span ref={countUpRef}/>
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>
                                        +
                                    </span>
                                </h3>
                                {data?.worker_title && <p className={'split-left'}>{reactHtmlParser(data?.worker_title)}</p>}
                            </Col>

                            <Col lg={6} className={'counter-item'}>
                                <h3>
                                    <span>
                                        <CountUp start={0} end={data?.factory}>
                                            {({countUpRef, start}) => (
                                                <VisibilitySensor onChange={start}
                                                                  partialVisibility={{top: 0, bottom: 20}}
                                                                  delayedCall>
                                                    <span ref={countUpRef}/>
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>
                                        +
                                    </span>
                                </h3>
                                {data?.factory_title && <p className={'split-left'}>{reactHtmlParser(data?.factory_title)}</p>}
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: #E4E4E4;
  overflow: hidden;
  position: relative;
  .row {
    position: relative;
  }
  .global-image{
    overflow: visible;
  }
  
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: unset;
    height: 100%;
    width: 60%;
    object-fit: unset;
  }

  .upper-title {
    width: 50%;
    margin: 0 0 20px 0;
  }
  
  h3 {
    margin: 0 0 80px 0;
    
    span {
      color: #E50019;
    }
  }

  p {
    margin: 0 0 40px 0;
  }
  
  .counter-item {
    h3 {
      font-size: 72px;
      line-height: 84px;
      font-weight: 400;
      margin: 0;
    }
    
    p {
      margin: 0;
      font-weight: 500;
    }
    
    &:nth-child(1) {
      margin-bottom: 40px;
    }
  }
  
  @media (max-width: 767px){
    .counter-item {
      p{
        opacity: .5;
      }
    }
  }

  @media (max-width: 991px) {
    img {
      top: unset;
      bottom: 50px;
      right: 15px;
      left: 15px;
      height: unset;
      width: calc(100% - 30px);
      object-fit: unset;
    }
    
    .upper-title {
      width: 100%;
    }
    
    .btn-col {
      margin-bottom: 80px;
    }

    .counter-item {
      &:nth-child(3) {
        margin-bottom: 40px;
      }
    }
  }
  
`;

export default AboutPartex;
