import React, {useEffect} from 'react';
import styled from "styled-components";

import {HelmetProvider, Helmet} from 'react-helmet-async';
import MediaDetails from "../../components/MediaCenter/MediaDetails";
import {useParams} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchMediaDetail} from "../../api/redux/MediaCenter";
import {useDispatch, useSelector} from "react-redux";

const MyComponent = () => {

    const dispatch = useDispatch();
    let {slug} = useParams();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.MEDIA_DETAIL
        dispatch(fetchMediaDetail([api_url + `/${slug}`]))
    }, [])

    const getData = useSelector(store => store.posts);

    const data = getData?.detail?.[0];


    return (

        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${data?.post_title} | Partex Star Group`}</title>
                <meta name="description" content="Description"/>
            </Helmet>

            <StyledComponent>
                <MediaDetails data={data} />
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
