import React, {useEffect} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async';
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchAbout} from "../../api/redux/About";
import StarParticleBoardMill from "../../components/Product/StarParticleBoardMill";
import SisterConcern from "../../components/Home/SisterConcern";
import SisterConcernProduct from "../../components/Product/SisterConcernProduct";

const SisterConcernindex = ({offset}) => {

    const dispath = useDispatch()
    const getData = useSelector((state) => state);

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.ABOUT
        dispath(fetchAbout([api_url]))
    }, [])


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Sister Concerns | Partex Star Group</title>
                <meta name="description" content="Description"/>
            </Helmet>

            <StyledComponent>
                <SisterConcernProduct/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default SisterConcernindex;
