import { gsap, TimelineLite } from "gsap";
import { useLocation } from "react-router-dom";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { loading } from "../../api/redux/global";


const PageTransition = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const MainStore = useSelector(state => state);

    useEffect(() => {
        const tl = new TimelineLite();

        // preloader start on page/router change
        dispatch(loading());
        tl.to('.page-transition', {
            opacity: 1,
            height: '100%', // Set height to '100%' for the bottom to top effect
            duration: 0.001,
            // ease: 'Cubic.easeOut',
            display: 'flex'
        }).to('.page-transition .logo', {
            duration: .3,
            opacity: 1
        }, '-=.7')
    }, [location.pathname]);

    useEffect(() => {
        const tl = new TimelineLite();

        // preloader end after page load
        if (!MainStore?.globalReducer?.globalLoader) {
            tl.to('.page-transition', {
                delay: 1.8,
                // opacity:1,
                // ease: 'Cubic.easeOut',
                height: 0, // Set height to 0 for the bottom to top effect
                duration: 1,
                display: 'none'
            }).to('.page-transition .logo', {
                duration: 0.3,
                opacity:0,
            },'-=.6');
        }
    }, [MainStore]);

};

export default PageTransition;
