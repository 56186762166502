import React, {useEffect, useRef, useState, useLayoutEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col, ListGroup, Dropdown, DropdownButton} from "react-bootstrap";
import {Img} from "../Img";
import 'react-modal-video/css/modal-video.min.css'
import {LightgalleryItem, LightgalleryProvider} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Mousewheel, Navigation, Pagination} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import reactHtmlParser from "react-html-parser";


import {gsap} from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

const Navbar = ({data, dataSlider}) => {


    const section1Ref = useRef(null);
    const section2Ref = useRef(null);
    const section3Ref = useRef(null);

    const firstSlider = data?.[0];
    const secondSlider = data?.[1];
    const thirdSlider = data?.[2];

    console.log('firstSlider',firstSlider)

    const [sectionName, SetSectionName] = useState('Education Sector');
    const [activesection, setActiveSection] = useState('Education Sector')

    const scrollToSection = (sectionRef, sectionname) => {
        const yOffset = -80; // Adjust this value to offset the scroll position if needed
        const y = sectionRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
        SetSectionName(sectionname);
        setActiveSection(sectionname);
        window.scrollTo({top: y, behavior: 'smooth'});
    };

    const [offset, setOffset] = useState(100)


    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)

        window.addEventListener('load', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
    }, [])


    // <width control

    const sourceDivRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            const fullWidth = sourceDivRef.current.offsetWidth;
            const adjustedWidth = fullWidth - 15;
            document.documentElement.style.setProperty('--target-width', `${adjustedWidth}px`);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // halfcut

    const sourceDivReff = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            const fullHeight = sourceDivReff.current?.offsetHeight;
            const adjustedHeight = (fullHeight / 2) + 15;
            const final = (adjustedHeight / 2);
            document.documentElement.style.setProperty('--target-height', `${final}px`);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // sticky navbar

    gsap.registerPlugin(ScrollTrigger);

    useLayoutEffect(() => {
        const col4Element = document.querySelector('.concern-nav');

        // Check if the screen width is greater than 1024px (desktop)
        if (window.innerWidth > 991) {
            gsap.to(col4Element, {
                scrollTrigger: {
                    trigger: col4Element,
                    start: 'top top',
                    end: 'max',
                    pin: true,
                    pinSpacing: false
                }
            });
        }

        return () => {
            ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
        };
    }, []);


    return (
        <>
            <StyledMediaTab className={'concern-nav'}>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className={'product-specification-head'}>
                                <ListGroup as={'ul'} horizontal className={'product-specification-ul'}>
                                    {firstSlider &&
                                    <ListGroup.Item as="li" className={activesection === firstSlider?.subtitle ? 'active' : ''}>
                                        <button onClick={() => scrollToSection(section1Ref,firstSlider?.subtitle)}>
                                            <span>{firstSlider?.subtitle}</span>
                                        </button>
                                    </ListGroup.Item>
                                    }
                                    {secondSlider &&
                                    <ListGroup.Item as="li" className={activesection === secondSlider?.subtitle ? 'active' : ''}>
                                        <button onClick={() => scrollToSection(section2Ref,secondSlider?.subtitle)}>
                                            <span>{secondSlider?.subtitle}</span>
                                        </button>
                                    </ListGroup.Item>
                                    }
                                    {thirdSlider &&
                                    <ListGroup.Item as="li" className={activesection === thirdSlider?.subtitle ? 'active' : ''}>
                                        <button onClick={() => scrollToSection(section3Ref,thirdSlider?.subtitle)}>
                                            <span>{thirdSlider?.subtitle}</span>
                                        </button>
                                    </ListGroup.Item>
                                    }

                                </ListGroup>

                                <DropdownButton as={'ul'} className={'product-specification-head__mobile'}
                                                title={sectionName}>
                                    {firstSlider &&
                                    <Dropdown.Item as={'li'}>
                                        <button onClick={() => scrollToSection(section1Ref, firstSlider?.subtitle)}>
                                            <span>{firstSlider?.subtitle}</span>
                                        </button>
                                    </Dropdown.Item>
                                    }
                                    {secondSlider &&
                                    <Dropdown.Item as={'li'}>
                                        <button onClick={() => scrollToSection(section2Ref, secondSlider?.subtitle)}>
                                            <span>{secondSlider?.subtitle}</span>
                                        </button>
                                    </Dropdown.Item>
                                    }
                                    {thirdSlider &&
                                    <Dropdown.Item as={'li'}>
                                        <button onClick={() => scrollToSection(section3Ref, thirdSlider?.subtitle)}>
                                            <span>{thirdSlider?.subtitle}</span>
                                        </button>
                                    </Dropdown.Item>
                                    }
                                </DropdownButton>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </StyledMediaTab>


            <StyledMediaList className={'pb-200'}>
                {firstSlider &&
                <div className={'media-list'} ref={section1Ref}>
                    <StyledComponent className='csr' offset={offset}>
                        <Container>
                            <Row>
                                <p className={'bg-text-one'}>{1}</p>

                                <Col lg={{span: 7, offset: 2}}>
                                    {firstSlider?.subtitle &&
                                        <p className={'upper-title split-left'}>{firstSlider?.subtitle}</p>}
                                </Col>

                                <Col lg={{span: 7, offset: 2}} className={'about-partex__text'}>
                                    {firstSlider?.title &&
                                        <h3 className={'split-up'}>{reactHtmlParser(firstSlider?.title)}</h3>}
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>

                                </Col>
                                <Col lg={4}>
                                    {firstSlider?.description &&
                                        <p className={'lower-para'}>{reactHtmlParser(firstSlider?.description)}</p>}
                                </Col>
                            </Row>
                            <Row className={'gallery-svg__section'}>
                                <Col lg={{span: 6, offset: 6}} ref={sourceDivRef}>
                                    <div className="gallery-svg__section__arrow">
                                        <ul>
                                            <li id={"csr-prev1"} className="left">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49"
                                                     viewBox="0 0 52 52">
                                                    <g id="Button_-_Nav_Buttonn" data-name="Button - Nav Button"
                                                       transform="translate(52 52) rotate(180)">
                                                        <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26"
                                                                cy="26" r="26"
                                                                fill="#323232"></circle>
                                                        <circle id="Ellipse_4378" data-name="Ellipse 437" cx="-26"
                                                                cy="26"
                                                                r="26"
                                                                fill="#E50019"></circle>
                                                        <g id="Group_15984" data-name="Group 15984"
                                                           transform="translate(-96 -5894.5)">
                                                            <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                                                  transform="translate(120.5 5915.5)" fill="none"
                                                                  stroke="#D80028"
                                                                  stroke-linecap="round" stroke-width="2"></line>
                                                            <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                                  transform="translate(120.5 5920.5)" fill="none"
                                                                  stroke="#D80028"
                                                                  stroke-linecap="round" stroke-width="2"></line>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </li>
                                            <li id={"csr-next1"} className="right">
                                                <svg id="Button_-_Nav_Buttonn" data-name="Button - Nav Button"
                                                     xmlns="http://www.w3.org/2000/svg" width="49" height="49"
                                                     viewBox="0 0 52 52">
                                                    <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26" cy="26"
                                                            r="26"
                                                            fill="#323232"></circle>
                                                    <circle id="Ellipse_4378" data-name="Ellipse 437" cx="-26" cy="26"
                                                            r="26"
                                                            fill="#323232"></circle>
                                                    <g id="Group_15984" data-name="Group 15984"
                                                       transform="translate(-96 -5894.5)">
                                                        <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                                              transform="translate(120.5 5915.5)" fill="none"
                                                              stroke="#D80028"
                                                              stroke-linecap="round" stroke-width="2"></line>
                                                        <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                              transform="translate(120.5 5920.5)" fill="none"
                                                              stroke="#D80028"
                                                              stroke-linecap="round" stroke-width="2"></line>
                                                    </g>
                                                </svg>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Container>

                        {/*Gallery*/}
                        <Container className={'version_one'}>
                            <Row>

                                <Col lg={{span: 10, offset: 1}} className={'slider_left'}>


                                </Col>
                            </Row>
                        </Container>
                        <div className="clearfix"></div>
                        <LightgalleryProvider>
                            <Container fluid className={'p-0'}>
                                <Row>
                                    <Col className={'slider_overlap'}>
                                        <Swiper
                                            loop={true}
                                            spaceBetween={15}
                                            speed='1000'
                                            slidesPerView={4}
                                            keyboardControl={true}
                                            initialSlide={1}
                                            modules={[Pagination, Navigation, Mousewheel]}
                                            navigation={{
                                                prevEl: '#csr-prev1',
                                                nextEl: '#csr-next1',
                                            }}
                                            breakpoints={{
                                                320: {
                                                    slidesPerView: 1,
                                                    spaceBetween: 15,

                                                },
                                                768: {
                                                    slidesPerView: 3,
                                                    spaceBetween: 15,

                                                },
                                                1024: {
                                                    slidesPerView: 4,
                                                    spaceBetween: 15,
                                                },
                                            }}
                                            className="mySwiper"
                                        >
                                            {firstSlider?.images?.map((sliderItem, sliderIndex) => (
                                                <SwiperSlide key={sliderIndex} ref={sourceDivReff}>
                                                    <div className="single single_testimonial">
                                                        <LightgalleryItem src={sliderItem?.img}>
                                                            <div className="wrapper_box">
                                                                <div className="image_wrapper">
                                                                    {/*<Img src={'images/dynamic/csr-1.jpg'}/>*/}
                                                                    <Img
                                                                        src={sliderItem?.img ? sliderItem?.img : 'images/static/blur.jpg'}/>
                                                                </div>
                                                            </div>
                                                        </LightgalleryItem>
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </Col>
                                </Row>
                            </Container>
                        </LightgalleryProvider>

                    </StyledComponent>
                </div>
                }

                {secondSlider &&
                <div className={'media-list'} ref={section2Ref}>
                    <StyledComponent className='csr' offset={offset}>
                        <Container>
                            <Row>
                                <p className={'bg-text-one'}>{2}</p>

                                <Col lg={{span: 7, offset: 2}}>
                                    {secondSlider?.subtitle &&
                                        <p className={'upper-title split-left'}>{secondSlider?.subtitle}</p>}
                                </Col>

                                <Col lg={{span: 7, offset: 2}} className={'about-partex__text'}>
                                    {secondSlider?.title &&
                                        <h3 className={'split-up'}>{reactHtmlParser(secondSlider?.title)}</h3>}
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>

                                </Col>
                                <Col lg={4}>
                                    {secondSlider?.description &&
                                        <p className={'lower-para'}>{reactHtmlParser(secondSlider?.description)}</p>}
                                </Col>
                            </Row>
                            <Row className={'gallery-svg__section'}>
                                <Col lg={{span: 6, offset: 6}} ref={sourceDivRef}>
                                    <div className="gallery-svg__section__arrow">
                                        <ul>
                                            <li id={"csr-prev2"} className="left">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49"
                                                     viewBox="0 0 52 52">
                                                    <g id="Button_-_Nav_Buttonn" data-name="Button - Nav Button"
                                                       transform="translate(52 52) rotate(180)">
                                                        <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26"
                                                                cy="26" r="26"
                                                                fill="#323232"></circle>
                                                        <circle id="Ellipse_4378" data-name="Ellipse 437" cx="-26"
                                                                cy="26"
                                                                r="26"
                                                                fill="#E50019"></circle>
                                                        <g id="Group_15984" data-name="Group 15984"
                                                           transform="translate(-96 -5894.5)">
                                                            <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                                                  transform="translate(120.5 5915.5)" fill="none"
                                                                  stroke="#D80028"
                                                                  stroke-linecap="round" stroke-width="2"></line>
                                                            <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                                  transform="translate(120.5 5920.5)" fill="none"
                                                                  stroke="#D80028"
                                                                  stroke-linecap="round" stroke-width="2"></line>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </li>
                                            <li id={"csr-next2"} className="right">
                                                <svg id="Button_-_Nav_Buttonn" data-name="Button - Nav Button"
                                                     xmlns="http://www.w3.org/2000/svg" width="49" height="49"
                                                     viewBox="0 0 52 52">
                                                    <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26" cy="26"
                                                            r="26"
                                                            fill="#323232"></circle>
                                                    <circle id="Ellipse_4378" data-name="Ellipse 437" cx="-26" cy="26"
                                                            r="26"
                                                            fill="#323232"></circle>
                                                    <g id="Group_15984" data-name="Group 15984"
                                                       transform="translate(-96 -5894.5)">
                                                        <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                                              transform="translate(120.5 5915.5)" fill="none"
                                                              stroke="#D80028"
                                                              stroke-linecap="round" stroke-width="2"></line>
                                                        <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                              transform="translate(120.5 5920.5)" fill="none"
                                                              stroke="#D80028"
                                                              stroke-linecap="round" stroke-width="2"></line>
                                                    </g>
                                                </svg>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Container>

                        {/*Gallery*/}
                        <Container className={'version_one'}>
                            <Row>

                                <Col lg={{span: 10, offset: 1}} className={'slider_left'}>


                                </Col>
                            </Row>
                        </Container>
                        <div className="clearfix"></div>
                        <LightgalleryProvider>
                            <Container fluid className={'p-0 middle-after'}>
                                <Row>
                                    <Col className={'slider_overlap'}>
                                        <Swiper
                                            loop={true}
                                            spaceBetween={15}
                                            speed='1000'
                                            slidesPerView={4}
                                            keyboardControl={true}
                                            initialSlide={1}
                                            modules={[Pagination, Navigation, Mousewheel]}
                                            navigation={{
                                                prevEl: '#csr-prev2',
                                                nextEl: '#csr-next2',
                                            }}
                                            breakpoints={{
                                                320: {
                                                    slidesPerView: 1,
                                                    spaceBetween: 15,

                                                },
                                                768: {
                                                    slidesPerView: 3,
                                                    spaceBetween: 15,

                                                },
                                                1024: {
                                                    slidesPerView: 4,
                                                    spaceBetween: 15,
                                                },
                                            }}
                                            className="mySwiper"
                                        >
                                            {secondSlider?.images?.map((sliderItem, sliderIndex) => (
                                                <SwiperSlide key={sliderIndex} ref={sourceDivReff}>
                                                    <div className="single single_testimonial">
                                                        <LightgalleryItem src={sliderItem?.img}>
                                                            <div className="wrapper_box">
                                                                <div className="image_wrapper">
                                                                    {/*<Img src={'images/dynamic/csr-1.jpg'}/>*/}
                                                                    <Img
                                                                        src={sliderItem?.img ? sliderItem?.img : 'images/static/blur.jpg'}/>
                                                                </div>
                                                            </div>
                                                        </LightgalleryItem>
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </Col>
                                </Row>
                            </Container>
                        </LightgalleryProvider>

                    </StyledComponent>
                </div>
                }
                {thirdSlider &&
                    <div className={'media-list'} ref={section3Ref}>
                        <StyledComponent className='csr' offset={offset}>
                            <Container>
                                <Row>
                                    <p className={'bg-text-one'}>{3}</p>

                                    <Col lg={{span: 7, offset: 2}}>
                                        {thirdSlider?.subtitle &&
                                            <p className={'upper-title split-up'}>{thirdSlider?.subtitle}</p>}
                                    </Col>

                                    <Col lg={{span: 7, offset: 2}} className={'about-partex__text'}>
                                        {thirdSlider?.title &&
                                            <h3 className={'split-up'}>{reactHtmlParser(thirdSlider?.title)}</h3>}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>

                                    </Col>
                                    <Col lg={4}>
                                        {thirdSlider?.description &&
                                            <p className={'lower-para split-up-up'}>{reactHtmlParser(thirdSlider?.description)}</p>}
                                    </Col>
                                </Row>
                                <Row className={'gallery-svg__section'}>
                                    <Col lg={{span: 6, offset: 6}} ref={sourceDivRef}>
                                        <div className="gallery-svg__section__arrow">
                                            <ul>
                                                <li id={"csr-prev3"} className="left">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49"
                                                         viewBox="0 0 52 52">
                                                        <g id="Button_-_Nav_Buttonn" data-name="Button - Nav Button"
                                                           transform="translate(52 52) rotate(180)">
                                                            <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26"
                                                                    cy="26" r="26"
                                                                    fill="#323232"></circle>
                                                            <circle id="Ellipse_4378" data-name="Ellipse 437" cx="-26"
                                                                    cy="26"
                                                                    r="26"
                                                                    fill="#E50019"></circle>
                                                            <g id="Group_15984" data-name="Group 15984"
                                                               transform="translate(-96 -5894.5)">
                                                                <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                                                      transform="translate(120.5 5915.5)" fill="none"
                                                                      stroke="#D80028"
                                                                      stroke-linecap="round" stroke-width="2"></line>
                                                                <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                                      transform="translate(120.5 5920.5)" fill="none"
                                                                      stroke="#D80028"
                                                                      stroke-linecap="round" stroke-width="2"></line>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </li>
                                                <li id={"csr-next3"} className="right">
                                                    <svg id="Button_-_Nav_Buttonn" data-name="Button - Nav Button"
                                                         xmlns="http://www.w3.org/2000/svg" width="49" height="49"
                                                         viewBox="0 0 52 52">
                                                        <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26" cy="26"
                                                                r="26"
                                                                fill="#323232"></circle>
                                                        <circle id="Ellipse_4378" data-name="Ellipse 437" cx="-26" cy="26"
                                                                r="26"
                                                                fill="#323232"></circle>
                                                        <g id="Group_15984" data-name="Group 15984"
                                                           transform="translate(-96 -5894.5)">
                                                            <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                                                  transform="translate(120.5 5915.5)" fill="none"
                                                                  stroke="#D80028"
                                                                  stroke-linecap="round" stroke-width="2"></line>
                                                            <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                                  transform="translate(120.5 5920.5)" fill="none"
                                                                  stroke="#D80028"
                                                                  stroke-linecap="round" stroke-width="2"></line>
                                                        </g>
                                                    </svg>
                                                </li>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>

                            {/*Gallery*/}
                            <Container className={'version_one'}>
                                <Row>

                                    <Col lg={{span: 10, offset: 1}} className={'slider_left'}>


                                    </Col>
                                </Row>
                            </Container>
                            <div className="clearfix"></div>
                            <LightgalleryProvider>
                                <Container fluid className={'p-0'}>
                                    <Row>
                                        <Col className={'slider_overlap'}>
                                            <Swiper
                                                loop={true}
                                                spaceBetween={15}
                                                speed='1000'
                                                slidesPerView={4}
                                                keyboardControl={true}
                                                initialSlide={1}
                                                modules={[Pagination, Navigation, Mousewheel]}
                                                navigation={{
                                                    prevEl: '#csr-prev3',
                                                    nextEl: '#csr-next3',
                                                }}
                                                breakpoints={{
                                                    320: {
                                                        slidesPerView: 1,
                                                        spaceBetween: 15,

                                                    },
                                                    768: {
                                                        slidesPerView: 3,
                                                        spaceBetween: 15,

                                                    },
                                                    1024: {
                                                        slidesPerView: 4,
                                                        spaceBetween: 15,
                                                    },
                                                }}
                                                className="mySwiper"
                                            >
                                                {thirdSlider?.images?.map((sliderItem, sliderIndex) => (
                                                    <SwiperSlide key={sliderIndex} ref={sourceDivReff}>
                                                        <div className="single single_testimonial">
                                                            <LightgalleryItem src={sliderItem?.img}>
                                                                <div className="wrapper_box">
                                                                    <div className="image_wrapper">
                                                                        {/*<Img src={'images/dynamic/csr-1.jpg'}/>*/}
                                                                        <Img
                                                                            src={sliderItem?.img ? sliderItem?.img : 'images/static/blur.jpg'}/>
                                                                    </div>
                                                                </div>
                                                            </LightgalleryItem>
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        </Col>
                                    </Row>
                                </Container>
                            </LightgalleryProvider>

                        </StyledComponent>
                    </div>
                }



            </StyledMediaList>

        </>
    )
};

const StyledComponent = styled.section`


  &.about-partex-one {
    padding-top: 200px;
    @media (max-width: 991px) {
      padding-top: 100%;
    }
  }

  //&.about-partex-2, &.about-partex-3{
  //  @media (max-width: 991px){
  //    padding-bottom: 0;
  //  }
  //}


  @media (max-width: 991px) {
    padding-top: 430px;
    padding-bottom: 0px;
  }

  .container-fluid {
    position: relative;
    background-color: white;
    position: relative;
    bottom: -1px;
    @media (max-width: 991px) {
      padding-bottom: 0 !important;
    }
  }

  //.middle-after {
  //  &:after {
  //    content: '';
  //    position: absolute;
  //    bottom: 0;
  //    left: 0;
  //    width: 100%;
  //    height: 75%;
  //    background: #E4E4E4;
  //  }
  //}

  .row {
    position: relative;
  }

  .about-partex__text {
    position: relative;
  }

  .bg-text-one {
    font-size: 240px;
    line-height: 240px;
    font-weight: 400;
    opacity: 60%;
    overflow: visible;
    margin: 0;
    color: #E4E4E4;
    position: absolute;
    white-space: nowrap;


    @media (max-width: 991px) {
      font-weight: 400;
      font-size: 240px;
      line-height: 240px;
      position: absolute;
      top: -270px;
      padding-left: 15px;
    }
  }


  .bg-text-two {
    left: -10%;
    bottom: 0;
  }

  .upper-title {
    width: 50%;
    margin: 0 0 20px 0;
    font-weight: 500;;
  }

  h3 {
    margin: 0 0 80px 0;

    span {
      color: #E50019;
    }
  }

  p {
    margin: 0 0 80px 0;
  }

  .upper-pera {
    font-weight: 500;
    margin: 0 0 20px 0;
  }

  .lower-para {
    margin: 0;
  }

  @media (max-width: 991px) {

    .upper-title {
      width: 100%;
    }

    h3, p, .sub-col {
      margin: 0 0 40px 0;
    }
  }

  //Gallery

  .swiper {
    padding-left: ${props => props.offset ? props.offset + 15 + 'px' : '100px'};
    height: var(--target-width) !important;
    @media (min-width: 768px) and (max-width: 991px) {
      height: 100% !important;
    }
  }

  .swiper-button-next, .swiper-button-prev {
    display: none;
  }

  .gallery-svg__section {
    padding: 80px 0;

    &__arrow {
      ul {
        display: flex;

        li {
          margin-right: 20px;
        }

        svg {
          border-radius: 50px;

          #Ellipse_4378 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            r: 26;
            cursor: pointer;
          }

          line {
            stroke: white;
          }

          &:hover {
            #Ellipse_4378 {
              fill: #E50019;
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              cx: 26;
            }

            g {
              line {
                stroke: #f9f9f9;
              }
            }
          }
        }
      }
    }

    @media (max-width: 991px) {
      padding: 40px 0;
    }
  }


  .swiper-slide {
    transition: 0.7s all ease;
    padding-right: 15px;
    @media (max-width: 991px) {
      padding-right: 0;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      padding-left: 15px;
    }
  }

  .swiper-pagination {
    width: auto;
    bottom: 120px;
    left: 155px;
    @media (min-width: 1550px) {
      //bottom: 60px;
    }
    @media (max-width: 991px) {
      left: 5px;
      bottom: 85px;
    }

    .swiper-pagination-bullet {
      width: 8px;
      height: 3px;
      display: inline-block;
      background: #C49F8A;
      border-radius: 4px;
      opacity: 1;
      margin: 0 10px;

      &.swiper-pagination-bullet-active {
        width: 70px !important;
        background: #565440;
      }
    }
  }


  .swiper-slide-active {
    width: var(--target-width) !important;
    @media (max-width: 991px) {
      width: 100% !important;
    }

  }

  .single_testimonial {

    .image_wrapper {
      padding-top: calc(570 / 570 * 100%) !important;

      img {
        transform: unset !important;
      }
    }
  }
}

.single_testimonial {
  //padding-right: 15px;
  box-sizing: border-box;
  @media (max-width: 991px) {
    padding-right: 15px;
    padding-left: 15px;
  }
  @media (max-width: 991px) {
    padding-right: 15px;
  }

  .wrapper_box {
    display: flex;
    position: relative;

    @media (max-width: 991px) {
      flex-direction: column;
      .image_wrapper {
        margin-bottom: 30px;
      }
    }


    .image_wrapper {
      padding-top: calc(312 / 370 * 100%);
      transition: 0.7s all ease;
      position: relative;
      cursor: pointer;
      overflow: hidden;

      width: 100%;

      .play {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;

        svg {
          #Ellipse_396 {
            transition: 0.7s all ease;
          }
        }
      }

      .global-image {
        transition: 0.7s all ease;
        transform: scale(1.01);
      }

      &:hover {
        .global-image {
          transform: scale(1.06);
        }

        svg {
          #Ellipse_396 {
            r: 25;
          }
        }
      }
    }


  }
}

@media (max-width: 991px) {
  .swiper {
    padding-left: unset;

    .container-fluid {
      padding: 0 15px 0 0 !important;
    }

    .title {
      margin-bottom: 30px !important;
    }
  }

`;

const StyledMediaTab = styled.section`
  background: #323232;
  position: sticky;
  z-index: 100;

  .product-specification-head {
    .product-specification-ul {
      border-radius: 0;

      .list-group-item {
        padding: 0;
        border: 0;
        background-color: transparent;
        border-radius: 0;

        &.active {
          button, span {
            color: #FFFFFF;
          }
        }

        button {
          font-size: 20px;
          line-height: 24px;
          font-weight: 500;
          padding: 20px 30px;
          display: flex;
          color: rgba(255, 255, 255, 0.5);
          height: 100%;
          background-color: transparent;
          border: 0;

          &:hover {
            color: #FFFFFF;

            span {
              color: #FFFFFF;
            }
          }
        }

        &:nth-child(1) {
          button {
            padding-left: 0;
          }
        }
      }

    }

    &__mobile {
      display: none;
    }
  }

  @media (max-width: 991px) {
    .col-sm-12 {
      padding: 0;
    }

    .product-specification-head {
      &__mobile {
        button {
          font-size: 20px;
          font-weight: 500;
        }
      }
    }

    .product-specification-head {
      .product-specification-ul {
        display: none;
      }
      .dropdown{
        .active{
          button{
            background-color: #98223C;
          }
        }
      }

      &__mobile {
        display: block;

        .dropdown-toggle {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px 15px;
          border-radius: 0;
          background-color: #323232;
          border: 0;
          position: relative;

          &:after {
            display: none;
          }

          &:before {
            content: '';
            background-image: url('/images/static/drop.svg');
            position: absolute;
            right: 15px;
            height: 10px;
            width: 12px;
            top: 50%;
            background-repeat: no-repeat;
            transform: translateY(-5px) rotate(0deg);
          }

          &:active {
            background-color: transparent;
            border: 0;

            &:before {
              transform: translateY(-10px) rotate(180deg);
            }
          }
        }

        &-menu {
          width: 100%;
        }

        .dropdown-item {
          padding: 0;

          &:nth-child(1) {
            button {
              padding-top: 20px;
            }
          }

          &.active {
            background-color: rgba(50, 50, 50, 0.5);

            button {
              color: #ffffff;

              span {
                color: #ffffff;
              }
            }
          }

          &:active {
            background-color: transparent;
          }

          button {
            width: 100%;
            display: block;
            padding: 10px 15px;
            border: 0;
            text-align: left;

            &:before {
              content: unset;
            }
          }
        }

        .dropdown-menu {
          width: 100%;
          padding: 0;
          margin: 0;
          border-radius: 0;
        }
      }
    }
  }
`;

const StyledMediaList = styled.section`
  .media-list:nth-child(odd) {
    .csr {
      background-color: #E4E4E4;

      .container-fluid {
        position: relative;
        bottom: -1px;
        @media (max-width: 991px) {
          padding-bottom: 0px !important;
        }

        &:after {
          content: '';
          top: 0;
          left: 0;
          width: 100%;
          height: var(--target-height);
          background-color: #E4E4E4;
          position: absolute;
          @media (max-width: 991px) {
            height: 50%;
          }
        }
      }

      .bg-text-one {
        color: #fff;
      }
    }
  }

  .csr {
    padding-top: 200px;
    @media (max-width: 991px) {
      padding-top: 430px;
    }
  }

`;
export default Navbar;
