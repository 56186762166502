import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";

import Title from "../Title";
import Button from "../Button";
import {BsChevronLeft, BsChevronRight} from 'react-icons/bs';
import {black} from "../../styles/globalStyleVars";
import React, {useEffect, useRef, useState} from "react";
import VisibilitySensor from "react-visibility-sensor";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {hoverr} from '../../styles/globalStyleVars'
import {Link} from "react-router-dom";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

const MediaCenter = ({data}) => {
    // SwiperCore.use([Autoplay]);


    let leftRef = useRef();
    let rightRef = useRef();
    let mLeftRef = useRef();
    let mRightRef = useRef();
    let containerRef = useRef();
    let sliderRef = useRef();
    const [current, setCurrent] = useState('')
    let [offset, setOffset] = useState(90)
    let [theWidth, SetTheWidth] = useState(0)
    let [activeNumber, setActiveNumber] = useState(1)
    let [totalNumber, setTotalNumber] = useState(5)


    useEffect(() => {
        setOffset(containerRef.current?.offsetLeft)
        window.addEventListener('resize', () => {
            setOffset(containerRef.current?.offsetLeft)
            SetTheWidth(window.innerWidth)
        })

        // slider number
        const getActiveItem = document.querySelector('.swiper-pagination-current')?.innerHTML
        const getTotalItem = document.querySelector('.swiper-pagination-total')?.innerHTML
        setActiveNumber(getActiveItem)
        setTotalNumber(getTotalItem)
    }, [null])


    useEffect(() => {
        const getTotalItem = 5
        setTotalNumber(getTotalItem)
    }, [data])

    let sliderNumber = () => {
        const getActiveItem = document.querySelector('.swiper-pagination-current').innerHTML
        // const getTotalItem = document.querySelector('.swiper-pagination-total').innerHTML
        setActiveNumber(getActiveItem)
        // setTotalNumber(getTotalItem)
    }


    return (
        <StyledBlog offset={offset} className='blog-slider pt-200 pb-200'>
            <Container ref={containerRef}>
                <Row>
                    <Col sm={12} className="full-title">
                        <Col md={5} className="blog-title">
                            {data?.title && <h3 className={'split-up'}>{reactHtmlParser(data?.title)}</h3>}
                        </Col>

                        <div className="blog-button">
                            <div className="slider-nav">
                                <ul>
                                    <li className=" slider_prev">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52">
                                            <g id="Button_-_Nav_Button" data-name="Button - Nav Button"
                                               transform="translate(52 52) rotate(180)">
                                                <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                        fill="#323232"></circle>
                                                <circle id="Ellipse_4378" data-name="Ellipse 437" cx="-26" cy="26" r="26"
                                                        fill="#E50019"></circle>
                                                <g id="Group_15984" data-name="Group 15984"
                                                   transform="translate(-96 -5894.5)">
                                                    <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                                          transform="translate(120.5 5915.5)" fill="none" stroke="#D80028"
                                                          stroke-linecap="round" stroke-width="2"></line>
                                                    <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                          transform="translate(120.5 5920.5)" fill="none" stroke="#D80028"
                                                          stroke-linecap="round" stroke-width="2"></line>
                                                </g>
                                            </g>
                                        </svg>
                                    </li>
                                    <li className=" slider_next">
                                        <svg id="Button_-_Nav_Button" data-name="Button - Nav Button"
                                             xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52">
                                            <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                    fill="#323232"></circle>
                                            <circle id="Ellipse_4378" data-name="Ellipse 437" cx="-26" cy="26" r="26"
                                                    fill="#323232"></circle>
                                            <g id="Group_15984" data-name="Group 15984" transform="translate(-96 -5894.5)">
                                                <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                                      transform="translate(120.5 5915.5)" fill="none" stroke="#D80028"
                                                      stroke-linecap="round" stroke-width="2"></line>
                                                <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                      transform="translate(120.5 5920.5)" fill="none" stroke="#D80028"
                                                      stroke-linecap="round" stroke-width="2"></line>
                                            </g>
                                        </svg>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <VisibilitySensor offset={{top: -150}}>
                {({isVisible}) =>
                    <div className={` fade-up blog-slider__slider-wrap`}>
                        <div className="blog-slider__slider-wrap__inner">
                            <Swiper loop={true}
                                    spaceBetween={30}
                                    slidesPerView={3}
                                    allowSlideNext={true}
                                    allowSlidePrev={true}
                                    allowTouchMove={true}
                                    speed={900}
                                    pagination={{
                                        type: "fraction",
                                    }}
                                    navigation={{
                                        prevEl: '.slider_prev',
                                        nextEl: '.slider_next',
                                    }}
                                    modules={[Autoplay, Pagination, Navigation]}

                                // autoplay={{
                                //     delay: 2500,
                                //     disableOnInteraction: false,
                                // }}

                                    breakpoints={{
                                        300: {
                                            slidesPerView: 1,
                                            spaceBetween: 30,
                                        },
                                        768: {
                                            slidesPerView: 2,
                                            spaceBetween: 30,
                                        },
                                        1024: {
                                            slidesPerView: 3,
                                            spaceBetween: 30,
                                        },
                                    }}
                                    onSlideChange={(s) => sliderNumber()}

                                // navigation={true} modules={[Navigation]}
                                //     onSwiper={(swiper) => console.log(swiper)}

                                    ref={sliderRef}>

                                {data?.slider?.map((item, index) => (
                                    <SwiperSlide key={index}>

                                        <div className="blog-single">
                                            <div className="blog-single__inner">
                                                {/*<Link to={'/'}></Link>*/}
                                                <Img src={item?.image} objectFit={'cover'} layout={'fill'}/>
                                                <div className="blog-single__inner__content">
                                                    <div className="blog-single__inner__content__top">
                                                        <div className="upper">
                                                            <p>{item?.description}</p>
                                                        </div>

                                                        <h2>{index+1}</h2>

                                                    </div>

                                                    <div className="blog-single__inner__content__bottom">
                                                        <h4>{item?.title}</h4>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </SwiperSlide>
                                ))}

                            </Swiper>
                        </div>
                    </div>
                }
            </VisibilitySensor>
        </StyledBlog>
    );
};

const StyledBlog = styled.section`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 59.5%;
    background-color: #E4E4E4;
  }

  .sub-title {
    font-weight: 500 !important;
    margin: 0 0 20px 0;
  }

  .full-title {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 0 80px 0;
  }

  .blog-title {
    padding-left: 0;

    h3 {
      span {
        color: ${hoverr};
      }
    }
  }

  .blog-single {
    .blog-single__inner {
      padding-top: calc(370 / 370 * 100%);
      position: relative;

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 3;
      }

      &__content {
        &:after {
          content: '';
          height: 0;
          width: 100%;
          background-color: #E50019;
          opacity: 75%;
          fill: #E50019;
          bottom: 0;
          left: 0;
          right: 0;
          position: absolute;
          transition: height .4s ease;
        }

        &__top {
          .upper {
            position: absolute;
            left: 30px;
            top: 40px;
            z-index: 2;
            right: 30px;
            margin: 0;
            transform: translateY(-30px);
            opacity: 0;
            transition: all .6s ease;
          }

          p {
            font-size: 20px;
            line-height: 24px;
            font-weight: 300;
            color: #FFFFFF;
            margin: 0 0 40px 0;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }

          .read-more {
            font-size: 20px;
            line-height: 24px;
            font-weight: 300;
            color: #FFFFFF;
            margin: 0;
            text-decoration: underline;
          }

          h2 {
            position: absolute;
            top: 40px;
            left: 40px;
            right: 40px;
            z-index: 2;
            font-size: 72px;
            font-weight: 400;
            line-height: 84px;
            color: #FFFFFF;
            margin: 0;
            transition: all .3s ease;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }
        }

        &__bottom {
          position: absolute;
          margin: 0;
          left: 40px;
          right: 40px;
          bottom: 40px;
          display: flex;
          justify-content: space-between;
          padding-top: 20px;
          z-index: 2;

          h3 {
            color: #FFFFFF;
            transition: color .3s ease;

            p {
              display: block;
              color: #FFFFFF;
            }
          }

          h4 {
            color: #FFFFFF;
          }

          p {
            position: relative;
            top: 40px;
            left: 40px;
            font-size: 16px;
            color: #FFFFFF;
            text-align: right;
            line-height: 20px;
            transition: color .3s ease;

            span {
              display: block;
              color: #FFFFFF;
            }
          }
        }
      }

      &:hover {
        .blog-single__inner__content__top {
          h2 {
            opacity: 0;
            transform: translateY(-20px);
          }

          .upper {
            transform: none;
            opacity: 1;
          }
        }

        .blog-single__inner__content__bottom {
          border-color: #FFF;

          h4 {
            color: #ffffff;
          }

          p {
            color: #ffffff;

            span {
              color: #ffffff;
            }
          }
        }
      }

    }

    &:hover {
      .blog-single__inner__content:after {
        height: 100%;
      }
    }
  }

  .blog-button {
    display: flex;
    justify-content: space-between;
    //margin-bottom: 85px;
    align-items: center;

    .slider-nav {
      ul {
        display: flex;
      }

      li {
        height: 50px;
        width: 50px;
        background-color: rgb(34, 31, 31);
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;

        &:nth-of-type(1) {
          margin-right: 20px;
        }

      }
    }
  }

  svg {
    border-radius: 50px;

    #Ellipse_4378 {
      transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
      r: 26;
      cursor: pointer;
    }

    line {
      stroke: white;
    }

    &:hover {
      #Ellipse_4378 {
        fill: #E50019;
        transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
        cx: 26;
      }

      g {
        line {
          stroke: #f9f9f9;
        }
      }
    }
  }

  .swiper-button-next, .swiper-button-prev {
    position: absolute;
    height: 40px;
    width: 40px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 99;
    background-color: red;
  }

  .swiper-button-next, .swiper-button-prev, .swiper-pagination {
    opacity: 0;
    visibility: hidden;
  }

  .swiper-initialized {
    margin-left: ${props => props.offset + 15}px;
    padding-right: ${props => props.offset + 15}px;
  }


  .slider-nav-mobile {
    margin-top: 80px;
  }

  @media (max-width: 767px) {
    .swiper-initialized {
      margin: 0;
      padding: 0;
    }

    .blog-slider {
      &__slider-wrap {
        margin: 0;
      }
    }

    .full-title {
      display: unset;
      margin: 0;
    }

    .blog-title {
      padding: 0;
      margin-bottom: 40px;
    }

    .blog-button {
      margin-bottom: 40px;
    }

    .blog-single {
      .blog-single__inner {
        padding-top: calc(440 / 375 * 100%);

        &__content {
          &__bottom {
            h3 {
              color: ${hoverr};

              p {
                color: #FFFFFF;
              }
            }
          }
        }

        &:hover {
          .blog-single__inner__content__bottom {
            h3 {
              color: #ffffff;
            }
          }
        }
      }

      .blog-single__inner__content__top {
        .upper, h2 {
          left: 15px;
          right: 15px;
        }
      }

      .blog-single__inner__content__bottom h4, .blog-single__inner__content__bottom p {
        left: 15px;
        right: 15px;
      }
    }

    .slider-nav-mobile {
      margin-top: 40px;
    }

  }

`;
export default MediaCenter;