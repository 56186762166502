import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";
import {ImgPreload} from "../ImgPreload";
import Button from "../Button";

const Overview = ({data, link}) => {
    return (
        <StyledComponent className='about-partex pt-200 pb-200 horizontal-scroll' data-speed-right={20}
                         data-speed-left={-30}>
            <Container>
                <Row>
                    {data?.back_text_one &&
                        <p className={'scroll-right opacity-30 bg-text-one'}>{data?.back_text_one}</p>}
                    {data?.back_text_two &&
                        <p className={'scroll-left opacity-30 bg-text-two'}>{data?.back_text_two}</p>}

                    <Col sm={12}>
                        {data?.subtitle && <p className={'upper-title split-left'}>{reactHtmlParser(data?.subtitle)}</p>}
                    </Col>

                    <Col md={9}>
                        {data?.title && <h3 className={'split-up'}>{reactHtmlParser(data?.title)}</h3>}
                    </Col>

                    <Col md={3}></Col>

                    <Col md={2}>
                        {data?.logo &&
                            <div className="img-wrapper">
                                <img src={data?.logo}/>
                            </div>}
                    </Col>

                    <Col md={{span: 9, offset: 1}}>
                        {data?.description && <p>{reactHtmlParser(data?.description)}</p>}
                        {link &&
                            <div>
                                <Button ext={link} text={'Visit Website'} background={'#F5F5F5'} color={'#323232'}
                                        margin={'40px 0 0 0'}/>
                            </div>
                        }
                    </Col>

                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: #E4E4E4;

  .row {
    position: relative;
  }

  .bg-text-one, .bg-text-two {
    font-size: 240px;
    line-height: 240px;
    font-weight: 700;
    margin: 0;
    color: #fff;
    position: absolute;
    white-space: nowrap;


    @media (max-width: 767px) {
      font-size: 120px;
      line-height: 120px;
    }
  }

  .bg-text-one {
    right: 0%;
    top: 60px;
  }

  .bg-text-two {
    left: 5%;
    top: 300px;

    @media (max-width: 767px) {
      top: 180px;
    }
  }

  .upper-title {
    margin: 0 0 20px 0;
  }

  h3 {
    margin: 0 0 80px 0;

    span {
      color: #E50019;
    }
  }

  //p {
  //  margin: 0 0 80px 0;
  //}

  .img-wrapper {
    position: relative;
    padding-top: calc(100 / 200 * 100%);
    margin-bottom: 40px;

    img {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      object-fit: cover;
    }
  }

  @media (max-width: 767px) {
    .img-wrapper {
      padding-top: unset;
      display: flex;
      justify-content: center;

      img {
        position: unset;
        height: 100px;
        width: 200px;
      }
    }
  }

`;

export default Overview;
