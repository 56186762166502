import React, {useEffect, useRef,useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import 'react-modal-video/css/modal-video.min.css'
import {LightgalleryItem, LightgalleryProvider} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Mousewheel, Navigation, Pagination} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import reactHtmlParser from "react-html-parser";
const Overview = ({title,data}) => {
    const next = () => {
        document.querySelector('.living-legend .mySwiper .swiper-button-next').click();
        console.log("Clicked");
    }

    const prev = () => {
        document.querySelector('.living-legend .mySwiper .swiper-button-prev').click();
    }


    const [offset, setOffset] = useState(100)


    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)

        window.addEventListener('load', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
    }, [])


    // <width control

    const sourceDivRef = useRef(null);
    const targetDivRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            const fullWidth = sourceDivRef.current.offsetWidth;
            const adjustedWidth = fullWidth-15;
            document.documentElement.style.setProperty('--target-width', `${adjustedWidth}px`);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // halfcut

    const sourceDivReff = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            const fullHeight = sourceDivReff.current.offsetHeight;
            const adjustedHeight = (fullHeight / 2) +15;
            const final = (adjustedHeight / 2);
            document.documentElement.style.setProperty('--target-height', `${final}px`);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <StyledComponent className=' living-legend pb-200' offset={offset}>
            <Container >
                <Row>
                    <Col md={6} ref={sourceDivRef}>

                    </Col>
                </Row>
                <Row className={'gallery-svg__section'}>

                    <Col md={{span:5,offset:4}}>
                        <h3 className={'gallery-svg__section__title'}>{reactHtmlParser(title)}</h3>
                    </Col>
                    <Col md={3}>
                        <div className="gallery-svg__section__arrow">
                            <ul>
                                <li onClick={prev} className="left">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 52 52">
                                        <g id="Button_-_Nav_Buttonn" data-name="Button - Nav Button"
                                           transform="translate(52 52) rotate(180)">
                                            <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                    fill="#323232"></circle>
                                            <circle id="Ellipse_4378" data-name="Ellipse 437" cx="-26" cy="26" r="26"
                                                    fill="#E50019"></circle>
                                            <g id="Group_15984" data-name="Group 15984"
                                               transform="translate(-96 -5894.5)">
                                                <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                                      transform="translate(120.5 5915.5)" fill="none" stroke="#D80028"
                                                      stroke-linecap="round" stroke-width="2"></line>
                                                <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                      transform="translate(120.5 5920.5)" fill="none" stroke="#D80028"
                                                      stroke-linecap="round" stroke-width="2"></line>
                                            </g>
                                        </g>
                                    </svg>
                                </li>
                                <li  onClick={next} className="right">
                                    <svg id="Button_-_Nav_Buttonn" data-name="Button - Nav Button"
                                         xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 52 52">
                                        <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26" cy="26" r="26"
                                                fill="#323232"></circle>
                                        <circle id="Ellipse_4378" data-name="Ellipse 437" cx="-26" cy="26" r="26"
                                                fill="#323232"></circle>
                                        <g id="Group_15984" data-name="Group 15984" transform="translate(-96 -5894.5)">
                                            <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                                  transform="translate(120.5 5915.5)" fill="none" stroke="#D80028"
                                                  stroke-linecap="round" stroke-width="2"></line>
                                            <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                  transform="translate(120.5 5920.5)" fill="none" stroke="#D80028"
                                                  stroke-linecap="round" stroke-width="2"></line>
                                        </g>
                                    </svg>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>

            {/*Gallery*/}
            <Container className={'version_one'}>
                <Row>

                    <Col md={{span: 10, offset: 1}} className={'slider_left'}>


                    </Col>
                </Row>
            </Container>
            <div className="clearfix"></div>
            <LightgalleryProvider>

                <Container fluid className={'p-0'}>
                    <Row>
                        <Col className={'slider_overlap'}>
                            <Swiper
                                loop={true}
                                spaceBetween={15}
                                speed='1000'
                                slidesPerView={4}
                                keyboardControl={true}
                                initialSlide={1}
                                modules={[ Pagination, Navigation, Mousewheel]}
                                navigation
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1,
                                        spaceBetween: 15,

                                    },
                                    768: {
                                        slidesPerView: 1,
                                        spaceBetween: 15,

                                    },
                                    1025: {
                                        slidesPerView: 4,
                                        spaceBetween: 15,
                                    },
                                }}
                                className="mySwiper"
                            >

                                {data?.map((item, index) => (
                                <SwiperSlide key={index} ref={sourceDivReff}>
                                    <div className="single single_testimonial">
                                        <LightgalleryItem src={item?.image}>
                                            <div className="wrapper_box ">
                                                <div className="image_wrapper">
                                                    <Img src={item?.image ? item?.image : 'images/static/blur.jpg'} alt=""/>
                                                </div>
                                            </div>
                                        </LightgalleryItem>
                                    </div>
                                </SwiperSlide>
                                ))}
                            </Swiper>
                        </Col>
                    </Row>
                </Container>
            </LightgalleryProvider>

        </StyledComponent>
    )
};

const StyledComponent = styled.section`
  margin-top: -1px;
  position: relative;
  background: #323232;
  
  &::after{
    content:'';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 200px;
    width: 100%;
    background: #FFFFFF;
  }
  
  &.living-legend{
    //background-color: #323232;
    .container-fluid{
      bottom: -2px;
      &:after{
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 43%;
        background-color: #323232;
        position: absolute;
        @media (max-width: 767px){
          margin-top: -1px;
          height: 37%;
        }
        @media (max-width: 991px){
          height: 50%;
        }
      }
    }
    .bg-text-one{
      color: #fff;
    }
  }


 

  .container-fluid{
    background-color: white;
    position: relative;
    @media(max-width: 767px){
      padding-bottom: 150px;
    }
  }
  .row {
    position: relative;
  }

  .about-partex__text {
    position: relative;
  }

  .bg-text-one {
    font-size: 240px;
    line-height: 240px;
    font-weight: 500;
    opacity: 60%;
    margin: 0;
    color: #E4E4E4;
    position: absolute;
    white-space: nowrap;


    @media (max-width: 767px) {
      font-weight: 400;
      font-size: 240px;
      line-height: 240px;
      position: absolute;
      top: -270px;
      padding-left: 15px;
    }
  }


  .bg-text-two {
    left: -10%;
    bottom: 0;
  }

  .upper-title {
    width: 50%;
    margin: 0 0 20px 0;
    font-weight: 500;;
  }


  p {
    margin: 0 0 80px 0;
  }

  .upper-pera {
    font-weight: 500;
    margin: 0 0 20px 0;
  }

  .lower-para {
    margin: 0;
  }

  @media (max-width: 767px) {

    .upper-title {
      width: 100%;
    }

    h3, p, .sub-col {
      margin: 0 0 40px 0;
    }
  }

  //Gallery

  .swiper {
    padding-left: ${props => props.offset ? props.offset + 15 + 'px' : '100px'};
    height: var(--target-width) !important;
    @media (min-width: 768px) and (max-width: 991px){
      height: 100%!important;
    }
  }

  .swiper-button-next, .swiper-button-prev {
    display: none;
  }

  .gallery-svg__section {
    padding: 0 0 80px 0;
    h3{
      color: #fff;
    }
    &__arrow {
      ul {
        display: flex;
        justify-content: flex-end;
        @media (max-width: 767px){
          justify-content: flex-start;
        }

        li {
          margin-right: 20px;
        }

        svg {
          border-radius: 50px;
          
            circle{
              fill:#ffffff;
            }
          
          #Ellipse_4378 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            r: 26;
            cursor: pointer;
          }

          line {
            stroke: black;
            transition: all 0.5s ease;
          }

          &:hover {
            #Ellipse_4378 {
              fill: #E50019;
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              cx: 26;
            }

            g {
              line {
                stroke: #f9f9f9;
                transition: all 0.5s ease;
              }
            }
          }
        }
      }
    }

    @media (max-width: 767px) {
      padding: 40px 0;
    }
   .title{
     color: #FFFFFF;
   }
  }


  .swiper-slide {
    transition: 0.7s all ease;
    padding-right: 15px;
    @media (max-width: 767px){
      padding-right: 0;
    }
    @media(min-width: 768px) and (max-width: 991px){
      padding-left: 15px;
    }
  }

  .swiper-pagination {
    width: auto;
    bottom: 120px;
    left: 155px;
    @media (min-width: 1550px) {
      //bottom: 60px;
    }
    @media (max-width: 767px) {
      left: 5px;
      bottom: 85px;
    }

    .swiper-pagination-bullet {
      width: 8px;
      height: 3px;
      display: inline-block;
      background: #C49F8A;
      border-radius: 4px;
      opacity: 1;
      margin: 0 10px;

      &.swiper-pagination-bullet-active {
        width: 70px !important;
        background: #565440;
      }
    }
  }

  .swiper-slide {
    @media (max-width: 767px) {
      //width: inherit !important;

    }
  }

  .swiper-slide-active {
    width: var(--target-width) !important;
    @media (max-width: 991px) {
      width: 100% !important;
    }

  }

  .single_testimonial {

    .image_wrapper {
      padding-top: calc(570 / 570 * 100%) !important;
      img{
        transform: unset!important;
      }
    }
  }
}

.single_testimonial {
  //padding-right: 15px;
  box-sizing: border-box;
  @media (max-width: 991px) {
    padding-right: 15px;
    padding-left: 15px;
  }
  @media (max-width: 991px) {
    padding-right: 15px;
  }

  .wrapper_box {
    display: flex;
    position: relative;

    @media (max-width: 767px) {
      flex-direction: column;
      .image_wrapper {
        margin-bottom: 30px;
      }
    }


    .image_wrapper {
      padding-top: calc(312 / 370 * 100%);
      transition: 0.7s all ease;
      position: relative;
      cursor: pointer;
      overflow: hidden;

      width: 100%;

      .play {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;

        svg {
          #Ellipse_396 {
            transition: 0.7s all ease;
          }
        }
      }

      .global-image {
        transition: 0.7s all ease;
        transform: scale(1.01);
      }

      &:hover {
        .global-image {
          transform: scale(1.06);
        }

        svg {
          #Ellipse_396 {
            r: 25;
          }
        }
      }
    }


  }
}

@media (max-width: 991px) {
  .swiper {
    padding-left: unset;

    .container-fluid {
      padding: 0 15px 0 0 !important;
    }

    .title {
      margin-bottom: 30px !important;
    }
  }

`;

export default Overview;
