import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import Modal from "react-bootstrap/Modal";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "../Img";
import SimpleBar from 'simplebar-react';
import gsap, { Power4 } from 'gsap';
import 'simplebar-react/dist/simplebar.min.css';
import Title from "../Title";
import ReactHtmlParser from "react-html-parser";
import {BsX} from "react-icons/bs";
import reactHtmlParser from "react-html-parser";

const Popup = ({
                     show,
                     handleClose,
                     no_img,
                     item,
                     title,
                     description,
                     data,
                     subtitle,
                     img
                 }) => {


    const [winWidth, setWinWidth] = useState(true)
    const popRef = useRef(null);

    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [])

    useEffect(() => {
        const pop = gsap.timeline({
            paused: true,
        });

        pop.to(".director-popup", {
            duration: 0.8,
            opacity: 1,
            y: 0,
            scale: 1,
            ease: Power4.easeOut,
        });

        pop.from(
            ".popup-top, .form-row1 h1, .form-row1 input, .form-row1 textarea, .submit-popup",
            {
                duration: 0.5,
                opacity: 0,
                y: 15,
                stagger: {
                    amount: 0.4,
                },
            },
            "-=.3"
        );

        popRef.current = pop;
    }, []);

    const openPop = () => {
        popRef.current.play();
    };

    const closePop = () => {
        popRef.current.reverse();
    };

    return (

        <StyledModal className={'director-popup'}>
                        <Container>
                            <Row className={'for-close'}>
                                <div  className="modal-close hover" onClick={closePop}>
                                    <p>Close</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24.042" height="24.042" viewBox="0 0 24.042 24.042">
                                        <g id="Lines" transform="translate(-332.979 -31.979)">
                                            <line id="Line_3965" data-name="Line 3965" x1="30" transform="translate(355.606 33.394) rotate(135)" fill="none" stroke="black" stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_3966" data-name="Line 3966" x1="30" transform="translate(334.393 33.393) rotate(45)" fill="none" stroke="black" stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_3967" data-name="Line 3967" x1="30" transform="translate(355.607 33.394) rotate(135)" fill="none" stroke="black" stroke-linecap="round" stroke-width="2"/>
                                        </g>
                                    </svg>
                                </div>
                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                {data &&
                                    <div className="modal-data d-flex">
                                        <Col sm={'4'} className={'legacy-left'}>
                                            <div className={'legacy-left__image-wrapper'}>
                                                <div className={'reveal'}>
                                                    <Img src={data?.image} />
                                                </div>
                                            </div>
                                            <div className={'legacy-left__image-text'}>
                                                <h3 className={'split-up'}>{data?.person_name}</h3>
                                                <p className={'sub-title split-up'}>{data?.person_designation}</p>
                                            </div>
                                        </Col>
                                        <Col sm={'8'} className={'legacy-right'}>
                                            <p className={'split-up'}>{reactHtmlParser(data?.description)}</p>
                                        </Col>
                                    </div>
                                }
                            </Row>
                        </Container>
        </StyledModal>

    )
};


const StyledModal = styled.section`
background-color: #F5F5F5;
  .modal-dialog {
    max-width: 100%!important;
  }
  h4{
    letter-spacing: 1.5px;
  }
  

`;


export default Popup;
