import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import {Link} from "react-router-dom";

const StarParticleBoardMill = () => {
    return (
        <StyledComponent>

            <Container>
                <Img src={'images/dynamic/banner/banner-2.jpg'} alt={'background-image'}/>
                <Row>
                    <Col lg={{offset: 3, span: 3}} md={6} className={'left'}>
                        <Link to={'concern/partex-boards'}>
                            <div className={'image-wrapper'}>
                                <img src={'images/dynamic/partex_boards.png'} alt={'logo'}/>
                                <img src={'images/dynamic/boards-bg.jpg'} alt={'bg'}/>
                            </div>
                        </Link>
                    </Col>
                    <Col lg={3} md={6} className={'right'}>
                        <Link to={'concern/partex-doors'}>
                            <div className={'image-wrapper'}>
                                <img src={'images/dynamic/partex_doors.png'} alt={'logo'}/>
                                <img src={'images/dynamic/doors-bg.jpg'} alt={'bg'}/>
                            </div>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  height: 100vh;
  position: relative;

  .container {
    height: 100%;

    .row {
      align-items: center;
      height: 100%;
      gap: 30px;

      .image-wrapper {
        position: relative;
        padding-top: calc(300 / 300 * 100%);
        transform: scale(1);
        transition: 0.5s ease transform;

        &:after {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          z-index: 1;
          background: rgba(255, 255, 255, 0.75);
        }

        img {
          position: absolute;
          z-index: 10;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;

          &:last-child {
            z-index: 1;
          }
        }

        &:hover {
          transform: scale(1.1);
          transition: 0.5s ease transform;
          border: unset;
          will-change: transform;
        }
      }

    }
  }

  @media (max-width: 767px) {
    .row {
      align-items: center;
      height: 100%;
      gap: 15px !important;
      justify-content: center;

      .left, .right {
        width: calc(50% - 30px);
      }
    }
  }
  @media (min-width: 767px) and (max-width: 991px) {
    .row {
      align-items: center;
      height: 100%;
      gap: 30px !important;
      justify-content: center;

      .left, .right {
        width: calc(50% - 30px);
        max-width: calc(50% - 30px);
      }
    }
  }
`;

export default StarParticleBoardMill;
