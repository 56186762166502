import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {Img} from "../Img";
import Button from "../Button";
import ReactHtmlParser from "react-html-parser";

const MyComponent = ({des, designation, img, name}) => {
    return (
        <StyledComponent className={'pb-200'}>
            <Container className="profile">

                <Row>
                    <Col lg={5} md={12} className={'profile-left'}>
                        <div className="profile__image reveal">
                            <Img src={img}/>
                        </div>
                    </Col>
                    <Col lg={7} md={12} className="profile__body">
                        <div className={'profile__body__inner'}>
                            <div className="profile__body__name">
                                {name && <h3 className={'split-left'}>{name}</h3>}
                                {designation && <p className={'split-left'}>{designation}</p>}
                            </div>
                            {<p>{des && ReactHtmlParser(des)}</p>}
                        </div>
                    </Col>
                </Row>

            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #F5F5F5;
  .profile{
    padding-top: 200px;
    &__body{
      padding-bottom: 80px;
      p{
        color: #323232;
        font-size: 20px;
        font-weight: 300;
        line-height: 24px;
      }
      &__name{
        padding-bottom: 80px;
        p{
          color: #323232;
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
        }
      }
      &__inner{
        padding-bottom: 40px;
      }
    }
    &__image{
      position: relative;
      padding-bottom: 40px;
      padding-top: calc(480 / 353 * 100%);
      height: unset!important;
      img{
        object-fit: contain!important;
      }
    }
  }
  .profile-left{
    padding-right: 70px;
  }
  .row:nth-child(even) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  @media (max-width: 767px) {
    .row{
      flex-direction: row-reverse !important;
    }
  }


  @media(max-width: 767px){
    padding-bottom: 0!important;
    .profile-left{
      padding-right: 15px;
    }
    .profile{
      padding-bottom: 80px!important;
      padding-top: 80px!important;
      &__body{
        padding-bottom: unset;
        padding-top: 40px;
        &__inner{
          padding-bottom: 0;
        }
        &__name{
          padding-bottom: 40px;
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px){
    .profile__body__inner{
      padding-top: 40px;
      .profile__body__name{
        padding-bottom: 40px;
      }
    }
    .profile__body{
      padding-bottom: 0;
    }
  }


`;

export default MyComponent;
