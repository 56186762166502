import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import Img from "../Img";
import reactHtmlParser from "react-html-parser";
import {ImgCta} from "../ImgCta";

const Overview = ({data}) => {
    return (
        <StyledComponent  className='about-partex pb-200 pt-200'>
            <ImgCta src={'/images/dynamic/concerns-x.svg'}/>
            <Container >
                <Row>
                    <Col sm={12} className={'title'}>
                      <h2>Quality, commitment, and integrity are the pillars of our <span>success</span></h2>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: #FFFFFF;
  position: relative;

  .bg-text-one, .bg-text-two {
    font-size: 240px;
    line-height: 240px;
    font-weight: 700;
    margin: 0;
    color: #fff;
    position: absolute;
    white-space: nowrap;


    @media (max-width: 767px) {
      font-size: 120px;
      line-height: 120px;
    }
  }
  .global-image{
    img{
      object-fit: none;
    }
  }

  .bg-text-one {
    right: -15%;
    top: 60px;
  }
.title{
  h2{
    letter-spacing: -1.6px;
    span{
      color: #E50019;
    }
  }
}

  @media (max-width: 767px) {
    .img-wrapper {
      padding: 0 0 40px 0;

      img {
        height: 100%;
        width: unset;
      }
    }
  }

`;

export default Overview;
