import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";
const LivingLegend = ({ img,desc,designation,name }) => {
    return (
        <StyledComponent className={'pt-200 pb-200'}>
            <Container>
                <Row>
                    <Col lg={'4'} className={'legacy-left'}>
                        <div className={'legacy-left__image-wrapper'}>
                            <div className={''}>
                                <Img src={img}/>
                            </div>
                        </div>
                        <div className={'legacy-left__image-text'}>
                            {name && <h3 className={'split-up'}>{name}</h3>}
                            {designation && <p className={'sub-title split-up'}>{designation}</p>}
                        </div>
                    </Col>
                    <Col lg={'8'} className={'legacy-right'}>
                        {desc && <p className={'split-up'}>{reactHtmlParser(desc)}</p>}
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #323232;

  
  .legacy-left{
    padding-right: 15px;
    &__image-wrapper{
      position: relative;
      padding-top: calc(389 / 368 * 100%);
    }
    &__image-text{
      padding-top: 30px;
      h3{
        color:#FFFFFF;
        font-size: 28px;
        line-height: 28px;
        font-weight: 500;
      }
      p{
        color:white;
        font-size: 20px;
        font-weight: 300;
        line-height: 24px;
      }
    }
  }
  
  .legacy-right{
    position: relative;
    padding-top: 30px;
    p{
      color: #FFFFFF;
      font-size: 20px;
      font-weight: 300;
      line-height: 24px;
    }
    &:before{
      content: "";
      position: absolute;
      height: 1px;
      background-color: white;
      top: 0;
      left: 15px;
      right: 15px;
    }
  }
  .global-image{
    img{
      margin-top: 1px;
    }
  }
  
  @media (max-width: 767px){
    &.pt-200{
      padding-top: 100px!important;
    }
    .legacy-left{
      padding-right: 15px;
      border-top: 1px solid #C9C9C9;
      padding-top: 60px;
    }
      &__image-text{
        margin-bottom: 30px;
      }
    }
  @media (max-width: 991px){
    .legacy-right{
      margin-top: 40px;
    }
  }
`;

export default LivingLegend;
