import React, {useState,useRef} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "../Img";
import SimpleBar from 'simplebar-react';

import ModalVideo from "react-modal-video";
import Modal from "react-bootstrap/Modal";
import {hoverr} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";
const ProjectList = ({data}) => {

    let [open, setOpen] = useState(false);
    let [videoId, setVideo] = useState('');
    let handelOpen = (open, id) => {
        setOpen(open);
        setVideo(id);
        setShow(true)
    };
    const modalRef = useRef(null);

    const [show, setShow] = useState(false);
    const [popupId, setPopupId] = useState()
    const [showVideo, setShowVideo] = useState(false)

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = (e) => {
        setShow(true)
        setPopupId(e)
        setVideo(true)
    }
    const handleCloseModal = () => {
        setOpen(false);
        setVideo('');
        handleClose();
    };



    return (
        <StyledProjectList className={'gallery pt-200 pb-200'}>
            <Container>
                <Row>
                    {data?.map((item, index) => (
                    <Col md={4} onClick={() => handelOpen(true, item?.url)} key={index} className='gallery__single'>
                        <div className='gallery__single__img'>
                            <Img src={item?.thumb ? item?.thumb : 'images/static/blur.jpg'}></Img>
                            <div className="gallery__single__img__click">
                                <svg width="80" height="80" viewBox="0 0 80 80">
                                    <g id="Mask_Group_392" data-name="Mask Group 392" transform="translate(-793 -3533)">
                                        <circle id="Ellipse_604" data-name="Ellipse 604" cx="40" cy="40" r="40" transform="translate(793 3533)" fill="#fff"/>
                                        <circle className="video-icon-hover" id="Ellipse_606" data-name="Ellipse 606" cx="-40" cy="40" r="40" transform="translate(793 3533)" fill="#E50019"></circle>
                                        <path id="Polygon_1" data-name="Polygon 1" d="M9.168,1.248a1,1,0,0,1,1.664,0l8.131,12.2A1,1,0,0,1,18.131,15H1.869a1,1,0,0,1-.832-1.555Z" transform="translate(841 3563) rotate(90)" fill="#e50019"/>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div className='gallery__single__content'>
                            <p>{reactHtmlParser(item?.subtitle)}</p>
                            <h3>{reactHtmlParser(item?.title)}</h3>
                        </div>
                    </Col>
                    ))}

                </Row>
            </Container>

            <Modal
                show={show}
                // item={item}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                className="gph_modal modal_video_popup popup-version-one"
                dialogClassName="custom-modal-dialog"
            >
                <SimpleBar className="main_scroll " style={{height: '100vh'}}>
                    <Modal.Body>
                        <Container>
                            <Row className={'for-close'}>
                                <div onClick={handleCloseModal} className="modal-close ">

                                    <svg id="Button_-_Close" data-name="Button - Close" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                        <g id="Ellipse_18" data-name="Ellipse 18" fill="none" stroke="#3c3c3b" stroke-width="1" opacity="0.3">
                                            <circle cx="22" cy="22" r="22" stroke="none"/>
                                            <circle cx="22" cy="22" r="21.5" fill="none"/>
                                        </g>
                                        <g id="Ellipse_19" data-name="Ellipse 19" fill="none" stroke="#3c3c3b" stroke-width="1" stroke-dasharray="0 142" opacity={'0'}>
                                            <circle cx="22" cy="22" r="22" stroke="none"/>
                                            <circle cx="22" cy="22" r="21.5" fill="none"/>
                                        </g>
                                        <g id="Group_18979" data-name="Group 18979" transform="translate(-3149 -104.5)">
                                            <line id="Line_4" data-name="Line 4" x2="8" y2="8" transform="translate(3167 122.5)" fill="none" stroke="#3c3c3b" strokeLinecap="round" stroke-width="1"/>
                                            <line id="Line_3877" data-name="Line 3877" x1="8" y2="8" transform="translate(3167 122.5)" fill="none" stroke="#3c3c3b" strokeLinecap="round" stroke-width="1"/>
                                        </g>
                                    </svg>

                                </div>

                            </Row>
                        </Container>
                        <Container>
                            <Row>


                                <div className="modal-data d-flex">
                                    <ModalVideo channel='youtube' isOpen={open}
                                                videoId={videoId}
                                                onClose={handleCloseModal}/>

                                </div>
                            </Row>
                        </Container>
                    </Modal.Body>
                </SimpleBar>
            </Modal>


        </StyledProjectList>
    );
};
const StyledProjectList = styled.section`

  .gallery {
    background: #FFFFFF;

    &__single {
      padding-bottom: 60px;

      &:nth-last-child(1) {
        padding-bottom: 0;
      }

      &:nth-last-child(2) {
        padding-bottom: 0;
      }

      &__img {
        position: relative;
        padding-top: calc(340 / 570 * 100%);
        overflow: hidden;
        cursor: pointer;

        a {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          z-index: 2;

          &:nth-child(n + 2) {
            display: none;
          }
        }

        img {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          z-index: 1;
          transform: scale(1.01);
          transition: transform 0.6s ease;
        }

        &__click {
          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            transform: translate(-50%, -50%);
            overflow: hidden;
            border-radius: 50%;

            .video-icon-hover {
              //cy: 30px;
              transition: all 0.4s ease;
            }
          }
        }

      }

      &__content {
        cursor: pointer;

        p {
          color: #C9C9C9;
          margin: 30px 0 0 0;
        }

        h3 {
          font-size: 28px;
          line-height: 32px;
          font-weight: 500;
          color: #323232;
          margin: 5px 0 0 0;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }

      &:hover {
        img {
          transform: scale(1.1);
        }

        svg {
          .video-icon-hover {
            cx: 40px;
            transition: all 0.4s ease;
          }

          path {
            fill: #ffffff;
            transition: all 0.4s ease;
          }
        }

        h3 {
          color: ${hoverr};
        }
      }
    }

    @media (max-width: 767px) {
      &__single {
        &:nth-last-child(2) {
          padding-bottom: 60px;
        }

        &__img {
          padding-top: calc(250 / 345 * 100%);
        }
      }
    }
  }

  .load_more {
    margin-top: 100px;

    .dc-btn {
      a {
        margin: auto;
      }
    }
  }

`;

export default ProjectList;