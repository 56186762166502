import React from 'react';
import styled from "styled-components";
import Img from "./Img";

import {Col, Container, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import {ImgParallax} from "./ImgParallax";

const InnerBanner = ({img, breadcrumbs, title, subtitle}) => {

    return (
        <StyledInnerBanner className='InnerBanner'>
            <ImgParallax speed={15} banner={true} src={img ? img : 'images/static/blur.jpg'}/>
            <Container >
                <Row>
                    <Col lg={{ span: 6, offset: 6 }}>
                        {breadcrumbs && <p className={'breadcrumbs split-up-innerbanner'}>{reactHtmlParser(breadcrumbs)}</p>}
                        {title && <h1 className={'split-up-innerbanner'}>{reactHtmlParser(title)}</h1>}
                        {subtitle && <p className={'sub-title split-up-innerbanner'}>{reactHtmlParser(subtitle)}</p>}
                    </Col>
                </Row>
            </Container>
        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
height: 100vh;
  position: relative;
  background-color: #FFFFFF;
  overflow: hidden;

  .container {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    z-index: 1;
   bottom: 80px;
  }

  h1 {
    font-size: 80px;
    line-height: 80px;
    letter-spacing: -1.6px;
    font-weight: 500;
    color: #ffffff;
    margin: 0 0 80px 0;

    span {
      font-weight: 600;
      color: #ffffff;
    }
  }

  .breadcrumbs {
    font-weight: 500;
    color: #ffffff;
    margin: 0 0 20px 0;
  }

  .sub-title {
    color: #ffffff;
  }

  @media (min-width: 992px) {
    height: 100vh;
  }

  @media (max-width: 991px) {
    padding-top: calc(812 / 375 * 100%);

    .container {
      bottom: 60px;
      top: unset;
      padding-top: unset;
    }

    h1 {
      font-size: 60px;
      line-height: 60px;
      letter-spacing: -1.2px;
      margin: 0 0 40px 0;
    }
  }
`;

export default InnerBanner;
