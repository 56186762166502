import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Modal, Row} from "react-bootstrap";
import {Img} from "../Img";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import SwiperCore, {Autoplay, Navigation, Pagination} from "swiper";
import {useLocation} from "react-router-dom";
import reactHtmlParser from "react-html-parser";
import SimpleBar from "simplebar-react";

SwiperCore.use([Autoplay, Pagination, Navigation]);
const SliderShowRoom = ({padding, data, title}) => {

    let containerRef = useRef();
    const ref = useRef(null);
    const location = useLocation();
    const popRef = useRef(null);

    const [selectedData, setSelectedData] = useState({});

    // <width control

    const sourceDivRef = useRef(null);
    const targetDivRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            const fullWidth = sourceDivRef.current.offsetWidth;
            const adjustedWidth = fullWidth - 30;
            document.documentElement.style.setProperty('--target-width', `${adjustedWidth}px`);
            document.documentElement.style.setProperty('--target-height', `${adjustedWidth + 80}px`);
            document.documentElement.style.setProperty('--target-height-right', `${adjustedWidth + 30}px`);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    // offset

    const [offset, setOffset] = useState(100)


    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)

        window.addEventListener('load', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
    }, [])

    const [show, setShow] = useState(false);
    const [winWidth, setWinWidth] = useState(true)
    const [popupId, setPopupId] = useState()
    const handleClose = () => setShow(false);
    const handleShow = (index) => {
        setShow(true);
        setPopupId(index);
        setSelectedData(data[index]); // Set the selected data based on the index
    };

    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [])


    return (
        <>
            <StyledBox data-scroll-container offset={offset}
                       className={`showroom slider_component ${padding ? padding : 'pt-200 pb-200'}`}>
                <Container className={'version_one'}>
                    <Row>
                        <Col md={6} ref={sourceDivRef}>
                            <div className="testimonial__head">
                                <div className="testimonial__head__text">
                                    {title && <h3 className={''}>{reactHtmlParser(title)}</h3>}
                                </div>

                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {data?.map((item, index) => (
                            <Col md={6}>
                                <div className="testimonial__single">
                                    <div className={'testimonial__single__height'}>
                                        <div sm={5} className={'testimonial__single__position'}
                                             onClick={() => handleShow(index)}>

                                            <div className="testimonial__single__image video">
                                                <Img radius={'21px'}
                                                     src={item?.image ? item?.image : 'images/static/blur.jpg'}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'gallery-text'}>
                                    {item?.person_name &&
                                        <h4>{item?.person_name}</h4>}
                                    {item?.person_designation &&
                                        <p>{item?.person_designation}</p>}
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>

            </StyledBox>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="gph_modal popup-version-one director-modal"
            >
                <SimpleBar className="main_scroll" style={{height: '100vh'}}>
                    <Modal.Body>
                        <Container className={'close-container'}>
                            <Row className={'for-close'} onClick={handleClose}>
                                <div className="modal-close">
                                    <p>Close</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24.042" height="24.042"
                                         viewBox="0 0 24.042 24.042">
                                        <g id="Lines" transform="translate(-332.979 -31.979)">
                                            <line id="Line_3965" data-name="Line 3965" x1="30"
                                                  transform="translate(355.606 33.394) rotate(135)" fill="none"
                                                  stroke="black" stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_3966" data-name="Line 3966" x1="30"
                                                  transform="translate(334.393 33.393) rotate(45)" fill="none"
                                                  stroke="black" stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_3967" data-name="Line 3967" x1="30"
                                                  transform="translate(355.607 33.394) rotate(135)" fill="none"
                                                  stroke="black" stroke-linecap="round" stroke-width="2"/>
                                        </g>
                                    </svg>
                                </div>
                            </Row>
                        </Container>
                        <Container className={'close-container'}>
                            <Row>
                                {selectedData &&
                                    <div className="modal-data d-flex">
                                        <Col sm={'4'} className={'legacy-left'}>
                                            <div className={'legacy-left__image-wrapper'}>
                                                <div className={'image-section'}>
                                                    <Img src={selectedData?.image}/>
                                                </div>
                                            </div>
                                            <div className={'legacy-left__image-text'}>
                                                <h3 className={'split-up-pop'}>{selectedData?.person_name}</h3>
                                                <p>{selectedData?.person_designation}</p>
                                            </div>
                                        </Col>
                                        <Col sm={'8'} className={'legacy-right'}>
                                            <p>{reactHtmlParser(selectedData?.description)}</p>
                                        </Col>
                                    </div>
                                }
                            </Row>
                        </Container>
                    </Modal.Body>
                </SimpleBar>
            </Modal>
        </>
    )

};


const StyledBox = styled.section`
  background-color: ${props => props.background ? props.background : '#FFFFFF'};
  position: relative;

  .testimonial__head {
    margin-bottom: 60px;
    display: flex;
    justify-content: space-between;

    &__text {
      h3 {
        span {
          color: #E50019;
        }
      }
    }
  }

  .gallery-text {
    margin-top: 30px;

    p, h4 {
      text-align: center;
    }
  }

  .testimonial__single {
    &__image {
      position: relative;
      padding-top: calc(570 / 570 * 100%) !important;

      .react_lightgallery_item {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 9;
      }

      &__icon {
        position: absolute;
        top: -20px;
        right: -25px;
      }

    }

    &__position {
      position: relative;

    }

    &__content {
      .text {
        color: #32355D;
        line-height: 36px;
        font-weight: 700;
      }

      .description {
        margin: 40px 0 40px 0;
        color: #32355D;
      }

      .name {
        color: #18AEAE;
        line-height: 24px;
        font-weight: 500;

      }

      .designation {
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
      }
    }
  }

  .global-image {
    img {
      cursor: pointer;;
    }
  }

  @media (max-width: 767px) {
    .col-md-6:first-child {
      margin-bottom: 40px;
    }
  }



`;

export default SliderShowRoom;