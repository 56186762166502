import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {get} from "../../network/axiosServices";


const initialState = {
    loading: false,
    posts: [],
    error: '',
    detail: [],
    detailLoading: false,
    detailError: ''
}

export const fetchProductlist = createAsyncThunk("fetchProductlist", (params) => {
    return get(params);
});
export const fetchSearchProduct = createAsyncThunk("searchSearchProduct", (params) => {
    return get(params);
});


const postSlice = createSlice({
    name: 'productlist',
    initialState,
    extraReducers: builder => {

        builder.addCase(fetchSearchProduct.pending, (state) => {
            state.loading = true
            state.posts = []
            state.error = ''
        })
        builder.addCase(fetchSearchProduct.fulfilled, (state, action) => {
            state.loading = false
            state.posts = action.payload
            state.error = ''
        })
        builder.addCase(fetchSearchProduct.rejected, (state, action) => {
            state.loading = false
            state.posts = []
            state.error = action.error
        })

        builder.addCase(fetchProductlist.pending, (state) => {
            state.loading = true
            state.posts = []
            state.error = ''
        })
        builder.addCase(fetchProductlist.fulfilled, (state, action) => {
            state.loading = false
            state.posts = action.payload
            state.error = ''
        })
        builder.addCase(fetchProductlist.rejected, (state, action) => {
            state.loading = false
            state.posts = []
            state.error = action.error
        })
    }
})


export default postSlice.reducer
