import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Swiper, SwiperSlide} from "swiper/react";
import {hoverr, title} from '../../styles/globalStyleVars';
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import {Col, Container, Row} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import {CSSPlugin, gsap} from 'gsap';
import {SplitText} from "gsap/SplitText";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import {ImgParallax} from "../ImgParallax";
import VideoBanner from "../VideoBanner";
import {useSelector} from "react-redux";

const Banner = ({data, offset}) => {

    const swiperRef = useRef(null);
    const swiperRefTwo = useRef(null);
    gsap.registerPlugin(ScrollTrigger);
    const videoRef = useRef(null); // Ref to control the video
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const containerRef = useRef(null);

    const [swiperInstance, setSwiperInstance] = useState(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    swiperInstance?.autoplay?.start();
                } else {
                    swiperInstance?.autoplay?.stop();
                }
            },
            { threshold: 0.1 } // Adjust this threshold as needed
        );

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, [swiperInstance]);


    const handleSlideChange = (event) => {
        const newActiveSlideIndex = event.realIndex;
        setActiveSlideIndex(newActiveSlideIndex);

        // Update autoplay delay based on the slide index
        const autoplayDelay = newActiveSlideIndex === 0 ? 10000 : 4000; // 10s for first slide, 4s for others
        event.autoplay.stop();
        event.params.autoplay.delay = autoplayDelay;
        event.autoplay.start();

        // Play or pause video based on the active slide index
        if (newActiveSlideIndex === 0) {
            videoRef.current?.play();
        } else {
            videoRef.current?.pause();
        }
    };


    // handle slider progress
    const handleProgress = (swiper,event) => {

        var interleaveOffset = 1;
        for (let i = 0; i < swiper.slides.length; i++) {
            var slideProgress = swiper.slides[i].progress;
            var innerOffset = swiper.width * interleaveOffset;
            var innerTranslate = slideProgress * innerOffset;
            swiper.slides[i].querySelector(".global-image").style.transform =
                "translate3d(" + innerTranslate + "px, 0, 0)";
        }

    };

    // hand touch move not required this slider
    const handleTouchStart = (swiper) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = "";
        }
    };


    // handle image transition on change
    const handleSetTransition = (swiper, speed) => {

        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = `${speed}ms`;
            swiper.slides[i]
                .querySelector(".global-image")
                .style.transition = `${speed}ms`;
        }
    };



    // handle pagination
    var pagination_title = ['Pushing the bounds of innovation', 'Investing in growth to open new big opportunities', 'Financial freedom at the palm of your hands', 'Investing in growth to open new big opportunities']
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<div class="' + className + '"><span>' + pagination_title[index] + "</span></div>";
        },
    };
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);


    const handleButtonClickPagination = (index) => {
        if (swiperRef.current) {
            setActiveSlideIndex(index);
            swiperRef.current.swiper.slideToLoop(index);
        }
    };



    return (
        <StyledBanner offset={offset} className={'home-banner reveal-up-inner'} ref={containerRef}>
            <Swiper
                onSwiper={setSwiperInstance}
                ref={swiperRef}
                spaceBetween={0}
                loop={true}
                autoplay={{
                    delay: 10000, // Delay between transitions (in milliseconds)
                    disableOnInteraction: false, // Whether autoplay should stop on user interactions
                }}
                speed='2000'
                onSlideChange={handleSlideChange}
                onProgress={handleProgress}
                touchStart={handleTouchStart}
                onSetTransition={handleSetTransition}
                grabCursor={false}
                // autoplayTimeout={4}
                index={0}
                loadMinimal
                loadMinimalSize={3}
                watchSlidesProgress={true}
                mousewheelControl={true}
                keyboardControl={true}
                navigation={{
                    prevEl: '#banner-prev',
                    nextEl: '#banner-next',
                }}
                pagination={pagination}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper main-swiper"
            >
                {data?.slider?.map((item, index) => (
                    <SwiperSlide>
                        <div className="slide-inner">
                            {/* Breakpoint 1: 0px to 767px */}
                            {windowWidth <= 767 && (
                                <div className="mobile">
                                    {item?.isVideo === 'on' ? (
                                        <VideoBanner ref={videoRef}  speed={30} src={item?.image_mobile ? item?.image_mobile : 'images/static/blur.jpg'} />
                                    ) : (
                                        <ImgParallax speed={15}
                                                     src={item?.image_mobile ? item?.image_mobile : 'images/static/blur.jpg'}
                                        />
                                    )}
                                    <Container>
                                        <Row>
                                            <Col className={'slider-inner__unset-padding'}>
                                                <div className="slide-inner__info">
                                                    <Col md={7} className={'slide-inner__info__unset-padding'}>
                                                        {item?.title && index === 0 ?  (
                                                            <h1>
                                                                {ReactHtmlParser(item?.title)}
                                                            </h1>
                                                        ) : (
                                                            <h2>
                                                                {ReactHtmlParser(item?.title)}
                                                            </h2>
                                                        ) }
                                                    </Col>
                                                    <Col
                                                        md={{ span: 3, offset: 2 }}
                                                        className={'slide-inner__info__unset-padding'}
                                                    >
                                                        {item?.slider_description && (
                                                            <p className={'slide-inner__info__desktop'}>
                                                                {ReactHtmlParser(item?.slider_description)}
                                                            </p>
                                                        )}
                                                    </Col>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            )}

                            {/* Breakpoint 2: 768px to 991px */}
                            {windowWidth > 767 && windowWidth <= 991 && (
                                <div className="tablet">
                                    {item?.isVideo == 'on' ? (
                                        <VideoBanner ref={videoRef} src={item?.image_tab} />
                                    ) : (
                                        <ImgParallax speed={15}
                                                     src={item?.image_tab ? item?.image_tab : 'images/static/blur.jpg'}
                                        />
                                    )}
                                    <Container>
                                        <Row>
                                            <Col className={'slider-inner__unset-padding'}>
                                                <div className="slide-inner__info">
                                                    <Col md={7} className={'slide-inner__info__unset-padding'}>
                                                        {item?.title && index === 0 ?  (
                                                            <h1>
                                                                {ReactHtmlParser(item?.title)}
                                                            </h1>
                                                        ) : (
                                                            <h2>
                                                                {ReactHtmlParser(item?.title)}
                                                            </h2>
                                                        ) }
                                                    </Col>
                                                    <Col
                                                        md={{ span: 3, offset: 2 }}
                                                        className={'slide-inner__info__unset-padding'}
                                                    >
                                                        {item?.slider_description && (
                                                            <p className={'slide-inner__info__desktop'}>
                                                                {ReactHtmlParser(item?.slider_description)}
                                                            </p>
                                                        )}
                                                    </Col>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            )}

                            {/* Breakpoint 3: 992px and up */}
                            {windowWidth > 991 && (
                                <div className="desktop">
                                    {item?.isVideo == 'on' ? (
                                        <VideoBanner ref={videoRef} src={item?.image} />
                                    ) : (
                                        <ImgParallax speed={15}
                                                     src={item?.image ? item?.image : 'images/static/blur.jpg'}
                                        />
                                    )}
                                    <Container>
                                        <Row>
                                            <Col className={'slider-inner__unset-padding'}>
                                                <div className="slide-inner__info">
                                                    <Col md={7} className={'slide-inner__info__unset-padding'}>
                                                        {item?.title && index === 0 ?  (
                                                            <h1>
                                                                {ReactHtmlParser(item?.title)}
                                                            </h1>
                                                        ) : (
                                                            <h2>
                                                                {ReactHtmlParser(item?.title)}
                                                            </h2>
                                                        ) }
                                                    </Col>
                                                    <Col
                                                        md={{ span: 3, offset: 2 }}
                                                        className={'slide-inner__info__unset-padding'}
                                                    >
                                                        {item?.slider_description && (
                                                            <p className={'slide-inner__info__desktop'}>
                                                                {ReactHtmlParser(item?.slider_description)}
                                                            </p>
                                                        )}
                                                    </Col>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            )}
                        </div>
                    </SwiperSlide>

                ))}
            </Swiper>

            <Container className={'custom-navigation'}>
                <Row>
                    <Col>
                        <div className="swipper-navigation-slider-custom">
                            <Swiper
                                ref={swiperRefTwo}
                                speed='2500'
                                slidesPerView={4}
                                spaceBetween={0}
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1,
                                        spaceBetween: 30,

                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 0,

                                    },
                                    1024: {
                                        slidesPerView: 4,
                                        spaceBetween: 0,
                                    },
                                }}
                                modules={[Autoplay, Pagination, Navigation]}
                                className="mySwiperNext"
                            >
                                {data?.slider?.map((item, index) => (
                                    <SwiperSlide>
                                        <div className={`slide-inner-item ${activeSlideIndex === index ? 'active' : ''}`}
                                             onClick={() => handleButtonClickPagination(index)}>
                                            <span>{item?.navigation_title}</span>
                                        </div>
                                    </SwiperSlide>

                                ))}
                            </Swiper>
                        </div>
                    </Col>
                </Row>
            </Container>

            {/*next prev navigation*/}
        </StyledBanner>
    );
};

const StyledBanner = styled.section`
  height: 100vh;
  position: relative;

  margin-top: -1px;


  .swiper-button-prev, .swiper-button-next, .swiper-pagination {
    display: none;
  }

  .custom-navigation {
    .swiper-slide:not(:last-child) {
      .slide-inner-item {
        padding-right: 30px;
      }
    }

    .slide-inner-item.active {
      border-top: unset !important;
    }
  }


  .custom-navigation {
    .swiper-slide:last-child {
      .slide-inner-item {
        padding-left: 25px;
        @media (max-width: 767px) {
          padding-left: 0;
        }

        &:hover {
          &:after {

            width: 100%;
            opacity: 1;
            background: #C9C9C9;
            left: 25px;

          }
        }

        &.active {
          &:after {
            width: 100%;
            opacity: 1;
            background: #E50019;
            left: 25px;
            @media (max-width: 767px) {
              left: 0 !important;
            }
          }

        }

      }
    }
  }

  .swiper-slide {
    overflow: hidden;

    .slide-inner {
      &__info {
        h2, h1 {
          letter-spacing: -2.08px;
          //opacity: 0;
          //transition: opacity 0.5s ease;
        }

        p {
          //opacity: 0;
          //transition: opacity 0.5s ease;
        }

      }
    }
  }

  .swiper-button-disabled {
    opacity: 0.5 !important;
  }

  .custom-navigation {
    position: relative;
    bottom: 100px;
  }

  .main-swiper {
    //.swiper-slide {
    //  transform: unset;
    //  -webkit-flex-shrink: 0;
    //  -ms-flex-negative: 0;
    //  flex-shrink: 0;
    //  backface-visibility: hidden;
    //}
  }

  //.swiper{
  //  height: 100vh;
  //}
  border-top: 1px solid #C9C9C9 !important;
}

//.swiper-wrapper{
//   height: 100vh
// }
.slide-inner__info p {
  color: white;
}

.slider-inner__unset-padding {
  padding: unset;
  @media (max-width: 767px) {
    padding: 0 15px;
  }
}

.global-image {
  transition: clip-path .8s cubic-bezier(.29, .73, .45, 1), border-color 3.6s linear, -webkit-clip-path .8s cubic-bezier(.29, .73, .45, 1);
  will-change: clip-path;
  overflow: hidden;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

  img {
    top: unset;
  }
}


.swiper-slide-active, .swiper-slide-visible {
  .global-image {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  .slide-inner {
    //&__info {
    //  h2 {
    //    line-height: 85px;
    //    opacity: 1;
    //    transform: translateY(0px);
    //    @media (max-width: 767px){
    //      line-height: 60px;
    //      transition-delay: 1s;
    //    }
    //  }
    //  p {
    //    opacity: 1;
    //    transform: translateY(0px);
    //    @media (max-width: 767px){
    //      transition-delay: 1s;
    //    }
    //  }
    //}
  }
}


//custom navigation
.swipper-navigation-slider-custom {
  display: flex;
  position: absolute;
  left: 0px;
  right: 0px;
  min-height: 75px;
  text-align: left;
  margin: 0px auto;
  padding-left: 15px;
  padding-right: 15px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  gap: 15px;
  flex-direction: row;

  .slide-inner-item {
    width: 100%;
    position: relative;
    background: transparent !important;
    opacity: 1 !important;
    padding: 20px 0px 0;
    cursor: pointer;

    span {
      font-size: 16px;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 20px;
      opacity: 1 !important;
      transition: 0.7s all ease;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 4px;
      background: #E50019;
      width: 0;
      opacity: 0;
      z-index: 100;
    }

    &:hover {
      &:after {

        width: 90%;
        opacity: 1;
        background: #C9C9C9;
        @media (max-width: 767px) {
          width: 100%;
        }

      }
    }

    &:before {
      content: '';
      background: transparent !important;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 99;
      border-top: 1px solid #C9C9C9;
    }

    &.active {

      &:after {
        width: 90%;
        opacity: 1;
        background: #E50019;
        @media (max-width: 767px) {
          width: 100%;
        }
      }

    }

  }
}


.custom-navigation {
  .swiper-initialized {
    margin: unset;
    width: 100%;
  }
}


.slide-inner {
  position: relative;
  height: 100vh;

  .container {
    position: absolute;
    left: 0;
    right: 0;
    top: unset;
    bottom: 80px;
    transform: translateY(-100px);
    z-index: 2;
  }

  .global-image {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 1;
      background: rgba(0, 0, 0, 0.3);
      //background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    position: relative;
    overflow: hidden;

    @media (max-width: 767px) {
      flex-direction: column;
      &__unset-padding {
        padding: unset;

        :first-child {
          padding-bottom: 40px;
        }
      }

      h2, h1 {
        margin: unset;
        font-size: 60px !important;
        line-height: 60px !important;
      }
    }

    h2, h1 {
      font-size: 80px;
      font-weight: 500;
      color: #FFFFFF;
      font-family: ${title};
      position: relative;
      transform: translateY(400px);
      //transition: 2s all cubic-bezier(0.4, 0, 0, 1);
      margin: 0;
      //transition-delay: 1.2s;
    }

    p {
      //transition: 2s all cubic-bezier(0.4, 0, 0, 1);
      //transition-delay: 1.2s;
      padding-right: 30px;
      color: white !important;
      font-size: 20px;
      font-weight: 300;
      line-height: 24px;
    }

  }
}

@media (min-width: 768px) {
  .slider-mobile {
    display: block;
  }
}

//navigation
.navigation {
  position: absolute;
  right: ${props => props.offset ? props.offset : '90'}px;
  top: calc(50% - 10vh);
  transform: translateY(-50%);
  display: inline-block;
  z-index: 1;

  ul {
    display: flex;
    flex-direction: column;

    li {
      cursor: pointer;

      &:first-child {
        margin-bottom: 20px;
        margin-right: 0px;
      }

      svg {
        path, #Rectangle_5661 {
          transition: stroke-dasharray 0.3s ease-in-out;
          transition: all 1s cubic-bezier(0.4, 0, 0, 1);
        }
      }

      &:hover {
        svg {
          #Rectangle_5661 {
            opacity: 1;
            stroke-dasharray: 140;
          }

          path {
            stroke: ${hoverr};
          }
        }
      }
    }
  }
}


//responsive
@media (min-width: 1550px) {
  .slide-inner {
    &__info {
      position: relative;
      overflow: hidden;

      h2, h1 {
        font-size: 80px;
        line-height: 100px;
      }

    }
  }

  .swipper-navigation-slider-custom {
    bottom: 300px;
  }

  @media (max-width: 992px) {
    .swipper-navigation-slider-custom {
      bottom: 40px;

    }
  }

  @media (max-width: 992px) and (min-width: 768px) {
    height: 100vh;
    .swipper-navigation-slider-custom {
      bottom: 40px;

      .swiper-initialized {
        padding-right: 30%;
      }
    }

    .slide-inner {
      height: 100vh;

      .container {
        top: 45%;
      }
    }
  }

  @media (min-width: 768px) {
    .mobile {
      display: none;
    }
  }

  @media (max-width: 767px) {

    height: 100vh;
    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }

    .slide-inner {
      height: 100vh;

      .container {
        top: unset;
        transform: unset;
        bottom: 40%;
      }

      &__info {
        position: relative;
        overflow: hidden;

        h2, h1 {
          font-size: 40px;
          line-height: 48px;
        }

      }
    }

    .swipper-navigation-slider-custom {
      padding-left: 15px !important;
      padding-right: 15px !important;

      .swiper-initialized {
        padding-right: 30%;
      }
    }


    .scrollTo {
      left: 15px;
      right: unset;
      display: inline-block;
      bottom: 20px;
      transform: translateX(0);
    }

    .navigation {
      display: none;
    }
  }


`;

export default Banner;
