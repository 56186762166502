import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import Button from "../Button";
import Title from "../Title";
import {Img} from "../Img";
import {hoverr} from "../../styles/globalStyleVars";
import {ImgPreload} from "../ImgPreload";

const Tribute = ({offset, data}) => {
    return (
        <StyledComponent className='tribute'>
            <Container fluid className={'main-col'} style={{paddingLeft:offset+15+'px'}}>
                <Row>
                    <Col md={5} className={'img-col'}>
                        <div className="img-wrapper reveal">
                            <ImgPreload src={data?.image ? data?.image : 'images/static/blur.jpg'}/>
                        </div>
                    </Col>

                    <Col md={7} className={'content'} style={{paddingRight:offset+'px'}}>
                        <div>
                            {data?.title && <h3 className={'split-up'}>{data?.title}</h3>}
                            {data?.position && <p>{data?.position}</p>}
                            {data?.description && <p>{data?.description}</p>}
                            <Button src={'/a-visionarys-legacy'} text={'Read More'} color={'#323232'} background={'#E8E8E8'}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  background: #FFFFFF;
  
  &:before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    height: 50%;
    z-index: 0;
    background-color: #E4E4E4;
  }
  
  .row {
    background: #323232;
    position: relative;
    z-index: 1;
  }
  
  .img-col {
    padding-left: 0;
  }
  
  .img-wrapper {
    position: relative;
    height: 100%;
    padding-top: calc(603 / 570 * 100%);
    
    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }
  
  .content {
    //padding-top: 120px;
    //padding-bottom: 120px;
      display: flex;
      align-items: center;
    
    h3 {
      color: #FFFFFF;
      margin: 0 0 10px 0;
      
      span {
        color: ${hoverr};
      }
    }

    .sub-title {
      color: #FFFFFF;
      font-weight: 500;
      margin: 0 0 80px 0;
    }

    p {
      color: #FFFFFF;
      margin: 0 0 40px 0;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .content{
      padding-top: 40px;
      padding-bottom: 40px;
    }
    .main-col {
      padding-left: 0!important;
    }
    .revealTribute{
      transform: none!important;
    }
  }
  
  @media (max-width: 767px) {
    &:before {
      content: unset;
    }
    
    .container-fluid {
      padding: 0 15px;
    }

    .img-col {
      padding: 0px !important;
    }

    .content {
      padding-top: 80px;
      padding-bottom: 80px;
      padding-right: 15px !important;

      .sub-title {
        margin: 0 0 40px 0;
      }
    }
  }
  
`;

export default Tribute;
