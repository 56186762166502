import React, {useEffect, useState} from 'react';
import styled from "styled-components";

import {HelmetProvider, Helmet} from 'react-helmet-async';
import DealerList from "../../components/DealerList/DealerList";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchDealerList} from "../../api/redux/DealerList";
import JobOpening from "../../components/Career/JobOpening";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {ApiParam as ApiParams} from "../../api/network/apiParams";
import DealerListNew from "../../components/DealerList/DealerListNew";

const MyComponent = ({offset}) => {

    const dispatch = useDispatch()
    let {slug} = useParams();
    const location = useLocation();
    const history = useHistory();


    const [selectedType, setSelectedType] = useState('');


    // api call
    useEffect(() => {
        let api_url = apiEndPoints.DEALERLIST
        const param = {
            [ApiParams.dealer_location]: selectedType,
        };

        // Update the URL with the selected values
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("dealer_location", selectedType);
        history.push({ search: searchParams.toString() });
        dispatch(fetchDealerList([api_url, param]))
    }, [dispatch, history, location.search, selectedType])



    let getPost = useSelector(state => state.dealerlist); //store
    const page_title = getPost?.posts?.post_title;
    // const founderImage = getPost?.posts?.page_data?.[0]?.founder;
    const dealerlist = getPost?.posts?.list;
    const filter = getPost?.posts?.filter;

    let data = getPost?.posts?.page_data?.[0];
    let innerBanner = data?.banner?.image;


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${data?.meta?.meta_title ? data?.meta?.meta_title : 'Partex Star Group'}`}</title>
                {
                    data?.meta?.meta_desc &&
                    <meta name="description" content={data?.meta?.meta_desc}/>

                }
                {
                    data?.meta?.og_title &&
                    <meta property="og:title" content={data?.meta?.og_title}/>

                }
                {
                    data?.meta?.og_desc &&
                    <meta property="og:description" content={data?.meta?.og_desc}/>
                }
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={innerBanner?.image}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>
               <DealerList  filter={filter}
                                                        list={dealerlist}
                                                        selectedType={selectedType}
                                                        setSelectedType={setSelectedType}
                />}
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
  
`;

export default MyComponent;
