import React, {useState, useEffect, useRef} from 'react';
import styled, {css} from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {Link, useLocation, useParams} from 'react-router-dom';
import {gsap} from 'gsap';
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import {Img} from "./Img";


const MyComponent = ({color}) => {


    const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

    const [hoveredColumnIndex, setHoveredColumnIndex] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);

    const location = useLocation();

    const handleRowHover = (rowIndex) => {
        setHoveredRowIndex(rowIndex);
    };

    const handleColumnHover = (columnIndex) => {
        setHoveredColumnIndex(columnIndex);
    };

    const [activeMenu, setActiveMenu] = useState('');

    useEffect(() => {
        if (location.pathname === '/') {
            setActiveMenu('home');
        }
    }, [location.pathname]);
    // Animation for menu

    const menuRef = useRef(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuTimeline, setMenuTimeline] = useState(null);


    useEffect(() => {
        if (menuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }
    }, [menuOpen]);
    // const handleMenuClick = () => {
    //     if (!menuOpen) {
    //         setMenuOpen(true);
    //     }
    // };


    const handleCloseClick2 = (menu, index) => {
        setMenuOpen(false);
        setActiveMenu(menu);
        gsap.to(menuRef.current, {
            opacity: 0,
            height: 0,
            duration: 1.5,
            ease: 'power2.out',
            onComplete: () => {
                gsap.set(menuRef.current, { height: 'auto', opacity: '1' });
                ScrollTrigger.refresh();
            },
        });

        const target = document.querySelectorAll('.Mobile-menu-wrap');
        // Add logic to set the active index here
        setActiveIndex(index);
    };


    const handleMenuClick = () => {
        if (!menuOpen) {
            const timeline = gsap.timeline({paused: false});
            timeline.from(menuRef.current, {
                opacity: 'auto',
                height: '-10%',
                duration: 0.5,
                ease: 'power2.out',
                onComplete: () => {
                    gsap.set(menuRef.current, {height: '100%'});
                },
            });
            setMenuTimeline(timeline);
            setMenuOpen(true);
            timeline.play();
        }
    };

    const handleCloseClick = () => {
        if (menuTimeline) {
            menuTimeline.reverse().then(() => {
                setMenuOpen(false);
                gsap.set(menuRef.current, {clearProps: 'height'});
                gsap.set(menuRef.current, {height: '-5%'});
            });
            setMenuTimeline(null);
        }
    };


    // sticky menu fixed
    const targetElementRef = useRef(null);

    useEffect(() => {

        targetElementRef.current = document.querySelector('.show');

        return () => {

            clearAllBodyScrollLocks();
        };
    }, []);


    const [scrollUp, setScrollUp] = useState(false);
    const [scrollDown, setScrollDown] = useState(false);


    // menu fixed on scroll
    useEffect(() => {
        if (document.body.classList.contains('scroll-down')) {
            document.body.classList.remove('scroll-down');
        }
    });

    useEffect(() => {
        const body = document.body;
        const scrollUp = 'scroll-up';
        const scrollDown = 'scroll-down';
        let lastScroll = 0;
        let howMuchScroll = 150;
        if (window.screen.width < 991) {
            howMuchScroll = 0;
        } else {
            howMuchScroll = 150;
        }

        window.addEventListener('scroll', () => {
            let currentScroll = window.pageYOffset;
            if (currentScroll <= howMuchScroll) {
                body.classList.remove(scrollUp);
                return;
            }
            if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
                // down
                body.classList.remove(scrollUp);
                body.classList.add(scrollDown);
            } else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) {
                // up
                body.classList.remove(scrollDown);
                body.classList.add(scrollUp);
            }
            lastScroll = currentScroll;
        });
    }, []);

    const [isFirstColHovered, setIsFirstColHovered] = useState(false);
    const [isSecondColHovered, setIsSecondColHovered] = useState(false);
    const [isThirdColHovered, setIsThirdColHovered] = useState(false);
    const [isFourthColHovered, setIsFourthColHovered] = useState(false);


    return (
        <>
            <StyledPopup className={` ${menuOpen ? 'show' : ''}`} ref={menuRef}>
                <div className={'hover-image'}>
                    <ul>
                        <li>
                            <img className={`image first-image ${isFirstColHovered ? 'active' : ''}`}
                                 src="/images/static/menu/first_menu.jpg" alt="First Image"/>
                            <img className={`image second-image ${isSecondColHovered ? 'active' : ''}`}
                                 src="/images/static/menu/second_menu.jpg" alt="Second Image"/>
                            <img className={`image third-image ${isThirdColHovered ? 'active' : ''}`}
                                 src="/images/static/menu/first_menu.jpg" alt="Second Image"/>
                            <img className={`image fourth-image ${isFourthColHovered ? 'active' : ''}`}
                                 src="/images/static/menu/second_menu.jpg" alt="Second Image"/>
                        </li>
                    </ul>
                </div>

                <Container>
                    <Row>
                        <Col>
                            <div className={'menu-top'}>
                                <div className="logo">
                                    <Link to={'/'} onClick={handleCloseClick2}>
                                        <img src={'/images/static/menu/partex_logo_white.svg'} alt="Logo"/>
                                    </Link>
                                </div>
                                <div className={'menu-top-handle'}>
                                    <div className={'menu-top__right'} onClick={handleCloseClick}>
                                        <p>Close</p>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24.042" height="24.042"
                                             viewBox="0 0 24.042 24.042">
                                            <g id="Lines" transform="translate(-332.979 -31.979)">
                                                <line id="Line_3965" data-name="Line 3965" x1="30"
                                                      transform="translate(355.606 33.394) rotate(135)" fill="none"
                                                      stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                                <line id="Line_3966" data-name="Line 3966" x1="30"
                                                      transform="translate(334.393 33.393) rotate(45)" fill="none"
                                                      stroke="#fff"
                                                      stroke-linecap="round" stroke-width="2"/>
                                                <line id="Line_3967" data-name="Line 3967" x1="30"
                                                      transform="translate(355.607 33.394) rotate(135)" fill="none"
                                                      stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className={'menu-body'} className={hoveredRowIndex === 0 ? 'row-hovered' : ''}
                         onMouseEnter={() => handleRowHover(0)}
                         onMouseLeave={() => handleRowHover(null)}>
                        <Col sm={'2'} className={`first ${isFirstColHovered ? 'hovered' : ''}`}
                             onMouseEnter={() => setIsFirstColHovered(true)}
                             onMouseLeave={() => setIsFirstColHovered(false)}
                        >
                            <div className={`menu__title-first ${hoveredColumnIndex === 0 ? 'column-hovered' : ''}`}
                                 onMouseEnter={() => handleColumnHover(0)}
                                 onMouseLeave={() => handleColumnHover(null)}>
                                <h4>Who We are</h4>
                                <ul>


                                    <li onClick={handleCloseClick2}><Link to={'/about'}>Partex Star Group</Link></li>
                                    <li onClick={handleCloseClick2}><Link to={'/a-visionarys-legacy'}>A Visionary's
                                        Legacy</Link>
                                    </li>
                                    <li onClick={handleCloseClick2}><Link to={'/about#our-heritage'}>Our Heritage</Link></li>
                                    <li onClick={handleCloseClick2}><Link to={'/about#mission-vision'}>Our Mission and Vision</Link>
                                    </li>
                                    <li onClick={handleCloseClick2}><Link to={'/about#core-value'}>Our Core Values</Link></li>
                                    <li onClick={handleCloseClick2}><Link to={'/board-of-directors'}>Board of
                                        Directors</Link></li>
                                    <li onClick={handleCloseClick2}><Link to={'/key-executives'}>Key Executives</Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>

                        <Col sm={'6'} className={`second ${isSecondColHovered ? 'hovered' : ''}`}
                             onMouseEnter={() => setIsSecondColHovered(true)}
                             onMouseLeave={() => setIsSecondColHovered(false)}

                        >
                            {/*Our Concerns*/}
                            <div className={`menu__title-second ${hoveredColumnIndex === 1 ? 'column-hovered' : ''}`}
                                 onMouseEnter={() => handleColumnHover(1)}
                                 onMouseLeave={() => handleColumnHover(null)}>
                                <h4 className={'second-menu'}>Our Concerns</h4>
                                <ul className={'grid-list'}>

                                    <li onClick={handleCloseClick2}><Link to={'/star-particle-board-mill'}>Star Particle
                                        Board Mills Limited</Link></li>
                                    <li onClick={handleCloseClick2}><Link to={'/concern/partex-builders-ltd'}>Partex
                                        Builders Limited</Link></li>
                                    <li onClick={handleCloseClick2}><Link to={'/concern/partex-cables'}>Partex
                                        Cables Limited</Link></li>
                                    <li onClick={handleCloseClick2}><Link to={'/concern/ashley-furniture'}>Partex Home Stores Limited (Ashley Furniture)</Link></li>
                                    <li onClick={handleCloseClick2}><Link
                                        to={'/concern/partex-furniture-industries-ltd'}>Partex Furniture Industries
                                        Limited</Link></li>

                                    <li onClick={handleCloseClick2}><Link to={'/concern/partex-agro-ltd'}>Partex Agro
                                        Limited</Link></li>
                                    <li onClick={handleCloseClick2}><Link
                                        to={'/concern/partex-gypsum-boards-mills-ltd'}>Partex Gypsum Boards Mills
                                        Limited</Link></li>

                                    <li onClick={handleCloseClick2}><Link to={'/concern/cafe-de-partex'}>Café de
                                        Partex</Link></li>
                                    <li onClick={handleCloseClick2}><Link to={'/concern/star-adhesives-ltd'}>Star
                                        Adhesives Limited</Link></li>
                                    <li onClick={handleCloseClick2}><Link to={'/concern/partex-aeromarine'}>Partex
                                        Aeromarine Logistics Limited (Bangladesh)</Link></li>
                                    <li onClick={handleCloseClick2}><Link to={'/concern/partex-laminates-limited'}>Partex Laminates Limited</Link></li>
                                    <li onClick={handleCloseClick2}><Link to={'/concern/partex-aeromarine-india'}>Partex
                                        Aeromarine Logistics Limited (India)</Link></li>
                                    <li onClick={handleCloseClick2}><Link to={'/concern/triple-apparels-ltd'}>Triple
                                        Apparels Limited</Link></li>
                                    <li onClick={handleCloseClick2}><Link to={'/sister-concerns'}>Sister Concerns</Link></li>




                                </ul>
                            </div>
                        </Col>
                        <Col sm={'2'} className={`third ${isThirdColHovered ? 'hovered' : ''}`}
                             onMouseEnter={() => setIsThirdColHovered(true)}
                             onMouseLeave={() => setIsThirdColHovered(false)}

                        >
                            {/*Media Center*/}
                            <div className={`menu__title-third ${hoveredColumnIndex === 2 ? 'column-hovered' : ''}`}
                                 onMouseEnter={() => handleColumnHover(2)}
                                 onMouseLeave={() => handleColumnHover(null)}>
                                <h4 className={'third-menu'}>Media Center</h4>

                                <ul>
                                    <li onClick={handleCloseClick2}><Link to={`/media-center?category=event`}>Events</Link>
                                    </li>
                                    <li onClick={handleCloseClick2}><Link to={`/media-center?category=press-release`}>Press Release</Link></li>
                                    <li onClick={handleCloseClick2}><Link to={'/tvc'}>TVC</Link></li>
                                </ul>
                            </div>
                        </Col>
                        <Col sm={'2'} className={`fourth ${isFourthColHovered ? 'hovered' : ''}`}
                             onMouseEnter={() => setIsFourthColHovered(true)}
                             onMouseLeave={() => setIsFourthColHovered(false)}

                        >

                            {/*Contact*/}
                            <div className={`menu__title-fourth ${hoveredColumnIndex === 3 ? 'column-hovered' : ''}`}
                                 onMouseEnter={() => handleColumnHover(3)}
                                 onMouseLeave={() => handleColumnHover(null)}>
                                <h4 className={'fourth-menu'}>Contact</h4>
                                <ul>
                                    <li onClick={handleCloseClick2}><Link to={'/contact'}>Office</Link></li>

                                </ul>
                                <ul className={`${hoveredColumnIndex === 3 ? 'column-hovered' : ''} menu-extra`}
                                    onMouseEnter={() => handleColumnHover(3)}
                                    onMouseLeave={() => handleColumnHover(null)}>
                                    <li onClick={handleCloseClick2}><Link to={'/csr'}>CSR</Link></li>
                                    <li onClick={handleCloseClick2}><Link to={'/career'}>Career</Link></li>

                                </ul>
                            </div>
                        </Col>
                    </Row>
                    <Row className={'social'}>
                        <Col sm={'12'}>
                            <div className={'social__icon'}>
                                <div className={'social__icon__icons'}>
                                    <a href={'https://www.facebook.com/partexstargroup/'} target={"_blank"}>
                                        <div className={'social__icon__icons__facebook'}>
                                            <svg id="Animation_-_Social_Icons" data-name="Animation - Social Icons"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 xmlnsXlink="http://www.w3.org/1999/xlink"
                                                 width="30" height="30" viewBox="0 0 30 30">
                                                <defs>
                                                    <clipPath id="clip-path">
                                                        <circle id="Ellipse_448" data-name="Ellipse 448" cx="15" cy="15"
                                                                r="15"
                                                                transform="translate(-15955 7152)" fill="#e8e8e8"
                                                                stroke="rgba(0,0,0,0)" stroke-miterlimit="10"
                                                                stroke-width="1"/>
                                                    </clipPath>
                                                </defs>
                                                <g id="Mask_Group_13" data-name="Mask Group 13"
                                                   transform="translate(15955 -7152)" clip-path="url(#clip-path)">
                                                    <circle id="Ellipse_447" data-name="Ellipse 447" cx="15" cy="15"
                                                            r="15"
                                                            transform="translate(-15955 7152)" fill="#e8e8e8"
                                                            stroke="rgba(0,0,0,0)" stroke-miterlimit="10"
                                                            stroke-width="1"/>
                                                    <circle id="Ellipse_449" data-name="Ellipse 449" cx="15" cy="15"
                                                            r="15"
                                                            transform="translate(-15990 7152)" fill="#e50019"
                                                            stroke="rgba(0,0,0,0)" stroke-miterlimit="10"
                                                            stroke-width="1"/>
                                                    <path id="Path_2115" data-name="Path 2115"
                                                          d="M1206.12,104.34l.406-2.652h-2.544v-1.72a1.325,1.325,0,0,1,1.495-1.432h1.157V96.278a14.1,14.1,0,0,0-2.053-.179,3.237,3.237,0,0,0-3.465,3.569v2.021h-2.329v2.652h2.329v6.409h2.866V104.34Z"
                                                          transform="translate(-17142.787 7063.901)" fill="#323232"/>
                                                </g>
                                            </svg>
                                        </div>
                                    </a>
                                </div>

                                <div className={'social__icon__icons'}>
                                    <a href={'https://x.com/partex_star'} target={"_blank"}>
                                        <div className={'social__icon__icons__twitter'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31"
                                                 viewBox="0 0 31 31">
                                                <g id="Group_4899" data-name="Group 4899"
                                                   transform="translate(15955.5 -7201.5)">
                                                    <circle id="Ellipse_93" data-name="Ellipse 93" cx="15" cy="15"
                                                            r="15"
                                                            transform="translate(-15955 7202)" fill="#e8e8e8"
                                                            stroke="rgba(0,0,0,0)" stroke-miterlimit="10"
                                                            stroke-width="1"/>
                                                    <circle id="Ellipse_94" data-name="Ellipse 93" cx="-17" cy="15"
                                                            r="15"
                                                            transform="translate(-15955 7202)" fill="#e8e8e8"
                                                            stroke="rgba(0,0,0,0)" stroke-miterlimit="10"
                                                            stroke-width="1"/>
                                                    <path id="Path_2113" data-name="Path 2113"
                                                          d="M1237.574,104.23a5.331,5.331,0,0,1-1.306.372c.21-.035.518-.415.642-.569a2.4,2.4,0,0,0,.433-.79c.011-.023.02-.051,0-.068a.075.075,0,0,0-.069.006,6.668,6.668,0,0,1-1.548.592.1.1,0,0,1-.107-.029,1.258,1.258,0,0,0-.135-.138,2.736,2.736,0,0,0-.75-.459,2.609,2.609,0,0,0-1.15-.185,2.745,2.745,0,0,0-1.091.308,2.817,2.817,0,0,0-.88.719,2.7,2.7,0,0,0-.525,1.053,2.844,2.844,0,0,0-.028,1.111c.008.062,0,.071-.053.062a8.268,8.268,0,0,1-5.319-2.707c-.062-.071-.1-.071-.147.005a2.7,2.7,0,0,0,.46,3.186c.1.1.212.2.327.288a2.711,2.711,0,0,1-1.026-.288c-.062-.039-.094-.017-.1.054a1.62,1.62,0,0,0,.017.3,2.727,2.727,0,0,0,1.681,2.174,1.583,1.583,0,0,0,.341.1,3.04,3.04,0,0,1-1.007.031c-.073-.014-.1.023-.073.093a2.831,2.831,0,0,0,2.115,1.771c.1.017.192.017.288.039-.006.009-.012.009-.017.017a3.347,3.347,0,0,1-1.444.765,5.171,5.171,0,0,1-2.194.281c-.118-.017-.143-.016-.174,0s0,.048.034.079c.15.1.3.186.457.271a7.231,7.231,0,0,0,1.466.586,7.811,7.811,0,0,0,7.582-1.773,7.956,7.956,0,0,0,2.1-5.8c0-.083.1-.129.157-.174a5.128,5.128,0,0,0,1.032-1.073.327.327,0,0,0,.068-.205v-.011C1237.627,104.2,1237.626,104.207,1237.574,104.23Z"
                                                          transform="translate(-17170.717 7109.043)" fill="#323232"/>
                                                </g>
                                            </svg>
                                        </div>
                                    </a>
                                </div>

                                <div className={'social__icon__icons'}>
                                    <a href={'https://www.instagram.com/partexstargroup'} target={"_blank"}>
                                        <div className={'social__icon__icons__insta'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31"
                                                 viewBox="0 0 31 31">
                                                <g id="Group_4898" data-name="Group 4898"
                                                   transform="translate(15955.5 -7251.5)">
                                                    <circle id="Ellipse_98" data-name="Ellipse 98" cx="15" cy="15"
                                                            r="15"
                                                            transform="translate(-15955 7252)" fill="#e8e8e8"
                                                            stroke="rgba(0,0,0,0)" stroke-miterlimit="10"
                                                            stroke-width="1"/>
                                                    <circle id="Ellipse_97" data-name="Ellipse 98" cx="-17" cy="15"
                                                            r="15"
                                                            transform="translate(-15955 7252)" fill="#e8e8e8"
                                                            stroke="rgba(0,0,0,0)" stroke-miterlimit="10"
                                                            stroke-width="1"/>
                                                    <path id="Path_2109" data-name="Path 2109"
                                                          d="M1095.77,105.945a.854.854,0,1,0,.853.854A.854.854,0,0,0,1095.77,105.945Z"
                                                          transform="translate(-17031.635 7156.654)" fill="#323232"/>
                                                    <path id="Path_2110" data-name="Path 2110"
                                                          d="M1082.64,108.605a3.586,3.586,0,1,0,3.586,3.586A3.59,3.59,0,0,0,1082.64,108.605Zm0,5.882a2.3,2.3,0,1,1,2.3-2.3A2.3,2.3,0,0,1,1082.64,114.488Z"
                                                          transform="translate(-17022.301 7155.089)" fill="#323232"/>
                                                    <path id="Path_2111" data-name="Path 2111"
                                                          d="M1080.119,114.188h-5.813a4.379,4.379,0,0,1-4.374-4.374V104a4.378,4.378,0,0,1,4.374-4.373h5.813a4.378,4.378,0,0,1,4.374,4.373v5.813A4.379,4.379,0,0,1,1080.119,114.188ZM1074.306,101a3.007,3.007,0,0,0-3,3v5.813a3.007,3.007,0,0,0,3,3h5.813a3.007,3.007,0,0,0,3-3V104a3.007,3.007,0,0,0-3-3Z"
                                                          transform="translate(-17016.932 7160.372)" fill="#323232"/>
                                                </g>
                                            </svg>
                                        </div>
                                    </a>
                                </div>

                                <div className={'social__icon__icons'}>
                                    <a href={'https://www.youtube.com/@partexstargroup7699'} target={"_blank"}>
                                        <div className={'social__icon__icons__youtube'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31"
                                                 viewBox="0 0 31 31">
                                                <g id="Group_4897" data-name="Group 4897"
                                                   transform="translate(15955.5 -7301.5)">
                                                    <circle id="Ellipse_99" data-name="Ellipse 99" cx="15" cy="15"
                                                            r="15"
                                                            transform="translate(-15955 7302)" fill="#e8e8e8"
                                                            stroke="rgba(0,0,0,0)" stroke-miterlimit="10"
                                                            stroke-width="1"/>
                                                    <circle id="Ellipse_100" data-name="Ellipse 99" cx="-17" cy="15"
                                                            r="15"
                                                            transform="translate(-15955 7302)" fill="#e8e8e8"
                                                            stroke="rgba(0,0,0,0)" stroke-miterlimit="10"
                                                            stroke-width="1"/>
                                                    <path id="Path_2114" data-name="Path 2114"
                                                          d="M1146.9,113.13a2.813,2.813,0,0,0-2.813-2.813h-7.195a2.813,2.813,0,0,0-2.813,2.813v3.348a2.813,2.813,0,0,0,2.813,2.813h7.195a2.813,2.813,0,0,0,2.813-2.813Zm-4.231,1.925-3.226,1.6c-.126.068-.556-.023-.556-.167v-3.276c0-.146.433-.237.56-.165l3.089,1.68C1142.661,114.8,1142.8,114.985,1142.666,115.056Z"
                                                          transform="translate(-17080.074 7202.683)" fill="#323232"/>
                                                </g>
                                            </svg>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </StyledPopup>

            <StyledDefaultMenu
                className={`${scrollDown ? 'scroll-down' : ''} ${scrollUp ? 'scroll-up' : ''} reveal-down main-menu-desktop`}>
                <Container className={'desktop-default'}>
                    <Row>
                        <Col>
                            <div className={'header'}>
                                <div className="logo white-logo">
                                    <Link to={'/'}>
                                        {(location.pathname === '') ? (
                                           <div>
                                               <img src={'/images/static/logo_black.svg'} alt="Logo Black"/>
                                           </div>
                                        ) : (
                                            <div>
                                                <img src={'/images/static/logo_white.svg'} alt="Partex Logo White"/>
                                            </div>
                                        )}

                                    </Link>
                                </div>
                                <div className="logo black-logo">
                                    <Link to={'/'}>
                                            <div className={'black-logo'}>
                                                <img src={'/images/static/logo_black.svg'} alt="Logo Black"/>
                                            </div>
                                    </Link>
                                </div>
                                <div>
                                    <ul className={'header__menu'}>
                                        <li
                                            className={activeMenu === 'home' ? 'active' : ''} onClick={() => {
                                            handleCloseClick2('home');
                                        }}>
                                            <Link to={'/'} style={{color: color}}>Home</Link>
                                        </li>

                                        <li className={activeMenu === 'about' ? 'active' : ''} onClick={() => {
                                            handleCloseClick2('about');
                                        }}>
                                            <Link to={'/about'} style={{color: color}}>Partex Star Group</Link>
                                        </li>
                                        <li className={activeMenu === 'board-of-directors' ? 'active' : ''} onClick={() => {
                                            handleCloseClick2('board-of-directors');
                                        }}>
                                            <Link to={'/board-of-directors'} style={{color: color}}>Board of Directors</Link>
                                        </li>
                                        <li className={activeMenu === 'csr' ? 'active' : ''} onClick={() => {
                                            handleCloseClick2('csr');
                                        }}>
                                            <Link to={'/csr'} style={{color: color}}>CSR</Link>
                                        </li>
                                        <li className={activeMenu === 'contact' ? 'active' : ''} onClick={() => {
                                            handleCloseClick2('contact');
                                        }}>
                                            <Link to={'/contact'} style={{color: color}}>Contact</Link>
                                        </li>

                                    </ul>
                                </div>

                                <div className={'header__right__logo'}>
                                    <button onClick={handleMenuClick}>
                                        <p style={{color: color}}>Menu</p>
                                        <div className={'hamburger-icon'}>
                                            <span style={{background: color}} className={'first-span'}></span>
                                            <span style={{background: color}} className={'second-span'}></span>
                                            <span style={{background: color}} className={'third-span'}></span>
                                        </div>
                                    </button>

                                </div>
                            </div>


                        </Col>
                    </Row>
                </Container>
            </StyledDefaultMenu>
        </>

    );
}


const StyledDefaultMenu = styled.section`


  @media (max-width: 991px) {
    display: none;
  }
  height: fit-content;
  padding: 20px 0px;
  z-index: 50;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  overflow: hidden;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0));
  transition: background .5s ease;
}

//hamburger-icon
.hamburger-icon {
  display: inline-block;
  cursor: pointer;
  width: 30px;
  height: 16px;
  position: relative;
  margin: 0px 0px 0px 23px;
}

.hamburger-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 110%;
  background: #FFFFFF;
  transition: transform 0.3s ease;


  &:nth-child(1) {
    top: 0;
    width: 75%;
    right: 0;
    transition: width .3s ease;
  }

  &:nth-child(2) {
    right: 0;
    top: 50%;
    margin-top: -1px;
    transition: width .3s ease;
  }

  &:nth-child(3) {
    bottom: 0;
    width: 75%;
    right: 0;
    transition: width .3s ease;
  }

}

/* Animation for the active/hamburger open state */
.header__right__logo:hover span:nth-child(1) {
  width: 110%;
  transition: width .3s ease;
}

.header__right__logo:hover span:nth-child(2) {
  width: 75%;
  transition: width .3s ease;
}

.header__right__logo:hover span:nth-child(3) {
  width: 110%;
  transition: width .3s ease;
}


.header {
  display: flex;
  align-items: center;

  .black-logo {
    display: none;
  }
  
  .white-logo{
    display: block;
  }

  &__menu {
    display: flex;
    grid-template-columns: repeat(4, 1fr);

    li:first-child {
      padding-left: 58px;
    }

    li:not(:last-child) {
      margin-right: 30px;
    }

    li {
      display: flex;
      justify-content: center;

      a {
        color: white;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;

        &:hover {
          color: white !important;
          opacity: 70%;
        }

        :active {
          color: green;
        }
      }
    }
  }

  &__right {
    margin-left: auto;

    li {
      a {
        color: white;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }
    }

    &__logo {
      cursor: pointer;
      display: flex;
      margin-left: auto;

      button {
        display: flex;
        background-color: unset;
        border: unset;
      }

      p {
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
  

  .active {
    a {
      opacity: 70%;;
    }
  }

  .header__right__logo {
    svg {
      margin-left: 20px;

      &:hover {
        #Line_3965 {
          x1: 50;
          transform: translate(0);
        }

        #Line_3966 {
          x1: 20;
          transform: translate(0 8);
        }

        #Line_3967 {
          x1: 50;
          transform: translate(16);
        }

        g {
          line {
            stroke: #f9f9f9;
          }
        }
      }
    }
  }

`;


const StyledPopup = styled.section`

  //pop-up menu
  z-index: 100;


  .menu-top-handle {
    display: none;
  }

  visibility: hidden;


  &.show {
    visibility: visible;

    .menu-top-handle {
      margin-right: 5px;
      padding-top: 18px;
      display: block;
    }
  }

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 12px;
  background-color: #323232;
  overflow: hidden;

  .first-image {
    position: absolute;
    background-size: cover;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: unset;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

  .second-image {
    position: absolute;
    background-size: cover;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: unset;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

  .third-image {
    position: absolute;
    background-size: cover;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: unset;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

  .fourth-image {
    position: absolute;
    background-size: cover;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: unset;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

  .first-image.active {
    opacity: 1;
  }

  .second-image.active {
    opacity: 1;
  }

  .third-image.active {
    opacity: 1;
  }

  .fourth-image.active {
    opacity: 1;
  }

  .first:hover ~ .hover-image .first-image {
    display: block;
  }

  .second:hover ~ .hover-image .second-image {
    display: block;
  }

  .third:hover ~ .hover-image .third-image {
    display: block;
  }

  .fourth:hover ~ .hover-image .third-image {
    display: block;
  }

  .menu__title-fourth__extra {
    position: absolute;
    bottom: 20px;
    margin: auto;
  }

  .menu-extra {
    padding-top: 45px;
  }


  .menu-image {
    position: relative;

    &__first img {
      position: absolute;
      background-size: cover;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      object-fit: unset;
    }
  }

  .container {
    height: 100svh;
    position: relative;
    padding-top: 8px;
  }

  .grid-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /*Creates two equal columns*/
    grid-column-gap: 30px;
  }

  .menu-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 110px;

    &__right {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
      }

      svg:hover {
        cursor: pointer;
        transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
        transform: rotate(90deg);
      }

      p {
        padding-right: 28px;
        font-size: 16px;
        color: white;
      }
    }
  }

  .menu {
    &__title-first, &__title-second, &__title-third, &__title-fourth {
      margin-bottom: 20px;

      h4 {
        color: #707070;
        font-size: 20px;
        margin-bottom: 20px;
        transition: color .5s ease;
      }

      a {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 20px;
        transition: 0.3s ease color,0.3s ease opacity;

        &:hover {
          color: white !important;
          opacity: 70%;
          transition: 0.3s ease color,0.3s ease opacity;
        }
      }

      ul {
        li {
          a {
            color: white;
            display: flex;
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
          }
        }
      }

    }
  }

  .hovered {
    h4 {
      color: white;
      transition: color .5s ease;
    }
  }

  .row-hovered {
    ul {
      li {
        a {
          color: grey;
          display: flex;
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }

    .column-hovered {
      ul {
        li {
          a {
            color: white;
            display: flex;
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
          }
        }
      }
    }
  }

  .menu-body {

    .default-color {
      li {
        a {
          color: white;
          display: flex;
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }
  }

  .social {
    position: absolute;
    bottom: 40px;

    &__icon {
      display: flex;

      &__icons {
        padding-right: 20px;

        &__facebook {
          svg {
            border-radius: 50px;

            #Ellipse_449 {
              fill: #e8e8e8;
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              r: 15;
              cursor: pointer;
            }
          }

          &:hover {
            #Ellipse_449 {
              fill: #E50019;
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              cx: 50;
            }

            #Path_2115 {
              fill: white;
            }
          }

        }

        &__twitter {
          svg {
            border-radius: 50px;

            #Ellipse_94 {
              fill: #e8e8e8;
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              r: 15;
              cursor: pointer;
            }
          }

          &:hover {
            #Ellipse_94 {
              fill: #e50019;
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              cx: 15;
            }

            #Path_2113 {
              fill: white;
            }
          }
        }

        &__insta {
          svg {
            border-radius: 50px;

            #Ellipse_97 {
              fill: #e8e8e8;
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              r: 15;
              cursor: pointer;
            }
          }

          &:hover {
            #Ellipse_97 {
              fill: #e50019;
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              cx: 15;
            }

            #Path_2110, #Path_2109, #Path_2111 {
              fill: white;
            }
          }
        }

        &__youtube {
          svg {
            border-radius: 50px;

            #Ellipse_100 {
              fill: #e8e8e8;
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              r: 15;
              cursor: pointer;
            }
          }

          &:hover {
            #Ellipse_100 {
              fill: #e50019;
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              cx: 15;
            }

            #Path_2114 {
              fill: white;
            }
          }
        }
        &__linkedin{
          svg {
            border-radius: 50px;

            #Ellipse_100 {
              fill: #e8e8e8;
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              r: 15;
              cursor: pointer;
            }
          }

          &:hover {
            #Ellipse_100 {
              fill: #e50019;
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              cx: 15;
            }

            #Path_2114 {
              fill: white;
            }
          }
        }
      }
    }
  }

`;

export default MyComponent;
