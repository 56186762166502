import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Container, Row, Col, Accordion} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import {CSSPlugin, gsap, TimelineLite} from "gsap";
import {useDispatch, useSelector} from "react-redux";
import {clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll} from "body-scroll-lock";


const MyComponent = ({color, data}) => {
    const mobileMenuRaf = useRef()
    const menuRef = useRef()
    const hamburgerRef = useRef();
    const hamburgerCloseRef = useRef();
    const [scrollDirection, setScrollDirection] = useState('down');
    const Store = useSelector((state) => state);
    const location = useLocation()


    const remove_all_mobile = (e) => {
        var menuOpen = document.querySelectorAll(".Mobile-menu-wrap");
        var hamburger = document.querySelectorAll(".hamburger");
        var menu = document.querySelectorAll(".main_child");
        var menuSub = document.querySelectorAll(".main-child-sub ")
        var menuSubSub = document.querySelectorAll(".main-child-sub-sub");
        hamburger.forEach((i) => {
            i.classList.remove("menu-open");
            gsap.to(menuRef.current, {
                opacity: 0,
                duration: ".2",
            });
            gsap.to(menuRef.current, {
                display: "none",
            });
        });
        menuOpen.forEach((i) => {
            i.classList.remove("menu-open");

        });

        menu.forEach((i) => {
            i.classList.remove("submenu-open-mb");

        });
        menuSub.forEach((i) => {
            i.classList.remove("submenu-open-next");

        });
        menuSubSub.forEach((i) => {
            i.classList.remove("submenu-open-next-next");
            i.classList.remove("submenu-open-next");
            i.classList.remove("submenu-open-mb");

        });
    }

    const handleAboutClick = (e) => {
        document.body.classList.remove('mobile-menu-open');
        remove_all_mobile()
    };


    useEffect(() => {

        const body = document.body;
        const scrollUp = "scroll-up";
        const scrollDown = "scroll-down";

        let getSiblings = function (e) {
            // for collecting siblings
            let siblings = [];
            // if no parent, return no sibling
            if (!e.parentNode) {
                return siblings;
            }
            // first child of the parent node
            let sibling = e.parentNode.firstChild;
            // collecting siblings
            while (sibling) {
                if (sibling.nodeType === 1 && sibling !== e) {
                    siblings.push(sibling);
                }
                sibling = sibling.nextSibling;
            }
            return siblings;
        };

        // Mobile menu start
        // for mobile submenu


        let getDropdownChildMobile = document.querySelectorAll(".main_child");
        getDropdownChildMobile.forEach((i) => {
            i.addEventListener("click", (r) => {
                // r.target.parentElement.classList.toggle("sub-menu-open");

                if (r.target.parentElement.classList.contains('submenu-open-mb')) {
                    r.target.parentElement.classList.remove("submenu-open-mb");

                } else {
                    // if (document.querySelector(".sub-menu-open")) {
                    //     document
                    //         .querySelector(".sub-menu-open")
                    //         .classList.remove("sub-menu-open");
                    // }

                    r.target.parentElement.classList.toggle("submenu-open-mb");
                    // r.target.parentElement.nextElementSibling.classList.remove('submenu-open')

                    document.querySelector('.submenu-open-mb').addEventListener('click', (ne) => {
                        let parent = ne.target.parentElement;
                        parent.parentElement.classList.remove("submenu-open-mb");
                    })

                }
            });
        });


        let nextStepChild = document.querySelectorAll(".main-child-sub");
        nextStepChild.forEach((i) => {
            i.addEventListener("click", (r) => {

                if (r.target.parentElement.classList.contains('submenu-open-next')) {
                    r.target.parentElement.classList.remove("submenu-open-next");

                } else {


                    r.target.parentElement.classList.toggle("submenu-open-next");

                    document.querySelector('.submenu-open-next').addEventListener('click', (ne) => {
                        let parent = ne.target.parentElement;
                        parent.parentElement.classList.remove("submenu-open-next");
                    })

                }
            });
        });


        let nextStepChildChild = document.querySelectorAll(".main-child-sub-sub");
        nextStepChildChild.forEach((i) => {
            i.addEventListener("click", (r) => {
                // r.target.parentElement.classList.toggle("sub-menu-open");

                if (r.target.parentElement.classList.contains('submenu-open-next-next')) {
                    r.target.parentElement.classList.remove("submenu-open-next-next");


                } else {
                    // if (document.querySelector(".sub-menu-open")) {
                    //     document
                    //         .querySelector(".sub-menu-open")
                    //         .classList.remove("sub-menu-open");
                    // }

                    r.target.parentElement.classList.toggle("submenu-open-next-next");
                    // r.target.parentElement.nextElementSibling.classList.remove('submenu-open')

                    document.querySelector('.submenu-open-next-next').addEventListener('click', (ne) => {
                        let parent = ne.target.parentElement;
                        parent.parentElement.classList.remove("submenu-open-next-next");
                    })

                }
            });
        });


        // menu click on mobile
        hamburgerRef.current.addEventListener("click", () => {
            document.body.classList.add('mobile-menu-open');
            document.querySelector(".hamburger").classList.add("menu-open");
            document.querySelector(".Mobile-menu-wrap").classList.add("menu-open");
            gsap.to(menuRef.current, {
                display: "block",
                duration: "0.5",
                x: '100%',
            });
            gsap.to(menuRef.current, {
                x: 0,
                opacity: 1,
                duration: "0.5",
            });
            const target = document.querySelectorAll('.Mobile-menu-wrap')
            // disableBodyScroll(target);

        });
        hamburgerCloseRef.current.addEventListener("click", () => {
            document.body.classList.remove('mobile-menu-open');
            document.querySelector(".hamburger").classList.remove("menu-open");
            document.querySelector(".Mobile-menu-wrap").classList.remove("menu-open");
            gsap.to(menuRef.current, {

                opacity: 0,
                x: '100%',
                duration: "0.5",
            });
            gsap.to(menuRef.current, {

                opacity: 0,
                duration: "0.2",
            });
        });
        // const menuItems = document.querySelectorAll('.Mobile-menu-wrap ul li a');
        // menuItems.forEach(item => {
        //     setTimeout(() => {
        //         item.addEventListener('click', (e) => {
        //             enableBodyScroll(e);
        //         });
        //     }, 2000);
        // });
        // Mobile menu end

        //---- on scroll menu fixed action
        if (document.body.classList.contains("scroll-down")) {
            document.body.classList.remove("scroll-down");
        }


    }, []);


    // scroll menu
    useEffect(() => {
        const body = document.body;
        const scrollUp = "scroll-up";
        const scrollDown = "scroll-down";

        const handleScroll = () => {
            if (window.scrollY > 300 && scrollDirection === 'down') {
                setScrollDirection('up');
                body.classList.add(scrollUp);

            } else if (window.scrollY < 300 && scrollDirection === 'up') {
                setScrollDirection('down');
                body.classList.remove(scrollUp);

            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrollDirection]);


    const targetElementRef = useRef(null);

    useEffect(() => {

        targetElementRef.current = document.querySelector('.show');

        return () => {

            clearAllBodyScrollLocks();
        };
    }, []);


    const [scrollUp, setScrollUp] = useState(false);
    const [scrollDown, setScrollDown] = useState(false);


    // menu fixed on scroll
    useEffect(() => {
        if (document.body.classList.contains('scroll-down')) {
            document.body.classList.remove('scroll-down');
        }
    });

    useEffect(() => {
        const body = document.body;
        const scrollUp = 'scroll-up';
        const scrollDown = 'scroll-down';
        let lastScroll = 0;
        let howMuchScroll = 150;
        if (window.screen.width < 991) {
            howMuchScroll = 0;
        } else {
            howMuchScroll = 150;
        }

        window.addEventListener('scroll', () => {
            let currentScroll = window.pageYOffset;

            if (currentScroll <= howMuchScroll) {
                body.classList.remove(scrollUp);
                return;
            }
            if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
                // down
                body.classList.remove(scrollUp);
                body.classList.add(scrollDown);
            } else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) {
                // up
                body.classList.remove(scrollDown);
                body.classList.add(scrollUp);
            }
            lastScroll = currentScroll;
        });
    }, []);


    return (
        <StyledComponent className="Mobile-menu-wrap menu-bar">
            <Container>
                <Row className="mobile-menu" ref={mobileMenuRaf}>
                    <Col className="logo forNormal">
                        <div className={'regular'}>
                            <Link to={'/'}>
                                {color !== 'transparent' ?
                                    (
                                            <img src={'/images/static/logo-black.svg'} alt="Partex Logo"/>
                                    )
                                    :
                                    (
                                            <img src={'/images/static/logo_white.svg'} alt="Partex Logo"/>
                                    )
                                }
                            </Link>
                        </div>
                        <div className={'scroll-up'}>
                            <Link to={'/'}>
                                <img src={'/images/static/logo-black.svg'} alt="Partex Logo White"/>
                            </Link>
                        </div>
                    </Col>

                    <Col className="logo forClose">
                        <Link to={"/"}>
                            <img src={'/images/static/menu/partex_logo_white.svg'}/>
                        </Link>
                    </Col>

                    <Col className="hamburger">
                        <span ref={hamburgerRef} className={'mobile-sticky'}>
                {color !== 'transparent' ?
                    <div className={'hamburger-icon hamburger-black'}>
                        <span className={'first-span'}></span>
                        <span className={'second-span'}></span>
                        <span className={'third-span'}></span>
                    </div>
                    :
                    <div className={'hamburger-icon'}>
                        <span className={'first-span'}></span>
                        <span className={'second-span'}></span>
                        <span className={'third-span'}></span>
                    </div>
                }

                          </span>

                        <span className={'close-icon'} ref={hamburgerCloseRef}>
                                 <svg xmlns="http://www.w3.org/2000/svg" width="24.042" height="24.042"
                                      viewBox="0 0 24.042 24.042">
                                        <g id="Lines" transform="translate(-332.979 -31.979)">
                                            <line id="Line_3965" data-name="Line 3965" x1="30"
                                                  transform="translate(355.606 33.394) rotate(135)" fill="none"
                                                  stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_3966" data-name="Line 3966" x1="30"
                                                  transform="translate(334.393 33.393) rotate(45)" fill="none"
                                                  stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_3967" data-name="Line 3967" x1="30"
                                                  transform="translate(355.607 33.394) rotate(135)" fill="none"
                                                  stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                        </g>
                                    </svg>
              </span>
                    </Col>

                    <div className="mobile-menu__items" ref={menuRef}>

                        <ul className="mobile-menu__items__ul">
                            <Accordion defaultActiveKey="">
                                <li>
                                    <Link to={"/"} onClick={() => handleAboutClick()}>
                                        Home
                                    </Link>
                                </li>
                                <li className={'main_child'}>
                                    <Link to={"#"}>
                                        Our Concerns
                                        <svg class="sub-icon" xmlns="http://www.w3.org/2000/svg" width="21" height="21"
                                             viewBox="0 0 21 21">
                                            <g id="Group_16491" data-name="Group 16491"
                                               transform="translate(0.5 20.5) rotate(-90)">
                                                <circle id="Ellipse_450" data-name="Ellipse 450" cx="10" cy="10" r="10"
                                                        transform="translate(0 20) rotate(-90)" fill="#fff"
                                                        stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"
                                                        opacity="0.5"/>
                                                <g id="Group_16490" data-name="Group 16490"
                                                   transform="translate(13 11.5) rotate(180)">
                                                    <line id="Line_3973" data-name="Line 3973" y1="3" x2="3" fill="none"
                                                          stroke="#323232" stroke-linecap="round" stroke-width="1"/>
                                                    <line id="Line_3974" data-name="Line 3974" x1="3" y1="3"
                                                          transform="translate(3)" fill="none" stroke="#323232"
                                                          stroke-linecap="round" stroke-width="1"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </Link>

                                    <ul className={'submenu'}>
                                        <div className="main_title">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                 viewBox="0 0 20 20">
                                                <g id="Group_16491" data-name="Group 16491"
                                                   transform="translate(-8568 -15343) rotate(-90)">
                                                    <circle id="Ellipse_450" data-name="Ellipse 450" cx="10" cy="10"
                                                            r="10" transform="translate(-15343 8568) rotate(90)"
                                                            fill="#fff"/>
                                                    <g id="Group_16490" data-name="Group 16490"
                                                       transform="translate(-30769.5 17156) rotate(180)">
                                                        <line id="Line_3973" data-name="Line 3973" x2="3" y2="3"
                                                              transform="translate(-15419.5 8576.5)" fill="none"
                                                              stroke="#323232" stroke-linecap="round" stroke-width="1"/>
                                                        <line id="Line_3974" data-name="Line 3974" x1="3" y2="3"
                                                              transform="translate(-15416.5 8576.5)" fill="none"
                                                              stroke="#323232" stroke-linecap="round" stroke-width="1"/>
                                                    </g>
                                                </g>
                                            </svg>
                                            Our Concerns
                                        </div>
                                        <li onClick={handleAboutClick}><Link to={'/star-particle-board-mill'}>Star
                                            Particle Board Mills Limited</Link></li>
                                        <li onClick={handleAboutClick}><Link to={'/concern/partex-cables'}>Partex Cables
                                            Limited</Link></li>
                                        <li onClick={handleAboutClick}><Link
                                            to={'/concern/partex-furniture-industries-ltd'}>Partex Furniture Industries
                                            Limited</Link></li>
                                        <li onClick={handleAboutClick}><Link
                                            to={'/concern/partex-gypsum-boards-mills-ltd'}>Partex Gypsum Boards Mills
                                            Limited</Link></li>
                                        <li onClick={handleAboutClick}><Link to={'/concern/star-adhesives-ltd'}>Star
                                            Adhesives Limited.</Link></li>
                                        <li onClick={handleAboutClick}><Link to={'/concern/partex-laminates-limited'}>Partex
                                            Laminates Limited</Link></li>
                                        <li onClick={handleAboutClick}><Link to={'/concern/triple-apparels-ltd'}>Triple
                                            Apparels Limited</Link></li>
                                        <li onClick={handleAboutClick}><Link to={'/concern/partex-builders-ltd'}>Partex
                                            Builders Limited</Link></li>
                                        <li onClick={handleAboutClick}><Link to={'/concern/ashley-furniture'}>Partex
                                            Home Stores Limited (Ashley Furniture)</Link></li>
                                        <li onClick={handleAboutClick}><Link to={'/concern/partex-agro-ltd'}>Partex Agro
                                            Limited</Link></li>
                                        <li onClick={handleAboutClick}><Link to={'/concern/cafe-de-partex'}>Café de
                                            Partex</Link></li>
                                        <li onClick={handleAboutClick}><Link to={'/concern/partex-aeromarine'}>Partex
                                            Aeromarine Logistics Limited (Bangladesh)</Link></li>
                                        <li onClick={handleAboutClick}><Link to={'/concern/partex-aeromarine-india'}>Partex
                                            Aeromarine Logistics Limited (India)</Link></li>
                                        <li onClick={handleAboutClick}><Link to={'/sister-concerns'}>Sister
                                            Concerns</Link></li>
                                    </ul>
                                </li>

                                <li>
                                    <Link to={"/board-of-directors"} onClick={() => handleAboutClick()}>
                                        Board of Directors
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/key-executives'} onClick={() => handleAboutClick()}>Key
                                        Executives</Link>
                                </li>
                                <li>
                                    <Link to={"/about"} onClick={() => handleAboutClick()}>
                                        Partex Star Group
                                    </Link>
                                </li>

                                <li>
                                    <Link to={"/a-visionarys-legacy"} onClick={() => handleAboutClick()}>
                                        A Visionary's Legacy
                                    </Link>
                                </li>

                                <li>
                                    <Link to={'/csr'} onClick={() => handleAboutClick()}>CSR</Link>

                                </li>
                                <li>
                                    <Link to={'/tvc'} onClick={() => handleAboutClick()}>TVC</Link>
                                </li>
                                <li>
                                    <Link to={'/career'} onClick={() => handleAboutClick()}>Career</Link>

                                </li>
                                <li>
                                    <Link to={'/contact'} onClick={() => handleAboutClick()}>Contact</Link>
                                </li>
                            </Accordion>
                        </ul>
                        <div className={'social__icon'}>
                            <div className={'social__icon__icons'}>
                                <a href={'https://www.facebook.com/partexstargroup/'} target={"_blank"}>
                                    <div className={'social__icon__icons__facebook'}>
                                        <svg id="Animation_-_Social_Icons" data-name="Animation - Social Icons"
                                             xmlns="http://www.w3.org/2000/svg"
                                             xmlnsXlink="http://www.w3.org/1999/xlink"
                                             width="30" height="30" viewBox="0 0 30 30">
                                            <g id="Mask_Group_13" data-name="Mask Group 13"
                                               transform="translate(15955 -7152)">
                                                <circle id="Ellipse_447" data-name="Ellipse 447" cx="15" cy="15" r="15"
                                                        transform="translate(-15955 7152)" fill="#e8e8e8"
                                                        stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                                                <circle id="Ellipse_449" data-name="Ellipse 449" cx="15" cy="15" r="15"
                                                        transform="translate(-15990 7152)" fill="#e50019"
                                                        stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                                                <path id="Path_2115" data-name="Path 2115"
                                                      d="M1206.12,104.34l.406-2.652h-2.544v-1.72a1.325,1.325,0,0,1,1.495-1.432h1.157V96.278a14.1,14.1,0,0,0-2.053-.179,3.237,3.237,0,0,0-3.465,3.569v2.021h-2.329v2.652h2.329v6.409h2.866V104.34Z"
                                                      transform="translate(-17142.787 7063.901)" fill="#323232"/>
                                            </g>
                                        </svg>
                                    </div>
                                </a>
                            </div>

                            <div className={'social__icon__icons'}>
                                <a href={'https://x.com/partex_star'} target={"_blank"}>
                                    <div className={'social__icon__icons__twitter'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31"
                                             viewBox="0 0 31 31">
                                            <g id="Group_4899" data-name="Group 4899"
                                               transform="translate(15955.5 -7201.5)">
                                                <circle id="Ellipse_93" data-name="Ellipse 93" cx="15" cy="15"
                                                        r="15"
                                                        transform="translate(-15955 7202)" fill="#e8e8e8"
                                                        stroke="rgba(0,0,0,0)" stroke-miterlimit="10"
                                                        stroke-width="1"/>
                                                <circle id="Ellipse_94" data-name="Ellipse 93" cx="-17" cy="15"
                                                        r="15"
                                                        transform="translate(-15955 7202)" fill="#e8e8e8"
                                                        stroke="rgba(0,0,0,0)" stroke-miterlimit="10"
                                                        stroke-width="1"/>
                                                <path id="Path_2113" data-name="Path 2113"
                                                      d="M1237.574,104.23a5.331,5.331,0,0,1-1.306.372c.21-.035.518-.415.642-.569a2.4,2.4,0,0,0,.433-.79c.011-.023.02-.051,0-.068a.075.075,0,0,0-.069.006,6.668,6.668,0,0,1-1.548.592.1.1,0,0,1-.107-.029,1.258,1.258,0,0,0-.135-.138,2.736,2.736,0,0,0-.75-.459,2.609,2.609,0,0,0-1.15-.185,2.745,2.745,0,0,0-1.091.308,2.817,2.817,0,0,0-.88.719,2.7,2.7,0,0,0-.525,1.053,2.844,2.844,0,0,0-.028,1.111c.008.062,0,.071-.053.062a8.268,8.268,0,0,1-5.319-2.707c-.062-.071-.1-.071-.147.005a2.7,2.7,0,0,0,.46,3.186c.1.1.212.2.327.288a2.711,2.711,0,0,1-1.026-.288c-.062-.039-.094-.017-.1.054a1.62,1.62,0,0,0,.017.3,2.727,2.727,0,0,0,1.681,2.174,1.583,1.583,0,0,0,.341.1,3.04,3.04,0,0,1-1.007.031c-.073-.014-.1.023-.073.093a2.831,2.831,0,0,0,2.115,1.771c.1.017.192.017.288.039-.006.009-.012.009-.017.017a3.347,3.347,0,0,1-1.444.765,5.171,5.171,0,0,1-2.194.281c-.118-.017-.143-.016-.174,0s0,.048.034.079c.15.1.3.186.457.271a7.231,7.231,0,0,0,1.466.586,7.811,7.811,0,0,0,7.582-1.773,7.956,7.956,0,0,0,2.1-5.8c0-.083.1-.129.157-.174a5.128,5.128,0,0,0,1.032-1.073.327.327,0,0,0,.068-.205v-.011C1237.627,104.2,1237.626,104.207,1237.574,104.23Z"
                                                      transform="translate(-17170.717 7109.043)" fill="#323232"/>
                                            </g>
                                        </svg>
                                    </div>
                                </a>
                            </div>

                            <div className={'social__icon__icons'}>
                                <a href={'https://www.instagram.com/partexstargroup'} target={"_blank"}>
                                    <div className={'social__icon__icons__insta'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31"
                                             viewBox="0 0 31 31">
                                            <g id="Group_4898" data-name="Group 4898"
                                               transform="translate(15955.5 -7251.5)">
                                                <circle id="Ellipse_98" data-name="Ellipse 98" cx="15" cy="15"
                                                        r="15"
                                                        transform="translate(-15955 7252)" fill="#e8e8e8"
                                                        stroke="rgba(0,0,0,0)" stroke-miterlimit="10"
                                                        stroke-width="1"/>
                                                <circle id="Ellipse_97" data-name="Ellipse 98" cx="-17" cy="15"
                                                        r="15"
                                                        transform="translate(-15955 7252)" fill="#e8e8e8"
                                                        stroke="rgba(0,0,0,0)" stroke-miterlimit="10"
                                                        stroke-width="1"/>
                                                <path id="Path_2109" data-name="Path 2109"
                                                      d="M1095.77,105.945a.854.854,0,1,0,.853.854A.854.854,0,0,0,1095.77,105.945Z"
                                                      transform="translate(-17031.635 7156.654)" fill="#323232"/>
                                                <path id="Path_2110" data-name="Path 2110"
                                                      d="M1082.64,108.605a3.586,3.586,0,1,0,3.586,3.586A3.59,3.59,0,0,0,1082.64,108.605Zm0,5.882a2.3,2.3,0,1,1,2.3-2.3A2.3,2.3,0,0,1,1082.64,114.488Z"
                                                      transform="translate(-17022.301 7155.089)" fill="#323232"/>
                                                <path id="Path_2111" data-name="Path 2111"
                                                      d="M1080.119,114.188h-5.813a4.379,4.379,0,0,1-4.374-4.374V104a4.378,4.378,0,0,1,4.374-4.373h5.813a4.378,4.378,0,0,1,4.374,4.373v5.813A4.379,4.379,0,0,1,1080.119,114.188ZM1074.306,101a3.007,3.007,0,0,0-3,3v5.813a3.007,3.007,0,0,0,3,3h5.813a3.007,3.007,0,0,0,3-3V104a3.007,3.007,0,0,0-3-3Z"
                                                      transform="translate(-17016.932 7160.372)" fill="#323232"/>
                                            </g>
                                        </svg>
                                    </div>
                                </a>
                            </div>

                            <div className={'social__icon__icons'}>
                                <a href={'https://www.youtube.com/@partexstargroup7699'} target={"_blank"}>
                                    <div className={'social__icon__icons__youtube'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31"
                                             viewBox="0 0 31 31">
                                            <g id="Group_4897" data-name="Group 4897"
                                               transform="translate(15955.5 -7301.5)">
                                                <circle id="Ellipse_99" data-name="Ellipse 99" cx="15" cy="15"
                                                        r="15"
                                                        transform="translate(-15955 7302)" fill="#e8e8e8"
                                                        stroke="rgba(0,0,0,0)" stroke-miterlimit="10"
                                                        stroke-width="1"/>
                                                <circle id="Ellipse_100" data-name="Ellipse 99" cx="-17" cy="15"
                                                        r="15"
                                                        transform="translate(-15955 7302)" fill="#e8e8e8"
                                                        stroke="rgba(0,0,0,0)" stroke-miterlimit="10"
                                                        stroke-width="1"/>
                                                <path id="Path_2114" data-name="Path 2114"
                                                      d="M1146.9,113.13a2.813,2.813,0,0,0-2.813-2.813h-7.195a2.813,2.813,0,0,0-2.813,2.813v3.348a2.813,2.813,0,0,0,2.813,2.813h7.195a2.813,2.813,0,0,0,2.813-2.813Zm-4.231,1.925-3.226,1.6c-.126.068-.556-.023-.556-.167v-3.276c0-.146.433-.237.56-.165l3.089,1.68C1142.661,114.8,1142.8,114.985,1142.666,115.056Z"
                                                      transform="translate(-17080.074 7202.683)" fill="#323232"/>
                                            </g>
                                        </svg>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>

                </Row>

            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`

  @media (min-width: 992px) {
    display: none;
  }


  &.menu-open {
    transform: unset !important;
    transition: unset;
  }


  //.scroll-down .Mobile-menu-wrap {
  //  transform: unset!important;
  //  transition: unset;
  //}


  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  z-index: 99;
  display: flex;
  align-items: center;
  transition: background .4s cubic-bezier(0.4, 0, 0, 1);
  overflow: visible !important;

  //display: none;

  .menu-bar {
    background-color: transparent !important;
    box-shadow: unset !important;
  }

  .forClose {
    display: none !important;

    a {
      img {
        height: 50px !important;
      }
    }
  }

  //hamburger-icon


  .mobile-sticky {
    .hamburger-icon {
      display: inline-block;
      cursor: pointer;
      width: 30px;
      height: 16px;
      position: relative;
      margin: 3px 0px 0px 23px;
    }

    .hamburger-icon span {
      display: block;
      position: absolute;
      height: 2px;
      width: 110%;
      background: #FFFFFF;
      transition: transform 0.3s ease;


      &:nth-child(1) {
        top: 0;
        width: 75%;
        right: 0;
        transition: width .3s ease;
      }

      &:nth-child(2) {
        display: block !important;
        right: 0;
        top: 50%;
        margin-top: -1px;
        transition: width .3s ease;
      }

      &:nth-child(3) {
        bottom: 0;
        width: 75%;
        right: 0;
        transition: width .3s ease;
      }
    }

    .hamburger-black {
      span {
        background: #323232;
      }
    }

    /* Animation for the active/hamburger open state */

    .hamburger-icon:hover span:nth-child(1) {
      width: 110%;
      transition: width .3s ease;
    }

    .hamburger-icon:hover span:nth-child(2) {
      display: block;
      width: 75%;
      transition: width .3s ease;
    }

    .hamburger-icon:hover span:nth-child(3) {
      width: 110%;
      transition: width .3s ease;
    }
  }


  .container {
    background-color: transparent;
    overflow: hidden;
    transition: all 1.2s cubic-bezier(0.4, 0, 0, 1) 0s;
  }

  &.menu-open {
    //background: rgba(255, 255, 255, 1);

    .container {
      background-color: #323232;
      overflow: hidden;
      transition: all 1.2s cubic-bezier(0.4, 0, 0, 1) 0s;
      @media (min-width: 768px) and (max-width: 991px) {
        max-width: 100% !important;
        padding-left: 7%;
        padding-right: 7%;
      }
    }

    .forNormal {
      display: none !important;
    }

    .forClose {
      display: flex !important;
    }
  }

  .mobile-menu {
    .main_child a svg {
      z-index: -1;
    }

    .mobile-menu__items {
      overflow: hidden;

      &__ul {
        overflow: visible;
        overflow-x: clip;
        overflow-y: scroll;
      }
    }

    height: 90px;

    .logo {
      display: flex;
      align-items: center;

      span {
        width: 220px !important;
        //height: 30px !important;
      }

      .regular {
        display: block;
      }

      .scroll-up {
        display: none;
      }

      body.scroll-up & {
        .regular {
          display: none;
        }

        .scroll-up {
          display: block;
        }
      }

      a {
        img {
          width: 119px;
          height: 50px;
        }
      }

    }

    .hamburger {
      position: relative;
      display: flex;
      align-items: center;
      //justify-content: flex-end;

      span {
        position: absolute !important;
        right: 10px !important;
        //height: 25px !important;
        display: flex;

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          color: #F9F9F9;
          margin: 0 10px 0 0;
        }
      }

      .close-icon {
        display: none !important;
      }

      &.menu-open span {
        &:nth-of-type(1) {
          display: none !important;
        }

        &:nth-of-type(2) {
          display: flex !important;
          right: 20px !important;
        }
      }
    }

    &__items {

      display: none;
      position: absolute;
      opacity: 0;
      background-color: #323232;
      height: calc(100svh - 90px);
      top: 90px;
      left: 0;
      width: 100%;
      z-index: 99;
      padding: 55px 15px 60px 15px;
      overflow: hidden;
      transition: opacity .1s cubic-bezier(0.4, 0, 0, 1);

      &:after {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background: #C4C4C4;
        height: 1px;
        content: "";
        margin: 0 auto;
        width: 100%;
      }

      &__top {
        ul {
          li {
            a {
              height: 45px;
              border-radius: 25px;
              background-color: #D80028;
              color: #FFFFFF;
              font-size: 18px;
              font-weight: 400;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding: 0 30px;
              text-transform: capitalize;

              margin-bottom: 30px;

              img {
                margin-right: 10px;
              }

              span {
                margin-right: 10px !important;
              }
            }

            &:nth-last-child(1) a {
              margin-bottom: 60px;
              background-color: #4F616B;

              &:hover {
                color: #ffffff !important;
              }
            }

          }
        }
      }

      &__ul {
        height: 100%;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          display: none;
        }

        scrollbar-width: none;

        li {
          padding-bottom: 25px;
          border-bottom: 1px solid grey;
          margin-bottom: 25px;

          &:nth-last-child(1) {
            border-bottom: 0;
          }

          a {
            color: #999999;
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;
            text-transform: capitalize;
          }
        }

      }

    }

    &.menu-open {

      .mobile-menu__items {
        display: block;
      }

    }


    .main-child-sub {
      a {
        display: flex;
        justify-content: space-between;
      }
    }

    //accordion
    .accordion-item {
      .accordion-header {
        .accordion-button {
          background-color: transparent;
          color: #4F616B;
          font-weight: 500;
          font-size: 20px;
          line-height: 20px;
          text-transform: capitalize;
          width: 100%;
          text-align: left;
          display: flex;
          justify-content: space-between;
          padding: 0;
          box-shadow: none;
          border: none;
          margin-bottom: 20px;
          padding-bottom: 25px;
          border-bottom: 1px solid #E1E4E6;

          p {
            margin: 0;
            font-size: 22px;
            line-height: 28px;
            text-transform: capitalize;
            font-weight: bold;
            padding-right: 30px;
            width: 100%;
          }

          span {
            &:nth-last-child(2) {
              display: none !important;
            }

            &:nth-last-child(1) {
              display: block !important;
            }
          }


          &.collapsed {
            span {
              &:nth-last-child(2) {
                display: block !important;
              }

              &:nth-last-child(1) {
                display: none !important;
              }
            }
          }


          &.collapsed {
            border-bottom: 1px solid #E1E4E6;
          }
        }


      }

      .accordion-body {
        padding-bottom: 25px;
        padding-top: 20px;

        ul {
          li {
            border: none;
            padding-bottom: 0;
            margin-bottom: 0;

            a {
              font-size: 16px;
              line-height: 22px;
              font-weight: 600;
              margin-bottom: 15px;
              display: block;
            }

            &:after {
              display: none;
            }
          }
        }
      }

      &:nth-last-child(1) {
        .accordion-button {
          //border: none !important;
        }
      }

    }

    .social {
      &__icon {
        padding: 15px 0;
        position: absolute;
        bottom: unset;
        display: flex;
        z-index: 1;

        &__icons {
          margin-right: 20px;
        }
      }
    }

    .main_child {
      border: none;
      padding-bottom: 0;
      position: relative;

      a {
        background-color: transparent;
        color: #999999;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        text-transform: capitalize;
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: space-between;
        padding: 0;
        box-shadow: none;
        border: none;
        margin-bottom: 20px;
        padding-bottom: 25px;
        border-bottom: 1px solid grey;

        @media (max-width: 767px) {
          img {
            z-index: -1;
            position: relative;
          }
        }

        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          height: 25px;
          width: 25px;
          background: transparent;
          border-radius: 50px;
          z-index: 100000;
        }

        &:hover {
          color: white !important;
          opacity: 70%;
        }

        p {
          margin: 0;
          font-size: 22px;
          line-height: 28px;
          text-transform: capitalize;
          font-weight: bold;
          padding-right: 30px;
          width: 100%;
        }

        span {
          &:nth-last-child(2) {
            display: none !important;
          }

          &:nth-last-child(1) {
            display: block !important;
          }
        }


        &.collapsed {
          span {
            &:nth-last-child(2) {
              display: block !important;
            }

            &:nth-last-child(1) {
              display: none !important;
            }
          }


        }


        &.collapsed {
          border-bottom: 1px solid #E1E4E6;
        }
      }

      &.submenu-open-mb {
        position: relative;
        z-index: 100;

        .submenu {
          opacity: 1;
          visibility: visible;
          transform: translateX(0);
          overflow: scroll;
        }

        .submenu-open-next {
          .mega_sub {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
          }

        }

        .submenu-open-next-next {
          .mega_sub_sub {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
          }

        }
      }

      .submenu {
        padding: 55px 15px 30px;
        position: fixed;
        right: 0;
        top: 0;
        left: 0;
        bottom: 0;
        background: #323232;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
        transform: translateX(100%);

        &::-webkit-scrollbar {
          display: none;
        }

        .main_title {
          font-size: 20px;
          line-height: 24px;
          text-align: left;
          display: flex;
          color: white;
          margin-bottom: 60px;
          font-weight: 400;

          svg {
            margin-right: 20px;
            position: relative;
            z-index: -1;
          }
        }

        li {
          border: none;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: white;
            font-size: 20px;
            line-height: 24px;
            font-weight: 400;
            margin-bottom: 25px;
            //display: block;
          }

          &:after {
            display: none;
          }
        }

        .mega_sub {
          padding: 20px 15px 30px;
          position: fixed;
          right: 0;
          top: 0px;
          left: 0;
          bottom: 0;
          background: white;
          width: 100%;
          opacity: 0;
          visibility: hidden;
          transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
          transform: translateX(100%);
          height: 185svh;

          &:hover {
            //color: #4F616B;
          }
        }

        .mega_sub_sub {
          padding: 20px 15px 30px;
          position: fixed;
          right: 0;
          top: 0px;
          left: 0;
          bottom: 0;
          background: white;
          z-index: 999;
          width: 100%;
          opacity: 0;
          visibility: hidden;
          transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
          transform: translateX(100%);
        }


      }

      .fotter_menu {
        margin-top: 150px;

        h3 {
          font-size: 32px;
          color: #4F616B;
          line-height: 32px;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: #4F616B;
        }

        .footer {
          margin-top: 40px;

          a {
            font-size: 24px;
            font-weight: 400;
            line-height: 28px;
            color: #4F616B;
            padding: 0;
            display: block;
            margin: 0 0 10px;
            border: none;

            &:last-child {
              margin: 0;
            }
          }
        }
      }

      &:nth-last-child(1) {
        .accordion-button {
          //border: none !important;
        }
      }

    }

  }

  .mobile-bottom-bar__buttons {
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .mobile-bottom-bar__account {
    position: fixed;
    width: 100%;
    padding: 110px 20px 60px 20px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.20);
    visibility: hidden;
    transform: translateY(100%);
    transition: all .6s cubic-bezier(0.87, 0, 0.13, 1);

    svg {
      position: absolute;
      right: 13px;
      top: 50px;
      font-size: 40px;
      color: black;
    }

    p {
      font-size: 16px;
      color: #D0CA10;
      line-height: 22px;
      margin-bottom: 40px;
      font-weight: 600;
    }

    ul {
      width: 100%;

      li {
        a {
          display: flex;
          font-size: 12px;
          line-height: 18px;
          font-weight: 600;
          padding-bottom: 20px;
          border-bottom: 1px solid rgba(34, 31, 31, 0.1);
          margin-bottom: 20px;
        }

        &:nth-last-of-type(1) {
          a {
            margin-bottom: 0;
            border-bottom: 0;
          }
        }
      }
    }

    &.open {
      transform: none;
      visibility: visible;
    }
  }

  .has-child-sub.open_submenu > a svg {
    opacity: 1;
    right: 0;
  }

  .has-child-sub-sub a:hover {
    svg {
      opacity: 1 !important;
    }
  }


`;

export default MyComponent;
