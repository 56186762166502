import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {black, blue, hoverr} from "../styles/globalStyleVars";
import {FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter, FaYoutube} from 'react-icons/fa';

const FooterLarge = ({data}) => {
    const d = new Date();

    let year = d.getFullYear();

    // api data refactor
    let rowData = data && data?.data;
    return (<StyledFooter className='footer-large pt-160 pb-20'>
        <Container className='footer-large__top'>
            <Row>
                <p className={'opacity-30 bg-text-one'}>PARTEX</p>
                <p className={'opacity-30 bg-text-two'}>STAR GROUP</p>

                <Col className='footer-col' md={3}>
                    <div className="group logo">
                        <img src={'/images/static/footer-logo.svg'}/>
                        <p>Partex Star Group is one of the largest Bangladeshi diversified private sector
                            enterprises.</p>

                        <ul className="social-menus d-flex">
                            <li className='hover' target="_blank"><a href={'https://www.facebook.com/partexstargroup/'}
                                                                     target={"_blank"}><FaFacebookF/></a>
                            </li>
                            <li className='hover' target="_blank"><a href={'https://x.com/partex_star'}
                                                                     target={"_blank"}><FaTwitter/></a>
                            </li>
                            <li className='hover' target="_blank"><a href={'https://www.instagram.com/partexstargroup/'}
                                                                     target={"_blank"}><FaInstagram/></a>
                            </li>
                            <li className='hover' target="_blank"><a
                                href={'https://www.linkedin.com/company/3046430/admin/feed/posts/'}
                                target={"_blank"}><FaLinkedinIn/></a>
                            </li>
                            <li className='hover' target="_blank"><a
                                href={'https://www.youtube.com/@partexstargroup7699'} target={"_blank"}><FaYoutube/></a>
                            </li>
                        </ul>
                    </div>
                </Col>

                <Col className='footer-col' md={2}>
                    <div className="group">
                        <h4>Who We Are</h4>
                        <ul>
                            <li><Link to={'/about'}>Partex Star Group</Link></li>
                            <li><Link to={'/about#heritage'}>Our Heritage</Link></li>
                            <li><Link to={'/about#mission-vision'}>Our Mission and Vision</Link></li>
                            <li><Link to={'/about#core-value'}>Our Core Values</Link></li>
                            <li><Link to={'/board-of-directors'}>Board of Directors</Link></li>
                            <li><Link to={'/key-executives'}>Key Executives</Link></li>
                        </ul>
                    </div>
                </Col>

                <Col className='footer-col' md={5}>
                    <div className="group">
                        <h4>Our Concerns</h4>
                        <ul className={'grid-list'}>


                            <li><Link to={'/star-particle-board-mill'}>Star Particle
                                Board Mills Limited</Link></li>
                            <li><Link to={'/concern/partex-builders-ltd'}>Partex
                                Builders Limited</Link></li>
                            <li><Link to={'/concern/partex-cables'}>Partex
                                Cables Limited</Link></li>
                            <li><Link to={'/concern/ashley-furniture'}>Partex Home Stores Limited (Ashley Furniture)</Link></li>
                            <li><Link
                                to={'/concern/partex-furniture-industries-ltd'}>Partex Furniture Industries
                                Limited</Link></li>

                            <li><Link to={'/concern/partex-agro-ltd'}>Partex Agro
                                Limited</Link></li>
                            <li><Link
                                to={'/concern/partex-gypsum-boards-mills-ltd'}>Partex Gypsum Boards Mills
                                Limited</Link></li>

                            <li><Link to={'/concern/cafe-de-partex'}>Café de
                                Partex</Link></li>
                            <li><Link to={'/concern/star-adhesives-ltd'}>Star
                                Adhesives Limited</Link></li>
                            <li><Link to={'/concern/partex-aeromarine'}>Partex
                                Aeromarine Logistics Limited (Bangladesh)</Link></li>
                            <li><Link to={'/concern/partex-laminates-limited'}>Partex Laminates Limited</Link></li>
                            <li><Link to={'/concern/partex-aeromarine-india'}>Partex
                                Aeromarine Logistics Limited (India)</Link></li>
                            <li><Link to={'/concern/triple-apparels-ltd'}>Triple
                                Apparels Limited</Link></li>
                            <li><Link to={'/sister-concerns'}>Sister Concerns</Link></li>



                        </ul>
                    </div>
                </Col>

                <Col className='footer-col' md={2}>
                    <div className="group">
                        <h4>Misc.</h4>
                        <ul>
                            <li><Link to={`/media-center?category=all`}>Media Center</Link></li>
                            <li><Link to={'/contact'}>Contact</Link></li>
                            <li><Link to={'/csr'}>CSR</Link></li>
                            <li><Link to={'/career'}>Career</Link></li>
                        </ul>
                    </div>
                </Col>

            </Row>
        </Container>

        <Container className='footer-large__bottom'>
            <Row>
                <Col md={4}>
                    <p>© {year} Partex Star Group. All Rights Reserved. </p>
                </Col>
                <Col md={4}>
                    <p><a href={'https://dcastalia.com/'} target={'_blank'}>Designed & Developed by Dcastalia</a></p>
                </Col>
            </Row>
        </Container>
    </StyledFooter>);
};
const StyledFooter = styled.section`
  background-color: #F5F5F5;
  @media (max-width: 991px) {
    display: none;
  }

  .footer-large__top {
    position: relative;
    padding-bottom: 80px;

    &:after {
      content: '';
      position: absolute;
      height: 1px;
      left: 15px;
      right: 15px;
      background-color: #C9C9C9;
      bottom: 0;
    }

    .bg-text-one, .bg-text-two {
      font-size: 240px;
      line-height: 240px;
      font-weight: 700;
      margin: 0;
      color: #E8E8E8;
      position: absolute;
      white-space: nowrap;


      @media (max-width: 767px) {
        font-size: 120px;
        line-height: 120px;
      }
    }

    .bg-text-one {
      right: -5%;
      bottom: 180px;

      @media (max-width: 767px) {
        bottom: 60px;
      }
    }

    .bg-text-two {
      left: -5%;
      bottom: -60px;
    }

    .group {
      margin-bottom: 0px;

      h4 {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: #C9C9C9;
        margin: 0 0 20px 0;
      }

      ul {
        li {
          margin: 0 0 20px 0;

          &:nth-last-child(1) {
            margin: 0px;
          }

          a {
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            color: #323232;
          }
        }
      }

      .grid-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0 30px;
      }
    }

    .logo {
      img {
        margin-bottom: 40px;
      }

      p {
        margin-bottom: 40px;
      }
    }

    .social-menus {
      .hover {
        &:after {
          height: 100%;
          width: 100%;
          left: -200%;
          background-color: ${hoverr};
        }

        &:hover {
          svg {
            color: #FFFFFF;
            transition: all 0.5s ease 0s;
          }

          &:after {
            left: 0 !important;
          }
        }
      }

      li {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: #E8E8E8;
        margin: 0 20px 0 0 !important;

        &:nth-last-child(1) {
          margin: 0 !important;
        }

        svg {
          color: #323232;
          font-size: 14px;
          transition: all 0.5s ease 0s;
        }

        a {
          position: relative;
          display: flex;
          z-index: 2;
          height: 100%;
          width: 100%;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .footer-large__bottom {
    padding-top: 20px;
    padding-bottom: 20px;

    .row {
      justify-content: space-between;

      .col-md-4 {
        margin-right: 67px;
      }
    }

    p {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      color: #C9C9C9;

      a {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: #C9C9C9;
      }
    }
  }

`;


export default FooterLarge;