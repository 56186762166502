import styled from "styled-components";
import {Accordion, Col, Container, Row} from "react-bootstrap";
import {BsChevronDown} from "react-icons/bs";
import {Link} from "react-router-dom";
import React, {useEffect, useRef,useState} from 'react';
import Select, {components} from "react-select";
import reactHtmlParser from "react-html-parser";
import Button from "../Button";
import CareerPopup from "./CareerPopUp";


const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        color: state.isSelected ? '#000' : '#221F1F',
        backgroundColor: state.isSelected ? '' : '#FFF',
        marginTop: 10,
        marginLeft: 2,
        fontSize: 20,

        paddingLeft: 25,
        overflowY: 'auto',
        // borderColor: state.isFocused ? '#FF0000' : '#CCCCCC',
    }),
    menu: (provided, state) => ({
        ...provided,
        color: '#FFF',
        backgroundColor: state.isSelected ? '' : '',
        margin: 0,
        borderRadius: 5,
        fontSize: 12,

    }),
    menuList: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#000' : '#FFF',
        borderRadius: 0,
        fontWeight: '500',
        color: '#FFF',
        fontSize: 20,
        paddingBottom: 25,

    }),


};

const Faq = ({data,subtile,title}) => {
    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em"
                         width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path>
                    </svg>
                </components.DropdownIndicator>
            )
        );
    };


    const selectTypeInputRef = useRef();
    const [itemsToShow, setItemsToShow] = useState(6);
    const [isCompleted, setIsCompleted] = useState(false)
    const [selectType, setSelectType] = useState('Corporate Office')
    const [selectedCategory, setSelectedCategory] = useState('Corporate Office');
    const [filteredData, setFilteredData] = useState(); // Initialize with all data
    const [activeItem, setActiveItem] = useState('Corporate Office'); // Set 'All' as the default active item


// useEffect(()=>{
//     setFilteredData(data)
// },[data])
    const handleItemClick = (item) => {
        setActiveItem(item);
        // Filter the data when an item is clicked
        filterData(item);
    };

    const handleType = (e) => {
        setSelectType(e);
        // Filter the data when the select dropdown changes
        filterData(e);
    };

    useEffect(() => {
        setActiveItem('Corporate Office');
    }, []);
    const handleCategoryChange = (selectedValue) => {
        setSelectedCategory(selectedValue);
    };


    const filterData = (filter) => {

            setFilteredData(data?.filter(item => item?.office_type === filter));
    };

    useEffect(() => {
        if (data) {
            filterData(selectType);
        }
    }, [data, selectType]);


    const options = [
        ...(Array.isArray(data)
            ? data?.map((item) => ({
                value: item?.office_type,
                label: item?.office_type,
            }))
            : [])
    ];
    // Filter out duplicate values based on the "value" property
    const uniqueTypes = Array.from(new Set(options?.map((option) => option.value)))?.map((value) => options.find((option) => option.value === value));



    const [show, setShow] = useState(false);
    const [winWidth, setWinWidth] = useState(true)
    const [popupId, setPopupId] = useState()
    const handleClose = () => setShow(false);
    const handleShow = (serviceTitle,serviceSubtitle) => {
        setSelectedServiceTitle(serviceTitle);
        setSelectedServiceSubTitle(serviceSubtitle);
        setShow(true);
    }
    const [selectedServiceTitle, setSelectedServiceTitle] = useState('');
    const [selectedServiceSubTitle, setSelectedServiceSubTitle] = useState('');

    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [])



    return (
        <>
        <StyledFaq className="job-lists pb-200">
            <Container>
            <Row className={'upper'}>
                <Col sm={12}>
                    <p className={' split-left'}>{reactHtmlParser(subtile)}</p>
                </Col>

                <Col md={9}>
                    <h3 className={' split-up'}>{reactHtmlParser(title)}</h3>
                </Col>
            </Row>

                <Row>
                    <Col sm={3} className='job-lists__sidebar'>

                        <div className="job-lists__sidebar__mobile-dropdown">
                            <Select  classNamePrefix="filter"
                                     isSearchable={false}
                                     ref={selectTypeInputRef}
                                     options={uniqueTypes}
                                     onChange={(e) => handleType(e?.value)}
                                     placeholder='Corporate Office' styles={customStyles}
                                     />
                        </div>


                        <ul>
                            {uniqueTypes.map((item, index) => (
                                <li
                                    key={index}
                                    className={`${activeItem === item?.value || (activeItem === 'Corporate Office' && item?.value === 'Corporate Office') ? 'active' : ''}`}
                                    onClick={() => handleItemClick(item?.value)}>
                                    {item?.value}
                                </li>
                            ))}

                        </ul>
                    </Col>

                    <Col sm={9} className='job-lists__content'>
                        <Accordion defaultActiveKey="1">
                            {filteredData?.map((item, index) => (
                            <Accordion.Item eventKey={index}>
                                <Accordion.Header>
                                    <h4>{item?.post_title}</h4>
                                    <span><BsChevronDown/></span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    {reactHtmlParser(item?.post_content)}
                                    <div className={'bottom-button'} onClick={() => handleShow(item?.post_title,item?.office_type)}>
                                        <Button text={'View Details'} fontSize={'20'}  height={'49'} width={'174'} color={'black'} background={'#F5F5F5'}/>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                                ))}
                        </Accordion>
                    </Col>
                </Row>
            </Container>

         </StyledFaq>
    <CareerPopup show={show} handleClose={handleClose} title={selectedServiceTitle} subtitle={selectedServiceSubTitle} />
    </>
    );
};

const StyledFaq = styled.section`
  position: relative;

  //@media (min-width: 1050px) and (max-width: 1366px) {
  //  padding-top: 50px !important;
  //}
  //@media (min-width: 1367px) and (max-width: 1900px) {
  //  padding-top: 150px !important;
  //}

  .upper {
    p {
      font-weight: 500;
      padding-bottom: 20px;
    }

    h3 {
      padding-bottom: 80px;
      @media (max-width: 767px) {
        padding-bottom: 40px;
      }
    }
  }

  .accordion-button {
    h4 {
      color: #E50019;
      transition: color 0.5s ease;
    }
  }

  .bottom-button {
    padding-top: 40px;
    padding-bottom: 40px;

    .dc-btn {
      a {
        font-weight: 500;
        line-height: 24px;
      }
    }
  }

  .collapsed {
    h4 {
      color: #323232;
      transition: color 0.5s ease;
    }
  }

  .job-lists__sidebar {
    ul{
      .active{
        color:#E50019;
      }
    }

    &__mobile-dropdown {
      @media (min-width: 551px) {
        display: none;
      }
    }


    ul {
      @media (max-width: 550px) {
        display: none;
      }
      li {
        cursor: pointer;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          border-radius: 50%;
          top: 0;
          bottom: 0;
          right: -15px;
          margin: auto;
          height: 10px;
          width: 10px;
          background-color: #E50019;
          display: none;
        }

        &.active {
          a {
            color: #E50019;

          }
        }
      }

      li:not(:last-child) {
        padding-bottom: 20px;
      }
    }
  }


  .job-lists__sidebar__mobile-dropdown {
    .css-oyvj4z-menu {

    }
  }

  .css-13cymwt-control, .css-t3ipsp-control {
    background-color: #E50019;
    height: 60px;
    border-radius: 50px;

    .css-1jqq78o-placeholder {
      color: white;
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      margin: 20px 0 20px 30px;
    }

    .css-1fdsijx-ValueContainer {
      padding: unset;
    }

    .css-1u9des2-indicatorSeparator {
      display: none;
    }

    .css-1xc3v61-indicatorContainer {
      padding-right: 30px;
    }

    .css-1dimb5e-singleValue {
      margin:20px 0px 20px 30px;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      color: #FFF;
    }
  }
  
  .filter__control{
    border: unset!important;
  }
.filter__dropdown-indicator{
  svg{
    path{
      color: #ffffff;
    }
  }
}

  .accordion-item {
    margin-bottom: 40px;
  }

  .accordion-header {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    position: relative;
    margin: 0;

    button {
      border: none;
      background-color: transparent;
      padding-left: 0;
      color: #E50019;
      font-size: 26px;
      font-weight: bold;
      line-height: 32px;
      text-transform: capitalize;
      padding-bottom: 15px;
      border-bottom: 1px solid #E50019;
      width: 100%;
      text-align: left;
      //margin-bottom: 50px;
      transition: all .4s ease;

      h4 {
        width: 80%;
      }

      span {
        top: unset;
        @media (max-width: 767px) {
          top: 20%;
        }
      }

      &.collapsed {
        color: #1A1818;
        border-color: #1A1818;

        span {
          background-color: black;

          svg {
            transform: rotate(0deg);
          }

          &:after {
            background-color: #E50019 !important;
          }
        }
      }
    }

    span {
      position: absolute;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      color: #ffffff;
      background-color: #E50019;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 16px;

      &:after {
        height: 0;
        width: 0;
        background-color: #E50019;
        border-radius: 50%;
        opacity: 0;
        transition: all .4s ease;
        content: '';
        position: absolute;
      }

      svg {
        font-size: 13px;
        z-index: 2;
        transform: rotate(180deg);
      }

    }

    &:hover {
      h4 {
        color: #E50019 !important;
      }

      span {
        &:after {
          height: 100%;
          width: 100%;
          opacity: 1;
        }
      }

      button {
        color: #E50019;
        border-color: #E50019;
      }
    }
  }


  .accordion-body {
    p, a {
      font-size: 20px;
      line-height: 24px;
      font-weight: 300;
    }

    .body-title {
      padding-top: 40px;
    }

    .body-title {
      font-weight: 500;
    }

    p {
      opacity: 80%;
      border-bottom: 1px solid #32323238;
      position: relative;
      margin-left: 20px;
      padding: 20px 0 15px 0;

      &:before {
        content: "";
        position: absolute;
        border-radius: 50%;
        right: -15px;
        height: 10px;
        width: 10px;
        margin-top: 3px;
        left: -20px;
        background-color: #E50019;
      }
    }

    h6 {
      font-size: 20px;
      line-height: 24px;
      font-weight: 300;
      padding-top: 20px;
    }
  }

  .css-t3ipsp-control {
    border-color: unset !important;
    box-shadow: unset !important;
  }

  .css-qbdosj-Input {
    input {
      min-width: 0px !important;
      opacity: 0 !important;
    }
  }

  .css-15lsz6c-indicatorContainer {
    padding-right: 30px;
    color: hsl(0, 0%, 80%);
  }


  .css-yk16xz-control, .css-1pahdxg-control {
    border-radius: 18px !important;
    border-color: #989898;
    height: 40px;
    background-color: #E50019;
    cursor: pointer;
    padding-left: 15px;
  }

  .css-yk16xz-control .css-1wa3eu0-placeholder, .css-1pahdxg-control .css-1wa3eu0-placeholder, .css-1uccc91-singleValue {
    color: #ffffff !important;
    font-size: 12px !important;
    font-weight: bold;
    cursor: pointer;
  }

  .css-yk16xz-control .css-tlfecz-indicatorContainer, .css-1pahdxg-control .css-tlfecz-indicatorContainer, .css-yk16xz-control .css-1gtu0rj-indicatorContainer, .css-1pahdxg-control .css-1gtu0rj-indicatorContainer {
    margin-right: 20px;
  }


  @media (max-width: 767px) {
    .job-lists__sidebar {
      margin-bottom: 80px;

      p {
        width: 100%;
      }
    }
  }

`;
export default Faq;
