import React, {useRef, useEffect, useState, useLayoutEffect} from 'react';
import {Container, Row, Col, Dropdown, DropdownButton, ListGroup} from "react-bootstrap";

import {gsap} from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';


import styled from "styled-components";
// Internal Component
import Overview from "./Overview";
import ProductGallery from "./ProductGallery";
import ProductVideo from "./ProductVideo";
import DoorsProducts from "./DoorsProducts";
import Cta from "../Cta";
import BoardProducts from "./BoardProducts";
import InteractiveGallery from "./InteractiveGallery";
import PdfDownloader from "./PdfDownloader";
import Address from "./Address";
import Quotation from "./Quotation";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useLocation} from "react-router-dom";
import DealerList from "../../pages/dealer-list";
import DealerListNew from "../DealerList/DealerListNew";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam as ApiParams} from "../../api/network/apiParams";
import {fetchDealerList} from "../../api/redux/DealerList";

function Navbar({data, dealerList,link, productType}) {

    const section1Ref = useRef(null);
    const section2Ref = useRef(null);
    const section3Ref = useRef(null);
    const section4Ref = useRef(null);
    const section5Ref = useRef(null);
    const section6Ref = useRef(null);
    const section7Ref = useRef(null);
    const section8Ref = useRef(null);
    const section9Ref = useRef(null);
    const location = useLocation();
    const dispatch = useDispatch();

    const pathParts = location.pathname.split('/');
    const lastPart = pathParts[pathParts.length - 1];
    const DealerList = dealerList?.filter((i) => i?.dealer_concern_slug === lastPart);



    const [activeItem, setActiveItem] = useState('overview');
    const [sectionName, SetSectionName] = useState('Overview');
    const scrollToSection = (sectionRef, item) => {
        const yOffset = -80;
        const y = sectionRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
        SetSectionName(item);
        setTimeout(() => {
            // Update the state after the section has been moved
            setActiveItem(item);
        }, 5);
    };

    const ActiveClass = (item) => {

    }

    const [offset, setOffset] = useState(100)


    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)

        window.addEventListener('load', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
    }, [])


// sticky navbar
    gsap.registerPlugin(ScrollTrigger);

    useLayoutEffect(() => {
        const col4Element = document.querySelector('.concern-nav');

        // Check if the screen width is greater than 1024px (desktop)
        if (window.innerWidth > 991) {
            gsap.to(col4Element, {
                scrollTrigger: {
                    trigger: col4Element,
                    start: 'top top',
                    end: 'max',
                    pin: true,
                    pinSpacing: false
                }
            });
        }

        return () => {
            ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
        };
    }, []);



    return (
        <>
            <StyledMediaTab className={'concern-nav'}>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className={'product-specification-head'}>
                                <ListGroup as={'ul'} horizontal className={'product-specification-ul'}>
                                    {data?.overview?.hide != 'on' &&
                                        <ListGroup.Item as="li" className={activeItem === 'overview' ? 'activee' : ''}>
                                            <button onClick={() => scrollToSection(section1Ref, 'overview')}>
                                                <span>Overview</span>
                                            </button>
                                        </ListGroup.Item>
                                    }
                                    {data?.feature?.hide != 'on' &&
                                        <ListGroup.Item as="li" className={activeItem === 'feature' ? 'activee' : ''}>
                                            <button onClick={() => {
                                                scrollToSection(section2Ref, 'feature')
                                            }}>
                                                <span>Features</span>
                                            </button>
                                        </ListGroup.Item>
                                    }
                                    {data?.product_one?.hide != 'on' &&
                                        <Link to={`/product-list?product_type=${productType}`}>
                                            <ListGroup.Item as="li"
                                                            className={activeItem === 'doorproducts' ? 'activee' : ''}>
                                                <button onClick={() => scrollToSection(section3Ref, 'doorproducts')}>
                                                    <span>Products</span>
                                                </button>
                                            </ListGroup.Item>
                                        </Link>
                                    }
                                    {data?.product_two?.hide != 'on' &&
                                        <ListGroup.Item as="li"
                                                        className={activeItem === 'boardproducts' ? 'activee' : ''}>
                                            <button onClick={() => scrollToSection(section4Ref, 'boardproducts')}>
                                                <span>Products</span>
                                            </button>
                                        </ListGroup.Item>
                                    }
                                    {data?.gallery?.hide != 'on' &&
                                        <ListGroup.Item as="li" className={activeItem === 'gallery' ? 'activee' : ''}>
                                            <button onClick={() => scrollToSection(section5Ref, 'gallery')}>
                                                <span>Gallery</span>
                                            </button>
                                        </ListGroup.Item>
                                    }
                                    {data?.dealer?.hide != 'on' &&
                                        <ListGroup.Item as="li" className={activeItem === 'dealers' ? 'activee' : ''}>
                                            <button onClick={() => scrollToSection(section7Ref, 'dealers')}>
                                                <span>Dealers</span>
                                            </button>
                                        </ListGroup.Item>
                                    }
                                    {DealerList?.length > 0 &&
                                        <ListGroup.Item as="li"
                                                        className={activeItem === 'dealer-list' ? 'activee' : ''}>
                                            <button onClick={() => scrollToSection(section9Ref, 'dealer-list')}>
                                                <span>Dealer List</span>
                                            </button>
                                        </ListGroup.Item>
                                    }
                                    {data?.catalog?.hide != 'on' &&
                                        <ListGroup.Item as="li" className={activeItem === 'catalogs' ? 'activee' : ''}>
                                            <button onClick={() => scrollToSection(section6Ref, 'catalogs')}>
                                                <span>Catalogs</span>
                                            </button>
                                        </ListGroup.Item>
                                    }


                                    {data?.contact?.hide != 'on' &&
                                        <ListGroup.Item as="li" className={activeItem === 'contact' ? 'activee' : ''}>
                                            <button onClick={() => scrollToSection(section8Ref, 'contact')}>
                                                <span>Contact</span>
                                            </button>
                                        </ListGroup.Item>
                                    }


                                </ListGroup>

                                <DropdownButton as={'ul'} className={'product-specification-head__mobile'}
                                                title={sectionName}>
                                    {data?.overview?.hide != 'on' &&
                                        <Dropdown.Item as={'li'} className={activeItem === 'Overview' ? 'activee' : ''}>
                                            <button onClick={() => scrollToSection(section1Ref, 'Overview')}>
                                                <span>Overview</span>
                                            </button>
                                        </Dropdown.Item>
                                    }
                                    {data?.feature?.hide != 'on' &&
                                        <Dropdown.Item as={'li'} className={activeItem === 'Features' ? 'activee' : ''}>
                                            <button onClick={() => scrollToSection(section2Ref, 'Features')}>
                                                <span>Features</span>
                                            </button>
                                        </Dropdown.Item>
                                    }
                                    {data?.product_one?.hide != 'on' &&
                                        <Dropdown.Item as={'li'}
                                                       className={activeItem === 'Door Products' ? 'activee' : ''}>
                                            <button onClick={() => scrollToSection(section3Ref, 'Door Products')}>
                                                <span>Products</span>
                                            </button>
                                        </Dropdown.Item>
                                    }
                                    {data?.product_two?.hide != 'on' &&
                                        <Dropdown.Item as={'li'}
                                                       className={activeItem === 'Board Products' ? 'activee' : ''}>
                                            <button onClick={() => scrollToSection(section4Ref, 'Board Products')}>
                                                <span>Products</span>
                                            </button>
                                        </Dropdown.Item>
                                    }
                                    {data?.gallery?.hide != 'on' &&
                                        <Dropdown.Item as={'li'} className={activeItem === 'Gallery' ? 'active' : ''}>
                                            <button onClick={() => scrollToSection(section5Ref, 'Gallery')}>
                                                <span>Gallery</span>
                                            </button>
                                        </Dropdown.Item>
                                    }
                                    {DealerList?.length > 0 &&
                                        <Dropdown.Item as={'li'}
                                                       className={activeItem === 'dealer-list' ? 'activee' : ''}>
                                            <button onClick={() => scrollToSection(section8Ref, 'dealer-list')}>
                                                <span>Dealer List</span>
                                            </button>
                                        </Dropdown.Item>
                                    }
                                    {data?.catalog?.hide != 'on' &&
                                        <Dropdown.Item as={'li'} className={activeItem === 'Catalogs' ? 'activee' : ''}>
                                            <button onClick={() => scrollToSection(section6Ref, 'Catalogs')}>
                                                <span>Catalogs</span>
                                            </button>
                                        </Dropdown.Item>
                                    }

                                    {data?.dealer?.hide != 'on' &&
                                        <Dropdown.Item as={'li'} className={activeItem === 'Dealers' ? 'activee' : ''}>
                                            <button onClick={() => scrollToSection(section7Ref, 'Dealers')}>
                                                <span>Dealers</span>
                                            </button>
                                        </Dropdown.Item>
                                    }
                                    {data?.contact?.hide != 'on' &&
                                        <Dropdown.Item as={'li'} className={activeItem === 'Contact' ? 'activee' : ''}>
                                            <button onClick={() => scrollToSection(section8Ref, 'Contact')}>
                                                <span>Contact</span>
                                            </button>
                                        </Dropdown.Item>
                                    }

                                </DropdownButton>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </StyledMediaTab>

            <StyledMediaList className={''}>
                <>
                    {data?.overview?.hide != 'on' &&
                        <div ref={section1Ref}>
                            <Overview link={link} data={data?.overview}/>
                        </div>
                    }
                    {data?.feature?.hide != 'on' &&
                        <div ref={section2Ref}>
                            <ProductGallery data={data?.feature}/>
                        </div>
                    }
                    {data?.video?.hide != 'on' &&
                        <ProductVideo data={data?.video}/>
                    }
                    {data?.product_one?.hide != 'on' &&

                        <div ref={section3Ref}>
                            <DoorsProducts data={data?.product_one}/>
                        </div>

                    }
                    {data?.quotation?.hide != 'on' &&
                        <Cta popup
                             imgSrc={data?.quotation?.background}
                             subTitle={data?.quotation?.title}
                             desc={data?.quotation?.description}
                             btntxt={'Get Quotation'}
                             btnColor={'#323232'}
                             btnBg={'#E8E8E8'}
                        />
                    }
                    {data?.product_two?.hide != 'on' &&
                        <div ref={section4Ref}>
                            <BoardProducts data={data?.product_two}/>
                        </div>
                    }
                    {data?.quotes?.hide != 'on' &&
                        <Quotation data={data?.quotes}/>
                    }
                    {DealerList &&
                        <div ref={section9Ref}>
                            <DealerListNew data={DealerList}/>
                        </div>
                    }

                    {data?.gallery?.hide != 'on' &&
                        <div ref={section5Ref}>
                            <InteractiveGallery offset={offset} data={data?.gallery}/>
                        </div>
                    }
                    {data?.catalog?.hide != 'on' &&
                        <div ref={section6Ref}>
                            <PdfDownloader data={data?.catalog}/>
                        </div>
                    }
                    {data?.dealer?.hide != 'on' &&
                        <div ref={section7Ref}>
                            <Cta
                                imgSrc={data?.dealer?.background}
                                subTitle={data?.dealer?.title}
                                desc={data?.dealer?.description}
                                btntxt={'Find a Dealer Near You'}
                                src={'/dealer-list'}
                                btnColor={'#323232'}
                                btnBg={'#E8E8E8'}
                            />
                        </div>
                    }
                    {data?.contact?.hide != 'on' &&
                        <div ref={section8Ref}>
                            <Address data={data?.contact} contact_person={data?.contact_person}
                                     contact_office={data?.contact_office}/>
                        </div>
                    }
                </>
            </StyledMediaList>
        </>
    );
}


const StyledMediaTab = styled.section`
  background: #323232;
  z-index: 100;

  .product-specification-head {
    .product-specification-ul {
      border-radius: 0;

      .list-group-item {
        padding: 0;
        border: 0;
        background-color: transparent;
        border-radius: 0;

        &.activee {
          button, span {
            color: #FFFFFF;
          }
        }

        button {
          font-size: 20px;
          line-height: 24px;
          font-weight: 500;
          padding: 20px 25px;
          display: flex;
          color: rgba(255, 255, 255, 0.5);
          height: 100%;
          background-color: transparent;
          border: 0;

          &:hover {
            color: #FFFFFF;

            span {
              color: #FFFFFF;
            }
          }
        }
        

        &:first-child {
          button {
            padding-left: 0;
          }
        }
      }
      a{
        .list-group-item{
          button{
            padding-left: 15px!important;
          }
        }
      }

    }

    &__mobile {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .col-sm-12 {
      padding: 0;
    }

    .product-specification-head {
      .product-specification-ul {
        display: none;
      }

      .dropdown {
        .active {
          button {
            background-color: #98223C;
          }
        }
      }

      &__mobile {
        display: block;

        .dropdown-toggle {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px 15px;
          border-radius: 0;
          background-color: #323232;
          border: 0;
          position: relative;

          &:after {
            display: none;
          }

          &:before {
            content: '';
            background-image: url('/images/static/drop.svg');
            position: absolute;
            right: 15px;
            height: 10px;
            width: 12px;
            top: 50%;
            background-repeat: no-repeat;
            transform: translateY(-5px) rotate(0deg);
          }

          &:active {
            background-color: transparent;
            border: 0;

            &:before {
              transform: translateY(-10px) rotate(180deg);
            }
          }
        }

        &-menu {
          width: 100%;
        }

        .dropdown-item {
          padding: 0;

          &:nth-child(1) {
            button {
              padding-top: 20px;
            }
          }

          &.active {
            background-color: rgba(50, 50, 50, 0.5);

            button {
              color: #ffffff;

              span {
                color: #ffffff;
              }
            }
          }

          &:active {
            background-color: transparent;
          }

          button {
            width: 100%;
            display: block;
            padding: 10px 15px;
            border: 0;
            text-align: left;

            &:before {
              content: unset;
            }
          }
        }

        .dropdown-menu {
          width: 100%;
          padding: 0;
          margin: 0;
          border-radius: 0;
        }
      }
    }
  }
  
`;

const StyledMediaList = styled.section`
    .sustainability-text{
      margin-bottom: 200px;
      @media(max-width: 767px){
        margin-bottom: 150px;
      }
    }
`;

export default Navbar;

