import React, {useState} from 'react';
import styled from "styled-components";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Col, Container, Row, Card} from "react-bootstrap";
import Title from "./Title";
import Accordion from "react-bootstrap/Accordion";
import {text, title, hoverr} from "../styles/globalStyleVars";
import {Link} from "react-router-dom";
import {FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter, FaYoutube} from "react-icons/fa";

const FeaturesBenefits = ({data}) => {
    const d = new Date();
    let year = d.getFullYear();

    const [application, setApplication] = useState('All Season');
    const [activeKey, setActiveKey] = useState(0);

    const handleSelect = (selectedKey) => {
        setActiveKey(selectedKey === activeKey ? 0 : selectedKey);
    };

    return (
        <StyledComponent className='footer-small pt-120 pb-40'>
            <Container>
                <Row className={'footer-small-row'}>
                    <p className={'opacity-30 bg-text-one'}>PARTEX</p>
                    <p className={'opacity-30 bg-text-two'}>STAR GROUP</p>

                    <Col md={12} className="footer-small__top">
                        <div className="group logo">
                            <img src={'/images/static/footer-logo.svg'}/>

                            <p>Partex Star Group is one of the largest Bangladeshi diversified private sector
                                enterprises.</p>

                            <ul className="social-menus d-flex">
                                <li className='hover' target="_blank"><a href={'https://www.facebook.com/partexstargroup/'} target={"_blank"}><FaFacebookF/></a>
                                </li>
                                <li className='hover' target="_blank"><a href={'https://x.com/partex_star'} target={"_blank"}><FaTwitter/></a>
                                </li>
                                <li className='hover' target="_blank"><a href={'https://www.instagram.com/partexstargroup/'} target={"_blank"}><FaInstagram/></a>
                                </li>
                                <li className='hover' target="_blank"><a href={'https://www.linkedin.com/company/3046430/admin/feed/posts/'} target={"_blank"}><FaLinkedinIn/></a>
                                </li>
                                <li className='hover' target="_blank"><a
                                    href={'https://www.youtube.com/@partexstargroup7699'} target={"_blank"}><FaYoutube/></a></li>
                            </ul>
                        </div>
                    </Col>

                    <Col md={12} className={'pr-0'}>
                        <div className="footer_wrapper">

                            <Accordion>
                                <Accordion.Item eventKey={0} key={0}>
                                    <Accordion.Header>
                                        <div className="main">
                                            {'Who We Are'}
                                        </div>

                                        <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                      <g id="Group_16492" data-name="Group 16492" transform="translate(15411 -8568)">
                                        <circle id="Ellipse_1" data-name="Ellipse 1" cx="10" cy="10" r="10"
                                                transform="translate(-15411 8588) rotate(-90)" fill="#e8e8e8"/>
                                        <g id="Group_16489" data-name="Group 16489" transform="translate(15.5)">
                                          <line id="Line_3973" data-name="Line 3973" x2="3" y2="3"
                                                transform="translate(-15419.5 8576.5)" fill="none" stroke="#323232"
                                                stroke-linecap="round" stroke-width="1"/>
                                          <line id="Line_3974" data-name="Line 3974" x1="3" y2="3"
                                                transform="translate(-15416.5 8576.5)" fill="none" stroke="#323232"
                                                stroke-linecap="round" stroke-width="1"/>
                                        </g>
                                      </g>
                                    </svg>
                                  </span>
                                    </Accordion.Header>

                                    <Accordion.Body>
                                        <ul>
                                            <li><Link to={'/about'}>Partex Star Group</Link></li>
                                            <li><Link to={'/about#heritage'}>Our Heritage</Link></li>
                                            <li><Link to={'/about#mission-vision'}>Our Mission and Vision</Link></li>
                                            <li><Link to={'/about#core-value'}>Our Core Values</Link></li>
                                            <li><Link to={'/board-of-directors'}>Board of Directors</Link></li>
                                            <li><Link to={'/key-executives'}>Key Executives</Link></li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey={1} key={1}>
                                    <Accordion.Header>
                                        <div className="main">
                                            {'Our Concerns'}
                                        </div>
                                        <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                      <g id="Group_16492" data-name="Group 16492" transform="translate(15411 -8568)">
                                        <circle id="Ellipse_1" data-name="Ellipse 1" cx="10" cy="10" r="10"
                                                transform="translate(-15411 8588) rotate(-90)" fill="#e8e8e8"/>
                                        <g id="Group_16489" data-name="Group 16489" transform="translate(15.5)">
                                          <line id="Line_3973" data-name="Line 3973" x2="3" y2="3"
                                                transform="translate(-15419.5 8576.5)" fill="none" stroke="#323232"
                                                stroke-linecap="round" stroke-width="1"/>
                                          <line id="Line_3974" data-name="Line 3974" x1="3" y2="3"
                                                transform="translate(-15416.5 8576.5)" fill="none" stroke="#323232"
                                                stroke-linecap="round" stroke-width="1"/>
                                        </g>
                                      </g>
                                    </svg>
                                  </span>
                                    </Accordion.Header>

                                    <Accordion.Body>
                                        <ul>
                                            <li><Link to={'/star-particle-board-mill'}>Star Particle Board Mills Limited</Link></li>
                                            <li><Link to={'/concern/partex-cables'}>Partex Cables Limited</Link></li>
                                            <li><Link to={'/concern/partex-furniture-industries-ltd'}>Partex Furniture Industries Limited</Link></li>
                                            <li><Link to={'/concern/partex-gypsum-boards-mills-ltd'}>Partex Gypsum Boards Mills Limited</Link></li>
                                            <li><Link to={'/concern/star-adhesives-ltd'}>Star Adhesives Limited.</Link></li>
                                            <li><Link to={'/concern/partex-laminates-limited'}>Partex Laminates Limited</Link></li>
                                            <li><Link to={'/concern/triple-apparels-ltd'}>Triple Apparels Limited</Link></li>
                                            <li><Link to={'/concern/partex-builders-ltd'}>Partex Builders Limited</Link></li>
                                            <li><Link to={'/concern/ashley-furniture'}>Partex Home Stores Limited (Ashley Furniture)</Link></li>
                                            <li><Link to={'/concern/partex-agro-ltd'}>Partex Agro Limited</Link></li>
                                            <li><Link to={'/concern/cafe-de-partex'}>Café de Partex</Link></li>
                                            <li><Link to={'/concern/partex-aeromarine'}>Partex Aeromarine Logistics Limited</Link></li>
                                            <li><Link to={'/sister-concerns'}>Sister Concerns</Link></li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey={2} key={2}>
                                    <Accordion.Header>
                                        <div className="main">
                                            <Link to={'/contact'}>Contact</Link>
                                        </div>
                                    </Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey={3} key={3}>
                                    <Accordion.Header>
                                        <div className="main">
                                            <Link to={'/csr'}>CSR</Link>
                                        </div>
                                    </Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey={4} key={4}>
                                    <Accordion.Header>
                                        <div className="main">
                                            <Link to={'/career'}>Career</Link>
                                        </div>
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Accordion>

                        </div>
                    </Col>

                    <Col md={12} className="footer-small__bottom">
                        <p>© {year} Partex Star Group. All Rights Reserved. </p>
                        <p><a href={'https://dcastalia.com/'} target={'_blank'}>Designed & Developed by Dcastalia</a></p>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  background-color: #F5F5F5;
  @media (min-width: 992px) {
    display: none;
  }

  .footer-small-row {
    position: relative;


    .bg-text-one, .bg-text-two {
      font-size: 72px;
      line-height: 72px;
      font-weight: 700;
      margin: 0;
      color: #E8E8E8;
      position: absolute;
      white-space: nowrap;
    }

    .bg-text-one {
      right: -5%;
      bottom: 132px;
    }

    .bg-text-two {
      left: -5%;
      bottom: 60px;
    }
  }

  .footer-small__top {
    .group {
      margin-bottom: 0px;

      p {
        font-size: 20px;
        line-height: 24px;
        font-weight: 300;
        color: #000020;
        margin: 0 0 40px 0;
      }

      ul {
        margin: 0 0 80px 0;

        li {
          margin: 0 0 20px 0;

          &:nth-last-child(1) {
            margin: 0px;
          }

          a {
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            color: #323232;
          }
        }
      }

      .grid-list {
        display: unset;
      }
    }

    .logo {
      img {
        margin-bottom: 40px;
      }
    }

    .social-menus {
      .hover {
        &:after {
          height: 100%;
          width: 100%;
          left: -200%;
          background-color: ${hoverr};
        }

        &:hover {
          svg {
            color: #FFFFFF;
            transition: all 0.5s ease 0s;
          }

          &:after {
            left: 0 !important;
          }
        }
      }

      li {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: #E8E8E8;
        margin: 0 20px 0 0 !important;

        &:nth-last-child(1) {
          margin: 0 !important;
        }

        svg {
          color: #323232;
          font-size: 14px;
          transition: all 0.5s ease 0s;
        }

        a {
          position: relative;
          display: flex;
          z-index: 2;
          height: 100%;
          width: 100%;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .footer_wrapper {

    .accordion {
      padding-bottom: 60px;
    }

    .accordion-button:not(.collapsed) {
      color: #E50019;
      border-bottom: 1px solid #E50019;

      svg {
        transform: rotate(180deg);
        transition: all 0.25s ease-in-out;

        circle {
          fill: #E50019;
          transition: all 0.25s ease-in-out;
        }

        line {
          stroke: #FFFFFF;
          transition: all 0.25s ease-in-out;
        }
      }
    }

    .accordion-body {
      ul {
        li {
          cursor: pointer;
          font-size: 20px;
          line-height: 24px;
          font-weight: 400;
          color: #323232;
          padding: 20px 0;
          margin: 0px;
          border-bottom: 1px solid #C9C9C9;

          &:last-child {
            margin-bottom: 0;
            border: 0;
          }
        }
      }
    }

    .accordion-header {
      margin: 0;

      button {
        border: none;
        background: transparent;
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
        color: rgb(50, 50, 50);
        padding: 20px 0;
        position: relative;
        display: flex;
        width: 100%;
        justify-content: space-between;
        text-align: left;
        border-bottom: 1px solid #C9C9C9;

        .main {
          padding-right: 55px;

          a {
            font-size: 20px;
            line-height: 24px;
            font-weight: 400;
            color: rgb(50, 50, 50);
          }
        }

        span {
          transition: all 0.7s cubic-bezier(0.87, 0, 0.13, 1);
        }

      }
    }

    .accordion-item {
      margin: 0;
      padding: 0;
      border: 0;

      &:nth-child(1) {
        .accordion-header {
          button {
            padding-top: 0;
          }
        }
      }

      &:nth-last-child(1) {
        .accordion-header {
          button {
            border: 0;
          }
        }
      }

      .accordion-button {
        svg {
          transition: all 0.25s ease-in-out;

          circle {
            transition: all 0.25s ease-in-out;
          }

          line {
            transition: all 0.25s ease-in-out;
          }
        }
      }
    }
  }

  .footer-small__bottom {
    position: relative;
    padding-top: 40px;

    &:after {
      content: '';
      position: absolute;
      height: 1px;
      left: 15px;
      right: 15px;
      background-color: #C9C9C9;
      top: 0;
    }

    p {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      color: #C9C9C9;

      &:nth-child(1) {
        margin-bottom: 10px;
      }

      a {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: #C9C9C9;
      }
    }
  }


  @media (max-width: 767px) {
    .pr-0 {
      padding-right: 15px !important;
    }
  }
`

export default FeaturesBenefits;