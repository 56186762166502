import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link, Route} from "react-router-dom";

import {HelmetProvider, Helmet} from 'react-helmet-async';
import InnerBanner from "../../components/InnerBanner";
import Overview from "../../components/Career/Overview";
import LifePartex from "../../components/Career/LifePartex";
import JobOpening from "../../components/Career/JobOpening";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchCsr} from "../../api/redux/Csr";
import {fetchCareer} from "../../api/redux/Career";

const MyComponent = ({offset}) => {

    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.CAREER
        dispath(fetchCareer([api_url]))
    }, [])

    let getPost = useSelector(state => state.career);

    const page_title = getPost?.posts?.[0]?.post_title;
    const banner = getPost?.posts?.[0]?.banner;
    const career = getPost?.posts?.[0]?.overview;
    const careerPost = getPost?.posts?.[0]?.career_post;
    const slider = getPost?.posts?.[0]?.slider;
    const accordion = getPost?.posts?.[0]?.list;
    const accordionfilter = getPost?.posts?.[0]?.filter;

    // Define the images for different viewports
    const largeImage = banner?.image;
    const mediumImage = banner?.image_tab;
    const smallImage = banner?.image_mobile;

    // Determine the viewport size and set the image accordingly
    let imgUrl;
    if (window.innerWidth >= 992) {
        imgUrl = largeImage;
    } else if (window.innerWidth >= 768) {
        imgUrl = mediumImage;
    } else {
        imgUrl = smallImage;
    }

    let data = getPost?.posts?.[0];
    let innerBanner = data?.banner?.image;

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${data?.meta?.meta_title ? data?.meta?.meta_title : 'Partex Star Group'}`}</title>
                {
                    data?.meta?.meta_desc &&
                    <meta name="description" content={data?.meta?.meta_desc}/>

                }
                {
                    data?.meta?.og_title &&
                    <meta property="og:title" content={data?.meta?.og_title}/>

                }
                {
                    data?.meta?.og_desc &&
                    <meta property="og:description" content={data?.meta?.og_desc}/>
                }
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={innerBanner?.image}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>

                <InnerBanner
                    img={imgUrl}
                    breadcrumbs={banner?.breadcrumbs}
                    title={banner?.title}
                    subtitle={banner?.description}
                    text={banner?.text}
                />
                <Overview bgText={career?.back_text} desLeft={career?.desc_left} desRight={career?.desc_right} des={career?.description} subTitle={career?.subtitle} title={career?.title} titleLeft={career?.title_left} titleRight={career?.title_right} bgTextone={career?.back_text_one} bgTexttwo={career?.back_text_two}/>
                {slider?.slider?.length > 0 && <LifePartex data={slider?.slider} title={slider?.title}/>}
                {accordion?.length > 0 && <JobOpening data={accordion} datafilter={accordionfilter} subtile={careerPost?.subtitle} title={careerPost?.title}/>}

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
