import React, { useState, useEffect, useRef } from 'react';
// import DefaultBanner from '../../public/images/static/dummy.jpg';
import styled from 'styled-components';
import { gsap } from 'gsap';

export const ImgParallax = ({
                        src,
                        position,
                        objectFit,
                        height,
                        width,
                        banner,
                        alt,
                        left,
                        margin,
                        right,
                        top,
                        bottom,
                        speed
                    }) => {


    return (
        <StyledImg
            className="global-image global-image-parallax"
            objectFit={objectFit}
            margin={margin}
            position={position}
            left={left}
            right={right}
            top={top}
            bottom={bottom}
            height={height}
            width={width}
            speed={speed}
        >
            <img
                src={src}
                alt={alt || ''}
            />
        </StyledImg>
    );
};


const StyledImg = styled.div`
  position: ${(props) => props.position || 'absolute'};
  height: ${(props) => props.height || '100%'};
  width: ${(props) => props.width || '100%'};
  top: ${(props) => props.top || 0};
  left: ${(props) => props.left || 0};
  bottom: ${(props) => props.bottom || 0};
  right: ${(props) => props.right || 0};
  margin: ${(props) => props.margin || 0};
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: ${(props) => props.objectFit || 'cover'};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}`;