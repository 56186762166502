import React from 'react';
import styled from "styled-components";
import Img from "./Img";
import {Col, Container, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";

import {ImgParallax} from "./ImgParallax";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ImgParallaxTwo} from "./ImgParallaxTwo";

const InnerBannerTwo = ({img, breadcrumbs, title, subtitle, text}) => {

    return (
        <StyledInnerBanner className='InnerBanner2 reveal-up-inner'>
            <ImgParallaxTwo speed={15} banner={true} src={img ? img : 'images/static/blur.jpg'}/>
            <Container className={'reveal-up-inner'}>
                <Row>
                    <Col lg={{ span: 6, offset: 6 }}>
                        {/*{breadcrumbs && <p className={'breadcrumbs'}>{reactHtmlParser(breadcrumbs)}</p>}*/}
                        {title && <h1 className={'split-up-innerbanner'}>{reactHtmlParser(title)}</h1>}
                    </Col>

                    <Col lg={'5'}>
                        {text && <h3 className={'split-up-innerbanner'}>{reactHtmlParser(text)}</h3>}
                    </Col>

                    <Col lg={{ span: 4, offset: 1 }}>
                        {subtitle && <p className={'sub-title split-up-innerbanner'}>{reactHtmlParser(subtitle)}</p>}
                    </Col>
                </Row>
            </Container>
        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
  //padding-top: calc(1056 / 1366 * 100%);
  height: 120vh;
  position: relative;
  //height: 100vh;
  background-color: #FFFFFF;
  overflow: hidden;

  .container {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 200px;
    z-index: 1;
  }

  .row {
    height: 100%;
    align-content: flex-end;
    //padding-top: 100px;
  }

  h1 {
    font-size: 80px;
    line-height: 80px;
    letter-spacing: -1.6px;
    font-weight: 500;
    color: #ffffff;
    margin: 0 0 80px 0;

    span {
      font-weight: 600;
      color: #ffffff;
    }
  }

  .breadcrumbs {
    font-weight: 500;
    color: #ffffff;
    margin: 0 0 20px 0;
  }

  .sub-title {
    color: #ffffff;
  }

  h3 {
    font-size: 28px;
    line-height: 32px;
    font-weight: 500;
    color: #ffffff;
  }

  @media (max-width: 991px) {
    min-height: 100%;
    height: 100%;

    .container {
      padding-top:270px;
      padding-bottom: 150px;
      position: unset;
    }

    h1 {
      font-size: 60px;
      line-height: 60px;
      letter-spacing: -1.2px;
      margin: 0 0 40px 0;
    }

    h3 {
      margin: 0 0 40px 0;
    }
  }
`;

export default InnerBannerTwo;
