import React, {useEffect} from 'react';
import styled from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";
import ChairPersonBio from "../../components/BoardOfDirectors/ChairPersonBio";
import InnerBanner from "../../components/InnerBanner";
import DirectorsGallery from "../../components/BoardOfDirectors/DirectorsGallery";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchBod} from "../../api/redux/Bod";
import LeadersOfPartex from "../../components/BoardOfDirectors/LeadersOfPartex";
const BoardDirectors = ({offset}) => {


    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.BOARDOFDIRECTORS
        dispath(fetchBod([api_url]))
    }, [])

    let getPost = useSelector(state => state.bod); //store
    const page_title = getPost?.posts?.page_data?.[0]?.post_title;
    const chairperson = getPost?.posts?.page_data?.[0]?.chairperson;
    const banner = getPost?.posts?.page_data?.[0]?.banner;
    const leaderSlider = getPost?.posts?.page_data?.[0]?.leader;
    const datademo = getPost?.posts?.page_data;
    const directorsOne = getPost?.posts?.page_data?.[0]?.leader?.leader_slider?.[0];
    const directorsTwo = getPost?.posts?.page_data?.[0]?.leader?.leader_slider?.[1];


    // Define the images for different viewports
    const largeImage = banner?.image;
    const mediumImage = banner?.image_tab;
    const smallImage = banner?.image_mobile;

    // Determine the viewport size and set the image accordingly
    let imgUrl;
    if (window.innerWidth >= 992) {
        imgUrl = largeImage;
    } else if (window.innerWidth >= 768) {
        imgUrl = mediumImage;
    } else {
        imgUrl = smallImage;
    }

    let data = getPost?.posts?.page_data?.[0];
    let innerBanner = data?.banner?.image;

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${data?.meta?.meta_title ? data?.meta?.meta_title : 'Partex Star Group'}`}</title>
                {
                    data?.meta?.meta_desc &&
                    <meta name="description" content={data?.meta?.meta_desc}/>

                }
                {
                    data?.meta?.og_title &&
                    <meta property="og:title" content={data?.meta?.og_title}/>

                }
                {
                    data?.meta?.og_desc &&
                    <meta property="og:description" content={data?.meta?.og_desc}/>
                }
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={innerBanner?.image}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>
                <InnerBanner
                    img={imgUrl}
                    breadcrumbs={banner?.breadcrumbs}
                    title={banner?.title}
                    subtitle={banner?.description}
                    text={banner?.text}
                />

             <ChairPersonBio des={chairperson?.description} designation={chairperson?.designation} img={chairperson?.image} name={chairperson?.name}/>
                {/*{leaderSlider?.leader_slider && <DirectorsGallery data={leaderSlider?.leader_slider} title={leaderSlider?.title}/> }*/}
                <LeadersOfPartex zigzag des={directorsOne?.description} designation={directorsOne?.person_designation} img={directorsOne?.image} name={directorsOne?.person_name}/>
               <LeadersOfPartex des={directorsTwo?.description} designation={directorsTwo?.person_designation} padding img={directorsTwo?.image} name={directorsTwo?.person_name}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
position: relative;
`;

export default BoardDirectors;
