import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";

const Overview = ({desLeft,desRight,des,subTitle,title,titleLeft,titleRight,bgTextone,bgTexttwo}) => {
    return (
        <StyledComponent  className='about-partex pt-200 pb-200  parallax-img horizontal-scroll'data-speed-right={-30} data-speed-left={30}>
            <Container >
                <Row>
                    {/*<Img src={'images/static/map.svg'}/>*/}
                        <p className={'scroll-right  opacity-30 bg-text-one'}>{bgTextone}</p>
                        <p className={'scroll-left  opacity-30 bg-text-two'}>{bgTexttwo}</p>

                    <Col sm={12}>
                        {subTitle && <p className={'upper-title split-left'}>{reactHtmlParser(subTitle)}</p>}
                    </Col>

                    <Col md={9}>
                        {title && <h3 className={' split-up'}>{reactHtmlParser(title)}</h3>}
                    </Col>

                    <Col md={{span:10, offset:2}}>
                        {des && <p>{reactHtmlParser(des)}</p>}
                    </Col>

                    <Col md={{span:10, offset:2}}>
                        <Row>
                            <Col md={{span:5}} className={'sub-col'}>
                                {titleLeft && <p className={'upper-pera split-left'}>{reactHtmlParser(titleLeft)}</p>}
                                {desLeft && <p className={'lower-para'}>{reactHtmlParser(desLeft)}</p>}
                            </Col>

                            <Col md={{span:5, offset:1}}>
                                {titleRight && <p className={'upper-pera split-left'}>{reactHtmlParser(titleRight)}</p>}
                                {desRight && <p className={'lower-para'}>{reactHtmlParser(desRight)}</p>}
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: #E4E4E4;
  
  .row {
    position: relative;
  }
  
  .bg-text-one, .bg-text-two {
    font-size: 240px;
    line-height: 240px;
    font-weight: 700;
    margin: 0;
    color: #fff;
    position: absolute;
    white-space: nowrap;


    @media (max-width: 767px) {
      font-size: 120px;
      line-height: 120px;
    }
  }
  
  .bg-text-one {
    right: -15%;
    bottom: 240px;

    @media (max-width: 767px) {
      bottom: 120px;
    }
  }
  
  .bg-text-two {
    left: -10%;
    bottom: 0;
  }

  .upper-title {
    width: 50%;
    margin: 0 0 20px 0;
  }
  
  h3 {
    margin: 0 0 80px 0;
    
    span {
      color: #E50019;
    }
  }

  p {
    margin: 0 0 80px 0;
  }
  
  .upper-pera {
    font-weight: 500;
    margin: 0 0 20px 0;
  }
  
  .lower-para {
    margin: 0;
  }

  @media (max-width: 767px) {
    .upper-title {
      width: 100%;
    }
    
    h3, p, .sub-col {
      margin: 0 0 40px 0;
    }
  }
  
`;

export default Overview;
