import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { Img } from '../Img';
import { hoverr } from '../../styles/globalStyleVars';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import PopUp from './KeyPopUp';
import SimpleBar from 'simplebar-react';
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {gsap,CSSPlugin,Power4} from "gsap";

import { SplitText } from 'gsap/SplitText';
import reactHtmlParser from "react-html-parser";

gsap.registerPlugin(CSSPlugin);
const SliderShowRoom = ({ data }) => {
    const [winWidth, setWinWidth] = useState(true);


    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true);
        } else {
            setWinWidth(false);
        }
        window.addEventListener('resize', () => {
            if (window.innerWidth > 767) {
                setWinWidth(true);
            } else {
                setWinWidth(false);
            }
        });
    }, []);




    return (
        <>
            <StyledProfile className="directors-list pt-200 pb-200">
                <Container>
                    <Row>
                        {data?.map((item, index) => (
                            <Col className={'directors-list__single'} key={index} md={4}>
                                <div className="directors-list__single__img">
                                    <Img src={item?.image ? item?.image : 'images/static/blur.jpg'} alt="" />
                                </div>
                                <div className="directors-list__single__content">
                                    {item?.person_name && <h5>{reactHtmlParser(item?.person_name)}</h5>}
                                    {item?.person_designation && <p>{reactHtmlParser(item?.person_designation)}</p>}
                                    {item?.person_company && <p>{reactHtmlParser(item?.person_company)}</p>}
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </StyledProfile>
        </>
    );
};


const StyledProfile = styled.section`

  .directors-list__single {
    padding-bottom: 60px;
    
    &:nth-last-child(1) {
      padding-bottom: 0;
    }
    
    &:nth-last-child(2) {
      padding-bottom: 0;
    }

    &:nth-last-child(3) {
      padding-bottom: 0;
    }

    &__img {
      padding-top: calc(370 / 370 * 100%);
      position: relative;
      overflow: hidden;

      img {
        transform: scale(1.01);
        transition: transform 1s ease;
      }
    }

    &__content {
      margin: 30px 0 0 0;

      h5 {
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        margin: 0 0 5px 0;
        transition: color .3s ease;
      }

      p {
        font-size: 16px;
        line-height: 20px;
        font-weight: 300;
        transition: color .3s ease;
      }
    }

    &:hover {
      .directors-list__single__img img {
        transform: scale(1.06);
      }

      .directors-list__single__content {
        h5 {
          color: ${hoverr};
        }
      }
    }

  }

  @media (max-width: 767px) {
    padding-top: 150px;
    
    .directors-list__single {
      &:nth-last-child(2) {
        padding-bottom: 60px;
      }

      &:nth-last-child(3) {
        padding-bottom: 60px;
      }
    }


  }

`;
export default SliderShowRoom;
