import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import reactHtmlParser from "react-html-parser";

const Chairperson = ({data}) => {
    return (
        <StyledComponent className='chairperson pt-200 pb-200'>
            <Container>
                <Row>
                    <Col lg={4} className={'img-col'}>
                        <img className={'comma'} src={'images/static/comma.svg'}/>

                            <div className="img-wrapper">
                                <img className={'img'} src={data?.image ? data?.image : 'images/static/blur.jpg'}/>
                            </div>


                    </Col>

                    <Col lg={{span: 5, offset:2}} className={'content'}>
                        {data?.title && <h3 className={'split-up'}>{(data?.title)}</h3>}
                        {data?.name && data?.designation && <p className={'sub-title split-up'}>{data?.name + ' , ' + data?.designation}</p>}
                        {data?.message && <p>{reactHtmlParser(data?.message)}</p>}
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: #323232;
  margin-top: -1px;
  
  .img-col {
    position: relative;

    .comma {
      position: absolute;
      top: -30px;
      right: -32px;
      width: 65px;
      height: 60px;
      z-index: 2;
    }
  }
  
  .img-wrapper {
    position: relative;
    padding-top: calc(720 / 530 * 100%);
    
    .img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      z-index: 1;
    }
  }
  
  .content {
    h3 {
      color: #FFFFFF;
      margin: 0 0 10px 0;
    }

    .sub-title {
      color: #FFFFFF;
      font-weight: 500;
      margin: 0 0 80px 0;
    }

    p {
      color: #FFFFFF;
      font-weight: 300;
      margin: 0;
    }
  }

  @media (max-width: 991px) {

    .img-col {
      .comma {
        position: absolute;
        top: -30px;
        right: 5px;
        width: 65px;
        height: 60px;
      }
    }

    .img-wrapper {
      padding-top: calc(450 / 345 * 100%);
    }
    
    .content {
      h3 {
        margin: 40px 0 10px 0;
      }

      .sub-title {
        margin: 0 0 40px 0;
      }
    }
  }
  
`;

export default Chairperson;
