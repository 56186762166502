import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import Button from "../Button"
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import reactHtmlParser from "react-html-parser";

const Overview = ({data}) => {
    return (
        <StyledComponent  className='about-partex pt-200 pb-200 horizontal-scroll' data-speed-right={50} data-speed-left={-50}>
            <Container >
                <Row>
                    <p className={'scroll-right opacity-30 bg-text-one'}>{data?.background_text_one}</p>
                    <p className={'scroll-left opacity-30 bg-text-two'}>{data?.background_text_two}</p>

                    <Col sm={12}>
                        {data?.subtitle && <p className={'upper-title split-left'}>{reactHtmlParser(data?.subtitle)}</p>}
                    </Col>

                    <Col lg={9}>
                        {data?.title && <h3 className={'split-up'}>{reactHtmlParser(data?.title)}</h3>}
                    </Col>

                    <Col lg={{span:10, offset:2}}>
                        {data?.description && <p>{reactHtmlParser(data?.description)}</p>}
                    </Col>

                    <Col lg={{span:6, offset:6}}>
                        <Row>
                            <Col lg={6} className={'counter-item'}>
                                <h3>
                                    <span>
                                        <CountUp start={0} end={data?.year}>
                                            {({countUpRef, start}) => (
                                                <VisibilitySensor onChange={start}
                                                                  partialVisibility={{top: 0, bottom: 20}}
                                                                  delayedCall>
                                                    <span ref={countUpRef}/>
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>
                                        +
                                    </span>
                                </h3>
                                {data?.year_title && <p className="split-left">{reactHtmlParser(data?.year_title)}</p>}
                            </Col>


                            <Col lg={6}></Col>

                            <Col lg={6} className={'counter-item'}>
                                <h3>
                                    <span>
                                        <CountUp start={0} end={data?.worker}>
                                            {({countUpRef, start}) => (
                                                <VisibilitySensor onChange={start}
                                                                  partialVisibility={{top: 0, bottom: 20}}
                                                                  delayedCall>
                                                    <span ref={countUpRef}/>
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>
                                        +
                                    </span>
                                </h3>
                                {data?.worker_title && <p className="split-left">{reactHtmlParser(data?.worker_title)}</p>}
                            </Col>

                            <Col lg={6} className={'counter-item'}>
                                <h3>
                                    <span>
                                        <CountUp start={0} end={data?.factory}>
                                            {({countUpRef, start}) => (
                                                <VisibilitySensor onChange={start}
                                                                  partialVisibility={{top: 0, bottom: 20}}
                                                                  delayedCall>
                                                    <span ref={countUpRef}/>
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>
                                        +
                                    </span>
                                </h3>
                                {data?.factory_title && <p className="split-left">{reactHtmlParser(data?.factory_title)}</p>}
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: #E4E4E4;
  
  .row {
    position: relative;
  }
  
  .bg-text-one, .bg-text-two {
    font-size: 240px;
    line-height: 240px;
    font-weight: 700;
    margin: 0;
    color: #fff;
    position: absolute;
    white-space: nowrap;


    @media (max-width: 991px) {
      font-size: 120px;
      line-height: 120px;
    }
  }
  
  .bg-text-one {
    left: 0%;
    bottom: 150px;

    @media (max-width: 991px) {
      bottom: unset;
      top: 40%;
    }
  }
  
  .bg-text-two {
    right: -20%;
    bottom: unset;
    top: calc(40% - 120px);
  }

  .upper-title {
    width: 50%;
    margin: 0 0 20px 0;
  }
  
  h3 {
    margin: 0 0 80px 0;
    
    span {
      color: #E50019;
    }
  }

  p {
    margin: 0 0 80px 0;
  }
  
  .counter-item {
    h3 {
      font-size: 72px;
      line-height: 84px;
      font-weight: 400;
      margin: 0;
    }
    
    p {
      font-weight: 500;
      margin: 0;
    }
    
    &:nth-child(1) {
      margin-bottom: 40px;
    }
  }

  @media (max-width: 767px){
    .counter-item {
      p{
        opacity: .5;
      }
    }
  }

  @media (max-width: 991px) {
    .upper-title {
      width: 100%;
    }

    .counter-item {
      &:nth-child(3) {
        margin-bottom: 40px;
      }
    }
  }
  
`;

export default Overview;
