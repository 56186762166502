import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import Button from "../Button";
import {Img} from "../Img";
import {hoverr} from "../../styles/globalStyleVars";

const Bod = ({offset, data}) => {
    return (
        <StyledComponent className='bod parallax'>
            <Container fluid style={{paddingLeft:offset+15+'px'}}>
                <Row>
                    <Col lg={4} className={'content'}>
                        {data?.title && <h4 className={'split-up'}>{(data?.title)}</h4>}
                        <Button src={'/board-of-directors'} text={'Board of Directors'} color={'#323232'} background={'#E8E8E8'}/>
                    </Col>

                    <Col lg={8} className={'img-col'}>
                        <div className="img-wrapper parallax-bg reveal">
                            <Img  src={data?.image ? data?.image : 'images/static/blur.jpg'}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: #323232;
  
  .container-fluid {
    padding-right: 0;
  }
  
  .img-wrapper {
    position: relative;
    height: 100%;
    padding-top: calc(520 / 868 * 100%);
    
    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }
  
  .content {
    padding-top: 120px;
    padding-bottom: 120px;
    
    h4 {
      color: #FFFFFF;
      margin: 0 0 40px 0;
      
      span {
        color: ${hoverr};
      }
    }

    .sub-title {
      color: #FFFFFF;
      font-weight: 500;
      margin: 0 0 80px 0;
    }

    p {
      color: #FFFFFF;
      margin: 0 0 40px 0;
    }
  }

  @media (max-width: 991px) {
    .container-fluid {
      padding: 0 15px !important;
    }
    
    .row {
      flex-direction: column-reverse;
    }

    .img-col {
      padding: 0px !important;
    }

    .content {
      padding-top: 80px;
      padding-bottom: 0px;
      padding-right: 15px !important;

      .sub-title {
        margin: 0 0 40px 0;
      }
    }
  }
  
`;

export default Bod;
