import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Modal from "react-bootstrap/Modal";
import {Col, Container, Form, Row} from "react-bootstrap";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import ReactHtmlParser from "react-html-parser";
import {BsX} from "react-icons/bs";
import reactHtmlParser from "react-html-parser";
import Button from "./Button";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../api/network/apiEndPoints";
import {postForm} from "../api/redux/Contact";

const Popup = ({
                   show,
                   handleClose,
                   no_img,
                   item,
                   title,
                   description,
                   data,
                   subtitle,
                   img
               }) => {

    const dispatch = useDispatch();
    const responseData = useSelector(state => state.contact);

    const {register, handleSubmit, formState, reset} = useForm({mode:'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const handleBlur = () => {
        // Perform onBlur logic here
    };

    const handleChange = () => {
        // Perform onChange logic here
    };

    const onSubmit = (e) => {
        let api_services = apiEndPoints.CONTACT_FORM;

        var formData = new FormData();
        formData.append('your-name', e?.name);
        formData.append('your-email', e?.email);
        formData.append('your-phone', e?.phone);
        formData.append('your-message', e?.message);

        if (e.email !== '' && e.name !== '' && e.phone !== '') {
            dispatch(postForm([api_services, formData]));
            success('Successfully Submitted')
            reset();
        }
    };
    let count=0;
    const onError = (errors) => {
        console.log(errors)
        Object.values(errors).forEach((error) => {
            count++;
        });
        if(count>0){
            toast.error('please fill out the correct inputs');
        }
        count=0;
    };
    useEffect(() => {
        if (responseData && responseData?.error !== '') {
            error(responseData?.error)
        }
        if (responseData && responseData?.success !== '') {
            // success(responseData?.success)
        }

    }, [responseData])



    return (

        <StyledModal>
            <Modal
                show={show}
                item={item}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="gph_modal popup-version-one cta-pop-up"
            >
                <SimpleBar className="main_scroll" style={{height: '100vh'}}>
                    <Modal.Body>
                        <Container>
                            <Row className={'for-close'} onClick={handleClose}>
                                <div  className="modal-close hover">
                                    <p>Close</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24.042" height="24.042" viewBox="0 0 24.042 24.042">
                                        <g id="Lines" transform="translate(-332.979 -31.979)">
                                            <line id="Line_3965" data-name="Line 3965" x1="30" transform="translate(355.606 33.394) rotate(135)" fill="none" stroke="black" stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_3966" data-name="Line 3966" x1="30" transform="translate(334.393 33.393) rotate(45)" fill="none" stroke="black" stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_3967" data-name="Line 3967" x1="30" transform="translate(355.607 33.394) rotate(135)" fill="none" stroke="black" stroke-linecap="round" stroke-width="2"/>
                                        </g>
                                    </svg>
                                </div>
                            </Row>
                        </Container>
                        <Container >
                            <Row>
                                <p className={'scroll-right opacity-30 bg-text-one'}>CUSTOMER</p>
                                <p className={'scroll-left opacity-30 bg-text-two'}>SUPPORT</p>

                                <Col md={{span:9, offset:3}}>
                                    <h4>{reactHtmlParser('Get in touch today!')}</h4>

                                    <Form onSubmit={handleSubmit(onSubmit,onError)}>
                                        <input name={'spam_protector'} type='hidden'/>

                                        <Form.Control
                                            className={formState?.errors?.name?.message ? 'has-error' : ''}
                                            {...register("name", {
                                                required:{
                                                    value:true,
                                                    message:'please enter your name first'
                                                },
                                                pattern: {
                                                    value: /^[^\d]+$/, // This regular expression allows only non-digit characters
                                                    message: "Name cannot contain digits",
                                                }
                                            })}
                                            type="text"
                                            placeholder="Name*"
                                        />

                                        <div className="form__phoneEmail">

                                            <Form.Control className={formState?.errors?.phone?.message ? 'has-error' : ''}
                                                          {...register("phone",{
                                                              required:{
                                                                  value:true,
                                                                  message:'please enter your phone first'
                                                              },
                                                              pattern:{
                                                                  value:/^01[0-9]{9}$/,
                                                                  message:'please enter a valid 11 digit phone number'
                                                              }
                                                          })}
                                                          type="number"
                                                          placeholder="Phone Number*"/>
                                            <Form.Control  className={formState?.errors?.email?.message ? 'has-error' : ''}
                                                           {...register("email",{
                                                               required:{
                                                                   value:true,
                                                                   message:'please enter your email'
                                                               },
                                                               pattern:{
                                                                   value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i,
                                                                   message:'please enter a valid email address'
                                                               }
                                                           })}
                                                           type="email"
                                                           placeholder="Email*"/>
                                        </div>

                                        <Form.Control className={formState?.errors?.email?.message ? 'has-error' : ''}
                                                      {...register("message",{
                                                          required:{
                                                              // value:true,
                                                              message:'please enter your message'
                                                          },

                                                      })}
                                                      type="textarea"
                                                      placeholder="Message"/>


                                        <div onClick={handleSubmit(onSubmit,onError)}>
                                            <Button text={'Submit Message'} color={'#323232'} background={'#E8E8E8'}/>
                                        </div>

                                    </Form>
                                </Col>
                            </Row>


                        </Container>
                    </Modal.Body>
                </SimpleBar>
            </Modal>
        </StyledModal>

    )
};


const StyledModal = styled.section`
`;


export default Popup;
