import React, {useEffect, useLayoutEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Dropdown, DropdownButton, ListGroup} from "react-bootstrap";
// Internal Component
import VisibilitySensor from "react-visibility-sensor";
import {Link, useLocation} from "react-router-dom";
import {Img} from "../Img";
import {hoverr} from "../../styles/globalStyleVars";
import Button from "../Button";
import {useSelector} from "react-redux";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";


const MediaList = ({ data, filter, router, isIndustry }) => {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    const [activeTabTitle, setActiveTabTitle] = useState('');
    const [itemsToShow, setItemsToShow] = useState(6);
    const [isCompleted, setIsCompleted] = useState(false);
    const allCategory = { value: 'item0', label: 'All' };

    // Get the query parameter value from the URL
    const queryParams = new URLSearchParams(window.location.search);
    const category = queryParams.get('category');

    // Function to convert string to 'Title Case'
    const convertToTitleCase = (str) => {
        if (!str) return ''; // Check if str is null or undefined
        return str.replace(/\b\w/g, (char) => char.toUpperCase()).replace(/-/g, ' ');
    };

    // Convert category string to 'Title Case'
    const formattedCategory = convertToTitleCase(category);
console.log('activeTab',activeTab)

    const options = [
        allCategory,
        ...(filter?.media_category?.map((element) => ({ value: element?.value, label: element?.label })) || []),
    ];
    const handleLoadMore = () => {
        setItemsToShow(prevItemsToShow => prevItemsToShow + 6);
        if (itemsToShow + 6 >= filteredData.length) {
            setIsCompleted(true);
        } else {
            setIsCompleted(false);
        }
    };
    // Define a function for filtering data based on active tab and category

    useEffect(() => {
        // setTimeout(() => {
        //     if (document.querySelector('.product-specification-ul li')) {
        //         if (document.querySelector('.product-specification-ul .active')) {
        //             document.querySelector('.product-specification-ul .active').classList.remove('active');
        //         }
        //         document.querySelector('.product-specification-ul li').classList.add('active');
        //     }
        // }, 200);
        //
        // if (document.querySelector('.product-specification-ul li')) {
        //     let AlltheLi = document.querySelectorAll('.product-specification-ul li');
        //     AlltheLi.forEach((i) => {
        //         i.addEventListener('click', () => {
        //             document.querySelector('.product-specification-ul li').classList.remove('active');
        //         });
        //     });
        // }

        if(formattedCategory === 'All'){
            setActiveTab('item0'); // Set default activeTab
            setActiveTabTitle('All'); // Set default activeTabTitle
        }else if(formattedCategory === 'Event'){
            setActiveTab('item1'); // Set activeTab to 'item3'
            setActiveTabTitle(formattedCategory);
        }else if (formattedCategory === 'Press Release') {
            setActiveTab('item2'); // Set activeTab to 'item3'
            setActiveTabTitle(formattedCategory); // Set activeTabTitle to the value of 'category'
        }
    }, [formattedCategory]);

    function handleClickTabs(e, f) {
        if (f === 'all') {
            setActiveTab('all');
            setActiveTabTitle('all');
        } else {
            setActiveTab(e);
            setActiveTabTitle(f);
            const filteredDataResult =
                e === 'item0' ? data : data?.filter((itemCol) => itemCol?.media_cat === f) || [];
            setFilteredData(filteredDataResult);
        }
    }

    useEffect(() => {
        const filteredDataResult =
            activeTab === 'item0' ? data : data?.filter((itemCol) => itemCol?.media_cat === activeTabTitle) || [];
        setFilteredData(filteredDataResult);
    }, [activeTab, activeTabTitle, data]);



    let getData =useSelector(state => state)
    // sticky navbar
    gsap.registerPlugin(ScrollTrigger);

    useLayoutEffect(() => {
        const col4Element = document.querySelector('.concern-nav');

        // Check if the screen width is greater than 1024px (desktop)
        if (window.innerWidth > 767) {
            gsap.to(col4Element, {
                scrollTrigger: {
                    trigger: col4Element,
                    start: 'top top',
                    end: 'max',
                    pin: true,
                    pinSpacing: false
                }
            });
        }

        return () => {
            ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
        };
    }, []);

    return (
        <>
            <StyledMediaTab className={'concern-nav'}>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className={'product-specification-head'}>
                                <ListGroup as={'ul'} horizontal className={'product-specification-ul'}>
                                    {options?.map((item, index) => (
                                        <ListGroup.Item as="li" active={activeTab === 'item' + index}>
                                            <Link to="#" onClick={() => handleClickTabs('item' + index, item?.label)}>
                                                <span>{item?.label}</span>
                                            </Link>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>

                                <DropdownButton as={'ul'} className={'product-specification-ul__mobile'}
                                                title={activeTabTitle || 'Select'}>
                                    {options?.map((item, index) => (
                                        <Dropdown.Item as={'li'} active={activeTab === 'item' + index}>
                                            <Link to="#" onClick={() => handleClickTabs('item' + index, item?.label)}>
                                                <span>{item?.label}</span>
                                            </Link>
                                        </Dropdown.Item>
                                    ))}
                                </DropdownButton>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </StyledMediaTab>

            <StyledMediaList className={'ProductSpecWrapper pb-200'}>
                <Container className={'pt-100'}>
                    <VisibilitySensor offset={{top: 10}} partialVisibility>

                                        <div className={`product-specification-tab`}>
                                            <Row>
                                                {filteredData?.length > 0 && filteredData?.slice(0, itemsToShow)?.map((itemCol, indexItem) => (
                                                    <Col md={6} lg={4} className={'blog-single'}>
                                                        <div className="blog-single__inner">
                                                            <Link to={'/media-center/' + itemCol?.post_name}></Link>
                                                            <Img src={itemCol?.media_thumb} objectFit={'cover'}
                                                                 layout={'fill'}/>
                                                            <div className="blog-single__inner__content">
                                                                <div className="blog-single__inner__content__top">
                                                                    <div className="upper">
                                                                        <p>{itemCol?.hover_text}</p>
                                                                        <p className={'read-more'}>Read More</p>
                                                                    </div>
                                                                    <h2>{itemCol?.post_title}</h2>
                                                                </div>

                                                                <div className="blog-single__inner__content__bottom">
                                                                    {/*<h3>{itemCol?.media_date}*/}
                                                                    {/*    <p>{itemCol?.media_month_year}</p></h3>*/}
                                                                    <p>{itemCol?.media_cat}</p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))}
                                                {itemsToShow < filteredData?.length && (
                                                    <Col sm={12} className={'load_more'}>
                                                        <div onClick={handleLoadMore}>
                                                            <Button text={'Load More'} margin={'0 auto'} color={'#002839'} background={'#F2F2F2'} border={'1px solid #002839'}/>
                                                        </div>
                                                    </Col>
                                                )}
                                            </Row>

                                            {/*<Row>*/}
                                            {/*    {itemsToShow < filteredData?.length && (*/}
                                            {/*        <Col sm={12} className={'load_more'}>*/}
                                            {/*            <div onClick={handleLoadMore}>*/}
                                            {/*                <Button text={'Load More'} margin={'0 auto'}*/}
                                            {/*                        color={'#323232'} background={'#E8E8E8'}/>*/}
                                            {/*            </div>*/}
                                            {/*        </Col>*/}
                                            {/*    )}*/}
                                            {/*</Row>*/}
                                        </div>

                    </VisibilitySensor>
                </Container>
            </StyledMediaList>
        </>

    );
};

const StyledMediaTab = styled.section`
  background: #323232;
  position: revert;
  z-index: 100;

  .product-specification-head {
    .product-specification-ul {
      border-radius: 0;

      .list-group-item {
        padding: 0;
        border: 0;
        background-color: transparent;
        border-radius: 0;

        &.active {
          margin: 0;

          a, span {
            color: #ffffff;
          }
        }

        a {
          font-size: 20px;
          line-height: 24px;
          font-weight: 500;
          padding: 20px 30px;
          display: flex;
          color: rgba(255, 255, 255, 0.5);
          height: 100%;

          &:hover {
            color: #ffffff !important;

            span {
              color: #ffffff !important;
            }
          }
        }

        &:nth-child(1) {
          a {
            padding-left: 0;
          }
        }
      }

      &__mobile {
        display: none;
      }
    }
  }

  @media (max-width: 767px) {
    .col-sm-12 {
      padding: 0;
    }

    .product-specification-head {
      .product-specification-ul {
        display: none;

        &__mobile {
          display: block;

          button {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 15px;
            border-radius: 0;
            background-color: #323232;
            border: 0;
            position: relative;

            &:after {
              display: none;
            }

            &:before {
              content: '';
              background-image: url('/images/static/drop.svg');
              position: absolute;
              right: 15px;
              height: 10px;
              width: 12px;
              top: 50%;
              background-repeat: no-repeat;
              transform: translateY(-5px) rotate(0deg);
            }

            &:active {
              background-color: transparent;
              border: 0;

              &:before {
                transform: translateY(-10px) rotate(180deg);
              }
            }
          }

          &-menu {
            width: 100%;
          }

          .dropdown-item {
            padding: 0;

            &:nth-child(1) {
              a {
                padding-top: 20px;
              }
            }

            &:nth-last-child(1) {
              padding-bottom: 20px;
            }

            &.active {
              background-color: rgba(50, 50, 50, 0.5);

              a {
                color: #ffffff;
              }
            }

            &:active {
              background-color: transparent;
            }

            a {
              width: 100%;
              display: block;
              padding: 10px 15px;
            }
          }

          .dropdown-menu {
            width: 100%;
            padding: 0;
            margin: 0;
            border-radius: 0;
          }
        }
      }
    }
  }
`;


const StyledMediaList = styled.section`
  .blog-single {
    padding-bottom: 30px;

    &:nth-last-child(1) {
      padding-bottom: 0;
    }

    &:nth-last-child(2) {
      padding-bottom: 0;
    }

    &:nth-last-child(3) {
      padding-bottom: 0;
    }

    @media (max-width: 991px) {
      &:nth-last-child(3) {
        padding-bottom: 30px;
      }
    }

    @media (max-width: 767px) {
      padding: 0 0 60px 0;

      &:nth-last-child(1) {
        padding-bottom: 0;
      }

      &:nth-last-child(2) {
        padding-bottom: 60px;
      }

      &:nth-last-child(3) {
        padding-bottom: 60px;
      }
    }

    .blog-single__inner {
      padding-top: calc(440 / 370 * 100%);
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.3);
      }

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 3;
      }

      &__content {
        &:after {
          content: '';
          height: 0;
          width: 100%;
          background-color: ${hoverr};
          bottom: 0;
          left: 0;
          right: 0;
          position: absolute;
          transition: height .4s ease;
        }

        &__top {
          .upper {
            position: absolute;
            left: 30px;
            top: 40px;
            z-index: 2;
            right: 30px;
            margin: 0;
            transform: translateY(-30px);
            opacity: 0;
            transition: all .6s ease;
          }

          p {
            font-size: 20px;
            line-height: 24px;
            font-weight: 300;
            color: #FFFFFF;
            margin: 0 0 40px 0;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }

          .read-more {
            font-size: 20px;
            line-height: 24px;
            font-weight: 300;
            color: #FFFFFF;
            margin: 0;
            text-decoration: underline;
          }

          h2 {
            position: absolute;
            top: 40px;
            left: 30px;
            right: 30px;
            z-index: 2;
            font-size: 28px;
            font-weight: 500;
            line-height: 32px;
            color: #FFFFFF;
            margin: 0;
            transition: all .3s ease;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }
        }

        &__bottom {
          position: absolute;
          margin: 0;
          left: 30px;
          right: 30px;
          bottom: 24px;
          display: flex;
          justify-content: space-between;
          padding-top: 20px;
          z-index: 2;

          h3 {
            color: #FFFFFF;
            transition: color .3s ease;

            p {
              display: block;
              color: #FFFFFF;
            }
          }

          p {
            font-size: 16px;
            color: #FFFFFF;
            text-align: right;
            line-height: 20px;
            transition: color .3s ease;

            span {
              display: block;
              color: #FFFFFF;
            }
          }
        }
      }

      &:hover {
        .blog-single__inner__content__top {
          h2 {
            opacity: 0;
            transform: translateY(-20px);
          }

          .upper {
            transform: none;
            opacity: 1;
          }
        }

        .blog-single__inner__content__bottom {
          border-color: #FFF;

          h4 {
            color: #ffffff;
          }

          p {
            color: #ffffff;

            span {
              color: #ffffff;
            }
          }
        }
      }

    }

    &:hover {
      .blog-single__inner__content:after {
        height: 100%;
      }
    }
  }

  .load_more {
    margin-top: 100px;

    .dc-btn {
      a {
        margin: auto;
      }
    }
  }
`;

export default MediaList;