import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import Button from "../Button";
import Img from "../Img";
import VisibilitySensor from "react-visibility-sensor";
import reactHtmlParser from "react-html-parser";
import Title from "../Title";
import {ImgCta} from "../ImgCta";

const MissionVision = ({offset, data,id}) => {
    return (
    <StyledComponent id={`${id ? id : 'mission-vision'}`}>
        <Container fluid className={'about-overview'} style={{paddingLeft:offset+15+'px'}}>

            <Row className="about-overview__data pt-160 pb-160">
                <ImgCta src={data?.back_img}/>

                <Col md={{span:5, offset:1}} className="about-overview__data__left">
                    {data?.title && <h2 className="split-up">{(data?.title)}</h2>}
                </Col>

                <Col md={6} className="about-overview__data__right" style={{paddingRight:offset+15+'px'}}>
                    <ul>
                        <li>
                            {data?.vision_title && <h4 className="split-left">{(data?.vision_title)}</h4>}
                            {data?.vision_desc && <p>{(data?.vision_desc)}</p>}
                        </li>
                        <li>
                            {data?.mission_title && <h4 className="split-left">{(data?.mission_title)}</h4>}
                            {data?.mission_desc && <p>{(data?.mission_desc)}</p>}
                        </li>
                    </ul>
                </Col>
            </Row>
        </Container>
    </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  background: #FFFFFF;

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    height: 50%;
    z-index: 0;
    background-color: #E4E4E4;
  }

  .container-fluid {
    padding-right: 0;

    @media (max-width: 767px) {
      padding: 0 15px;
    }
  }

  .row {
    background: #323232;
    position: relative;
    z-index: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: unset;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  h2 {
    font-size: 48px;
    line-height: 56px;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 0px;
    
    @media (max-width: 767px) {
      margin-bottom: 80px;
    }
  }

  ul {
    li {
      counter-increment: count;
      position: relative;
      padding: 0 0 0 70px;
      
      &:before {
        content: counter(count, decimal);
        font-size: 72px;
        line-height: 84px;
        font-weight: 400;
        color: #ffffff;
        position: absolute;
        left: 0;
        top: 0;
      }
      
      h4 {
        margin: 0 0 20px 0;
        color: rgba(255, 255, 255, 0.5);
      }
      
      p {
        font-size: 28px;
        line-height: 32px;
        font-weight: 500;
        margin: 0;
        color: #ffffff;
      }
      
      &:nth-child(1) {
        border-bottom: 1px solid #C4C4C4;

        p {
          margin: 0 0 80px 0;
        }
      }

      &:nth-child(2) {
        &:before {
          top: 80px;
        }
        
        h4 {
          padding: 80px 0 0 0;
        }
      }
    }

    @media (max-width: 767px) {
      li {
        &:nth-child(1) {
          p {
            margin: 0 0 40px 0;
          }
        }

        &:nth-child(2) {
          &:before {
            top: 40px;
          }
          
          h4 {
            padding: 40px 0 0 0;
          }
        }
      }
    }
  }

`;

export default MissionVision;
