import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import Button from "./Button";
import Title from "./Title";
import Img from "./Img";
import reactHtmlParser from "react-html-parser";
import PopUp from "./BoardOfDirectors/DirectorsPopUp";
import CtaPopUp from "./CtaPopUp";
import {ImgCta} from "./ImgCta";
import {ImgParallax} from "./ImgParallax";


const MyComponent = ({imgSrc, upperTitle, subTitle, desc, btntxt, btnColor, btnBg, popup, src}) => {


    const [show, setShow] = useState(false);
    const [winWidth, setWinWidth] = useState(true)
    const [popupId, setPopupId] = useState()
    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true)
        setPopupId(e)
    }
    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [])

    return (
        <>
            <StyledComponent className='sustainability-text'>
                <ImgParallax speed={15} banner={true} src={imgSrc ? imgSrc : 'images/static/blur.jpg'}/>

                <Container>
                    <Row>
                        <Col sm={12}>
                            {upperTitle && <p className={'upper-title split-up'}>{reactHtmlParser(upperTitle)}</p>}
                        </Col>

                        <Col md={7}>
                            {subTitle && <h3 className={'split-up'}>{reactHtmlParser(subTitle)}</h3>}
                        </Col>

                        <Col md={{span: 4, offset: 1}}>
                            {desc && <p className={'split-up'}> {reactHtmlParser(desc)}</p>}
                            <div onClick={() => handleShow(0)}>
                                <Button src={src} text={btntxt} color={btnColor} background={btnBg}/>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </StyledComponent>
            {
                popup &&
                <CtaPopUp show={show} handleClose={handleClose}/>
            }

        </>
    );
};

const StyledComponent = styled.section`
  position: relative;
  height: calc(100vh + 100px);

  .container {
    position: absolute;
    bottom: 120px;
    left: 0;
    right: 0;
  }

  p {
    color: #FFFFFF;
    margin: 0 0 40px 0;
  }

  .upper-title {
    width: 50%;
    margin: 0 0 20px 0;
  }

  h3 {
    color: #FFFFFF;
  }

  @media (max-width: 767px) {
    .upper-title {
      width: 100%;
    }

    h3 {
      margin: 0 0 80px 0;
    }
  }

`;

export default MyComponent;
