import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {ScrollTrigger} from "gsap/ScrollTrigger";


export const SplitUp = () => {
    const location = useLocation();
    const getPost = useSelector((state) => state);
    gsap.registerPlugin(SplitText);
    const isDesktop = window.innerWidth > 991;

    useEffect(() => {
        document.fonts.ready.then(() => {
            if (isDesktop) {
                gsap.utils.toArray(".split-up").forEach((item, i) => {
                    const originalHTML = item.innerHTML;
                    const originalLetterSpacing = window.getComputedStyle(item).letterSpacing;

                    const parentSplit = new SplitText(item, {
                        linesClass: "split-parent",
                    });

                    new SplitText(item, {
                        type: "lines, chars",
                        linesClass: "split-child",
                    });

                    gsap.from(parentSplit.lines, {
                        duration: 1,
                        yPercent: 100,
                        scrollTrigger: {
                            trigger: item,
                            start: "top 85%",
                            once: true
                        },
                        onComplete: () => {
                            item.classList.remove("split-up");
                            item.style.visibility = "hidden";

                            // Apply letter spacing to child elements
                            parentSplit.lines.forEach(line => {
                                line.style.letterSpacing = originalLetterSpacing;
                            });

                            item.innerHTML = originalHTML;

                            item.style.visibility = "visible";
                        }
                    });
                });
                gsap.utils.toArray(".split-left").forEach((item, i) => {
                    const splitText = new SplitText(item, {
                        type: "chars,words,lines",
                        reduceWhiteSpace: true,
                    });
                    const chars = splitText.chars;

                    gsap.from(chars, 1.5, {
                        x: "10vw",
                        // opacity: 0,
                        // ease: "elastic.out(1, 0.3)",
                        stagger: 0.015,
                        scale: 0.95,
                        // delay: 0.2,
                        ease: "expo.out",
                        scrollTrigger: {
                            trigger: item,
                            toggleActions: "play none none none",
                        },
                    });
                    gsap.from(chars, 0.6, {
                        // x: 80,
                        opacity: 0,
                        // ease: "elastic.out(1, 0.3)",
                        stagger: 0.016,
                        // delay: 0.2,
                        ease: "power4.out",
                        scrollTrigger: {
                            trigger: item,
                            toggleActions: "play none none none",
                        },
                    });
                });
            }
            if (isDesktop) {
                gsap.utils.toArray(".horizontal-scroll").forEach((item, i) => {
                    let getTextRight = item.querySelector('.scroll-right')
                    let getTextLeft = item.querySelector('.scroll-left')
                    let parallaxSpeedRight = item.getAttribute('data-speed-right');
                    let parallaxSpeedLeft = item.getAttribute('data-speed-left');

                    gsap.to(getTextRight, {
                        xPercent: parallaxSpeedRight ? parallaxSpeedRight : 50,
                        ease: "none",
                        scrollTrigger: {
                            trigger: item,
                            // start: "left 70%",
                            // end: "left 30%",
                            // horizontal: true,
                            // markers: true,
                            scrub: true
                        }
                    });

                    gsap.to(getTextLeft, {
                        xPercent: parallaxSpeedLeft ? parallaxSpeedLeft : -50,
                        ease: "none",
                        scrollTrigger: {
                            trigger: item,
                            // start: "left 70%",
                            // end: "left 30%",
                            // horizontal: true,
                            // markers: true,
                            scrub: true
                        }
                    });

                    // t1.to(".box1", {
                    //     scale: 2,
                    //     rotation: 360,
                    //     duration: 3
                    // });

                });
            }

            if (isDesktop) {
                gsap.utils.toArray('.split-up-heritage').forEach((item, i) => {
                    const parentSplit = new SplitText(item, {
                        linesClass: "split-parent"
                    })
                    const childSplit = new SplitText(item, {
                        type: "lines, chars",
                        linesClass: "split-child"
                    })
                    const animation = gsap.from(parentSplit.lines, {
                        duration: .5,
                        delay: .5,
                        yPercent: 100,
                        stagger: 0.1,
                        scrollTrigger: {
                            trigger: item,
                            toggleActions: "restart none none reset",
                            // start: "top 60%",
                            // markers: true
                        }
                    })
                    // Remove the animation when the element is out of view or no longer needed
                    animation.eventCallback('onComplete', () => {
                        animation.kill(); // This may not be necessary depending on the version of TweenMax
                    });
                });

            }

            if (isDesktop) {

                gsap.utils.toArray('.split-up-banner').forEach((item, i) => {
                    const parentSplit = new SplitText(item, {
                        linesClass: "split-parent"
                    })
                    const childSplit = new SplitText(item, {
                        type: "lines, chars",
                        linesClass: "split-child"
                    })
                    const animation = gsap.from(parentSplit.lines, {
                        duration: 1,
                        delay: 1.1,
                        yPercent: 100,
                        stagger: 0.2,
                        scrollTrigger: {
                            trigger: item,
                            toggleActions: "restart none none reset",
                            // start: "top 60%",
                            // markers: true
                        }
                    })
                    // Remove the animation when the element is out of view or no longer needed
                    animation.eventCallback('onComplete', () => {
                        animation.kill(); // This may not be necessary depending on the version of TweenMax
                    });
                });
            }
        });
    }, [location.pathname, getPost]);


    return null; // Assuming you are not rendering anything in this component
};
