import React, {useEffect} from 'react';
import styled from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";
import InnerBanner from "../../components/InnerBannerTwo";
import CsrOverView from "../../components/Csr/CsrOverView";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchCsr} from "../../api/redux/Csr";
import InnerBannerTwo from "../../components/InnerBannerTwo";

;
const CSR = () => {
    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.CSR
        dispath(fetchCsr([api_url]))
    }, [])

    let getPost = useSelector(state => state.csr);
    const banner = getPost?.posts?.page_data?.[0]?.banner;
    const csroverview = getPost?.posts?.page_data?.[0]?.csr;
    const csrslider = getPost?.posts?.page_data?.[0]?.csr?.[0]?.images;
    const page_title = getPost?.posts?.page_data?.[0]?.post_title;


    // Define the images for different viewports
    const largeImage = banner?.image;
    const mediumImage = banner?.image_tab;
    const smallImage = banner?.image_mobile;

    // Determine the viewport size and set the image accordingly
    let imgUrl;
    if (window.innerWidth >= 992) {
        imgUrl = largeImage;
    } else if (window.innerWidth >= 768) {
        imgUrl = mediumImage;
    } else {
        imgUrl = smallImage;
    }

    let data = getPost?.posts?.page_data?.[0];
    let innerBanner = data?.banner?.image;

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${data?.meta?.meta_title ? data?.meta?.meta_title : 'Partex Star Group'}`}</title>
                {
                    data?.meta?.meta_desc &&
                    <meta name="description" content={data?.meta?.meta_desc}/>

                }
                {
                    data?.meta?.og_title &&
                    <meta property="og:title" content={data?.meta?.og_title}/>

                }
                {
                    data?.meta?.og_desc &&
                    <meta property="og:description" content={data?.meta?.og_desc}/>
                }
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={innerBanner?.image}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>
                {banner &&
                    <InnerBannerTwo
                        img={imgUrl}
                        breadcrumbs={banner?.breadcrumbs}
                        title={banner?.title}
                        subtitle={banner?.description}
                        text={banner?.text_left}/>
                }

                {csrslider?.length > 0 && (
                    <CsrOverView data={csroverview} dataSlider={csroverview.map(item => item.images)}/>
                )}

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
`;

export default CSR;
