import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link, Route} from "react-router-dom";

import {HelmetProvider, Helmet} from 'react-helmet-async';
import InnerBanner from "../../components/InnerBanner";
import Address from "../../components/Contact/Address";
import ContactForm from "../../components/Contact/ContactForm";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchContact} from "../../api/redux/Contact";

const MyComponent = () => {
    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.CONTACT
        dispath(fetchContact([api_url]))
    }, [])

    let getPost = useSelector(state => state.contact);

    const page_title = getPost?.posts?.page_data?.[0]?.post_title;
    const banner = getPost?.posts?.page_data?.[0]?.banner;
    const contact_info = getPost?.posts?.page_data?.[0]?.contact_info;
    const office_locations = getPost?.posts?.page_data?.[0]?.office_locations;

    // Define the images for different viewports
    const largeImage = banner?.image;
    const mediumImage = banner?.image_tab;
    const smallImage = banner?.image_mobile;

    // Determine the viewport size and set the image accordingly
    let imgUrl;
    if (window.innerWidth >= 992) {
        imgUrl = largeImage;
    } else if (window.innerWidth >= 768) {
        imgUrl = mediumImage;
    } else {
        imgUrl = smallImage;
    }

    let data = getPost?.posts?.page_data?.[0];
    let innerBanner = data?.banner?.image;
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${data?.meta?.meta_title ? data?.meta?.meta_title : 'Partex Star Group'}`}</title>
                {
                    data?.meta?.meta_desc &&
                    <meta name="description" content={data?.meta?.meta_desc}/>

                }
                {
                    data?.meta?.og_title &&
                    <meta property="og:title" content={data?.meta?.og_title}/>

                }
                {
                    data?.meta?.og_desc &&
                    <meta property="og:description" content={data?.meta?.og_desc}/>
                }
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={innerBanner?.image}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>
                <InnerBanner
                    img={imgUrl}
                    breadcrumbs={banner?.breadcrumbs}
                    title={banner?.title}
                    subtitle={banner?.description}
                />
                <Address contact_info={contact_info} office_locations={office_locations}/>
                <ContactForm/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
