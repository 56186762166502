import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import { Modal, Form } from 'react-bootstrap';
import {Col, Container, Row} from "react-bootstrap";

import 'simplebar-react/dist/simplebar.min.css';

import Button from "../Button";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postForm} from "../../api/redux/Contact";

const CareerPopup = ({
                   show,
                   handleClose,
                   item,
                   title,
                   data,
                   subtitle,
               }) => {

    const popupData = data;
    const [winWidth, setWinWidth] = useState(true)
    const [selectedFileName, setSelectedFileName] = useState('');
    const [cv, setCv] = useState(null);
    const cvRef = useRef();

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setCv(file);
        setSelectedFileName(file.name);
    };
    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [])

    const dispatch = useDispatch();
    const responseData = useSelector(state => state.career);

    const {register, handleSubmit, formState, reset} = useForm({mode:'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const handleBlur = () => {
        // Perform onBlur logic here
    };

    const handleChange = () => {
        // Perform onChange logic here
    };

    const onSubmit = (e) => {
        let api_services = apiEndPoints.CAREER_FORM;

        var formData = new FormData();
        formData.append('your-designation', title);
        formData.append('your-name', e?.name);
        formData.append('your-email', e?.email);
        formData.append('your-phone', e?.phone);
        formData.append('your-message', e?.message);
        formData.append('file', cv);
console.log('formmm',e)
        if (e.email !== '' && e.name !== '' && e.phone !== '' && e?.file?.[0]!== '') {
            dispatch(postForm([api_services, formData]))
            toast.success('successfully Submitted');
            reset();
        }
    };
    let count=0;
    const onError = (errors) => {
        console.log(errors)
        Object.values(errors).forEach((error) => {
            count++;
        });
        if(count>0){
            toast.error('please fill out the correct inputs');
        }
        count=0;
    };
    useEffect(() => {
        if (responseData && responseData?.error !== '') {
            error(responseData?.error)
        }
        if (responseData && responseData?.success !== '') {
            // success(responseData?.success)
        }

    }, [responseData])



    return (

        <StyledModal>
            <Modal
                show={show}
                item={item}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className=" gph_modal cv-modal popup-version-one"
            >
                    <Modal.Body>
                        <Container>
                            <Row className={'for-close'} onClick={handleClose}>
                                <div  className="modal-close hover">
                                    <p>Close</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24.042" height="24.042" viewBox="0 0 24.042 24.042">
                                        <g id="Lines" transform="translate(-332.979 -31.979)">
                                            <line id="Line_3965" data-name="Line 3965" x1="30" transform="translate(355.606 33.394) rotate(135)" fill="none" stroke="black" stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_3966" data-name="Line 3966" x1="30" transform="translate(334.393 33.393) rotate(45)" fill="none" stroke="black" stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_3967" data-name="Line 3967" x1="30" transform="translate(355.607 33.394) rotate(135)" fill="none" stroke="black" stroke-linecap="round" stroke-width="2"/>
                                        </g>
                                    </svg>
                                </div>
                            </Row>
                        </Container>
                        <div className="modal-data">

                            <Col md={{span:10,offset:1}} className='modal-data__content'>
                                <div className={'pop-subtitle'}><p className={'split-up'}>{subtitle}</p></div>
                                <h3>{title}</h3>

                                <Form onSubmit={handleSubmit(onSubmit,onError)}>

                                    <input name={'spam_protector'} type='hidden'/>

                                    <Form.Control className={formState?.errors?.name?.message ? 'has-error' : ''}
                                                  {...register("name",{})}
                                                  type="text"
                                                  placeholder="Full Name*"/>

                                    <div className="form__phoneEmail">
                                        <Form.Control className={formState?.errors?.phone?.message ? 'has-error' : ''}
                                                      {...register("phone",{
                                                          required:{
                                                              value:true,
                                                              message:'please enter your phone first'
                                                          },
                                                          pattern:{
                                                              value:/^01[0-9]{9}$/,
                                                              message:'please enter a valid 11 digit phone number'
                                                          }
                                                      })}
                                                      type="number"
                                                      placeholder="Phone Number*"/>
                                        <Form.Control  className={formState?.errors?.email?.message ? 'has-error' : ''}
                                                       {...register("email",{
                                                           required:{
                                                               value:true,
                                                               message:'please enter your email'
                                                           },
                                                           pattern:{
                                                               value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i,
                                                               message:'please enter a valid email address'
                                                           }
                                                       })}
                                                       type="email"
                                                       placeholder="Email*"/>
                                    </div>
                                    {/*<Form.Control type="textarea" placeholder="Message"/>*/}
                                    <Form.Control className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                  {...register("message",{
                                                      required:{
                                                          value:true,
                                                          message:'please enter your email'
                                                      },

                                                  })}
                                                  type="textarea"
                                                  placeholder="Message"/>

                                    <div className="button-group">
                                        <div className="attachCvName">
                                            <div className="attach-cv">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26.268" height="24" viewBox="0 0 26.268 24" style={{ display: selectedFileName ? "none" : "block" }}>
                                                    <g id="Group_15866" data-name="Group 15866" transform="translate(-12252 -863)">
                                                        <path id="Path_6994" data-name="Path 6994" d="M129.092,23.561a4.6,4.6,0,0,0-3.3-1.463h-.057a4.7,4.7,0,0,0-3.2,1.384l-.009.008L110.8,35.42a2.822,2.822,0,0,0,0,4.1,2.878,2.878,0,0,0,2.044.892h0a2.9,2.9,0,0,0,2.061-.9l8.11-8.285a1.026,1.026,0,0,0-1.466-1.435l-8.106,8.281a.862.862,0,0,1-.6.283.828.828,0,0,1-.586-.283.791.791,0,0,1,0-1.212l11.718-11.914a2.444,2.444,0,0,1,3.658.058,2.571,2.571,0,0,1,.019,3.809,1.026,1.026,0,1,0,1.458,1.443,4.6,4.6,0,0,0-.019-6.695Z" transform="translate(12147.724 840.902)" fill="#fffdfb"/>
                                                        <path id="Path_6995" data-name="Path 6995" d="M21.594,71.964a1.026,1.026,0,0,0-1.45.023L10.019,82.444a4.609,4.609,0,0,1-3.266,1.435h0A4.56,4.56,0,0,1,3.49,82.418a4.374,4.374,0,0,1,0-6.476L13.6,65.836a1.026,1.026,0,0,0-1.45-1.451L2.037,74.493l0,0A6.745,6.745,0,0,0,0,79.046a6.537,6.537,0,0,0,2.029,4.814,6.653,6.653,0,0,0,4.721,2.07h0a6.644,6.644,0,0,0,4.728-2.048L21.617,73.414A1.026,1.026,0,0,0,21.594,71.964Z" transform="translate(12252 801.07)" fill="#fffdfb"/>
                                                    </g>
                                                </svg>
                                                <Form.Control
                                                    ref={cvRef}
                                                    type="file"
                                                    accept=".pdf"
                                                    id="resume-upload"
                                                    style={{ display: "none" }}
                                                    {...register('file')}
                                                    onChange={handleFileChange}
                                                />
                                                <Form.Label htmlFor="resume-upload" className="resume-upload-label" style={{ display: selectedFileName ? "none" : "block" }}>
                                                    Attach CV
                                                </Form.Label>
                                                {selectedFileName && (
                                                    <div className="file-name">
                                                        {selectedFileName}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div onClick={handleSubmit(onSubmit,onError)}>
                                            <Button color={'white'} background={'#323232'} text={'Apply Now'}/>
                                        </div>
                                    </div>
                                </Form>

                            </Col>


                        </div>
                    </Modal.Body>
            </Modal>
        </StyledModal>

    )
};


const StyledModal = styled.section`
  background-color: #F5F5F5;
  .modal-dialog {
    max-width: 100%!important;
  }
  h4{
    letter-spacing: 1.5px;
  }


`;


export default CareerPopup;
