import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import styled from "styled-components";
import Cta from "../../components/Cta";
import MediaCenter from "../../components/Home/MediaCenter";
import AboutPartex from "../../components/Home/AboutPartex";
import Tribute from "../../components/Home/Tribute";
import {Helmet, HelmetProvider} from "react-helmet-async";
import Banner from "../../components/Home/Banner";
import Gallery from "../../components/Home/OurConcerns";
import {fetchHome} from "../../api/redux/Home";


const Home = ({offset}) => {


    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.HOME
        dispath(fetchHome([api_url]))
    }, [])

    let getPost = useSelector(state => state.home);

    const page_title = getPost?.posts?.page_data?.[0]?.post_title;
    const home_slider = getPost?.posts?.page_data?.[0]?.home_slider;
    const home_counter = getPost?.posts?.page_data?.[0]?.home_counter;
    const home_remember = getPost?.posts?.page_data?.[0]?.home_remember;
    const home_concern = getPost?.posts?.page_data?.[0]?.home_concern;
    const home_cr = getPost?.posts?.page_data?.[0]?.home_cr;
    const home_media = getPost?.posts?.page_data?.[0]?.home_media;
    let data = getPost?.posts?.page_data?.[0];
    let innerBanner = data?.banner?.image;

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${data?.meta?.meta_title ? data?.meta?.meta_title : 'Partex Star Group'}`}</title>
                {
                    data?.meta?.meta_desc &&
                    <meta name="description" content={data?.meta?.meta_desc}/>

                }
                {
                    data?.meta?.og_title &&
                    <meta property="og:title" content={data?.meta?.og_title}/>

                }
                {
                    data?.meta?.og_desc &&
                    <meta property="og:description" content={data?.meta?.og_desc}/>
                }
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={innerBanner?.image}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledSection>
                <Banner data={home_slider}/>
                <AboutPartex data={home_counter}/>
                <Tribute offset={offset} data={home_remember}/>
                {/*<SisterConcernProduct data={home_concern}/>*/}
                {
                    home_concern &&
                    <Gallery offset={offset} data={home_concern}/>
                }

                    <Cta src={'/csr'}
                         imgSrc={home_cr?.back_image}
                         uppeÏrTitle={home_cr?.title}
                         subTitle={home_cr?.subtitle}
                         desc={home_cr?.description}
                         btntxt={'Learn More'}
                         btnColor={'#323232'}
                         btnBg={'#E8E8E8'}
                    />

                {
                    home_media &&
                    <MediaCenter data={home_media}/>
                }
            </StyledSection>
        </HelmetProvider>
    );
};

const StyledSection = styled.section`
  @media (max-width: 767px) {
    .home-banner .swipper-navigation-slider-custom .swiper-initialized {
      padding-right: 30%;
    }
  }

  .banner-section {
    position: relative;
    height: 100vh;
  }

  .banner-image {
    position: fixed;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  .content-section {
    position: relative;
    z-index: 1;
    /* Add any additional styling as needed */
  }

`;

export default Home;
