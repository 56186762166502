import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Select, {components} from "react-select";

const DealerList = () => {

    const [filterState, setFilterState] = useState('')
    const [searchValue, setSearchValue] = useState('')

    const handleFilter = (e) => {
        setFilterState(e)
    }

    const customStyles = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            transform: state.selectProps.menuIsOpen && "rotate(180deg)"
        }),
        control: (base, state) => ({
            ...base,
            backgroundColor: 'transparent',
            borderColor: "#F5F5F5",
            borderRadius: 50,
            paddingLeft: 20,
            paddingRight: 20,
            // height: 60,
            boxShadow: state.isFocused ? null : null,

            "&:hover": {
                // borderColor: "#222222",
                cursor: 'pointer'
            },
        }),
        option: (styles, state) => ({
            ...styles,
            // backgroundColor: state.isSelected ? 'rgb(50, 50, 50)' : '#FFFFFF',
            color: state.isSelected ? '#ffffff' : 'rgb(50, 50, 50)',
            "&:hover": {
                backgroundColor: "transparent",
                // color: '#ffffff',
                cursor: 'pointer'
            },
        }),
        indicatorContainer: (base, state) => ({
            ...base,
            transform: state.selectProps.menuIsOpen && "rotate(180deg)"
        })
    };


    const CaretDownIcon = () => {
        return <img src={'/images/static/indicator-black.svg'} style={{width: 20, height: 10}}/>;
    };


    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon/>
            </components.DropdownIndicator>
        );
    };

    const options = [
        {value: 'Category 1', label: 'Category 1'},
        {value: 'Category 2', label: 'Category 2'},
        {value: 'Category 3', label: 'Category 3'}
    ]


    return (
        <StyledDealerSearch >
            <Container>
                <Row>
                    <Col md={3} className={'filter '}>
                        <Select styles={customStyles} onChange={oc => handleFilter(oc.value)}
                            // defaultValue={selectedOption}

                                options={options}
                                components={{DropdownIndicator}}
                                classNamePrefix={'react-select'}
                                defaultValue={{label: 'Plain Veneered Flush Door', value: 'Plain Veneered Flush Door'}}
                        />
                    </Col>
                    <Col md={{span:3, offset:6}} className={'filter-search'}>
                        <form>
                            <div className="search-input">
                                <div className="global-image">
                                    <img src="/images/static/search-black.svg" alt="seach-icon"/>
                                </div>
                                <input type="text" value={searchValue} onChange={(e)=>{setSearchValue(e.target.value)}} placeholder="Search" className="form-control"/>
                                {/*<button type="submit">Search</button>*/}
                            </div>
                        </form>

                    </Col>
                </Row>
            </Container>
        </StyledDealerSearch>
    );
};

const StyledDealerSearch = styled.section`
  background: #F5F5F5;
  padding: 20px 0;

  .filter-search {
    cursor: pointer;
    position: relative;

    form {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: 1px solid #323232;
      border-radius: 50px;
      padding: 0 15px;
      z-index: 1;
      background-color: transparent;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      .search-input {
        display: flex;
        align-items: center;
        height: 100%;

        .form-control {
          width: 100%;
          height: 100%;
          padding: 14px 0px 6px 0px;
          font-size: 20px;
          line-height: 24px;
          font-weight: 500;
          color: #ffffff;
          background-color: transparent;
          border: none;
          border-radius: 0;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          margin: 0 0 0 30px;
        }

        input{
          color:black!important;
          &::placeholder{
            color: #323232;
          }
        }
      }

      .global-image {
        position: absolute;
        height: 18px;
        width: 18px;
        margin: 0px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          inset: 0px;
        }
      }
    }

    @media (max-width: 767px) {
      height: 55px;
      margin-top: 30px;

      form {
        right: 15px;
        left: 15px;
        width: calc(100% - 30px);
      }
    }

  }

  .react-select {

    &__menu-list {
      padding: 0;
    }

    &__menu {
      border-radius: 0;
      z-index: 10;
    }

    &__control {
      padding: 12px 20px 8px 0px;
    }

    &__input-container {
      margin: 0;
    }

    &__single-value {
      color: #323232;
      margin: 0;
      font-size: 20px;
      line-height: 24px;
      font-weight: 300;
    }

    &__indicator {
      //color:#323232;
    }

    &__option {
      background-color: #ffffff;
      border-radius: 0;
      border-bottom: 1px solid rgba(50, 50, 50, 0.2);
      font-size: 20px;
      line-height: 24px;
      font-weight: 300;

      &:hover {
        background-color: rgb(50, 50, 50);
        color: #ffffff;
      }

      &--is-focused {
        //color: white;
        //background-color: black;
      }

      &--is-selected {
        font-weight: 500;
        background-color: rgba(50, 50, 50, 0.5);
      }
    }

    &__indicator-separator {
      background-color: transparent;
    }

  }


`;

export default DealerList;
