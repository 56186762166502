import React, {useEffect, useState} from 'react';
import {Container, Row, Col} from "react-bootstrap";

import styled from "styled-components";
import {Link} from "react-router-dom";
import reactHtmlParser from "react-html-parser";



const ProductSpecification = ({data, isIndustry}) => {

    const [activeTab, setActiveTab] = useState('');
    const [activeTabTitle, setActiveTabTitle] = useState('');

    function handleClickTabs(e, f) {
        setActiveTab(e)
        setActiveTabTitle(f)
        // setActiveTabTitle()
    }

    // filter data
    useEffect(() => {
        setTimeout(() => {
            if (document.querySelector('.product-specification-ul li')) {
                if (document.querySelector('.product-specification-ul .active')) {
                    document.querySelector('.product-specification-ul .active').classList.remove('active')
                }
                document.querySelector('.product-specification-ul li').classList.add('active')
            }
        }, 200)

        if (document.querySelector('.product-specification-ul li')) {
            let AlltheLi = document.querySelectorAll('.product-specification-ul li');
            AlltheLi.forEach(i => {
                i.addEventListener('click', () => {
                    document.querySelector('.product-specification-ul li').classList.remove('active')
                })
            })
        }

        setActiveTab('')
        setActiveTabTitle('')

    })


    return (
        <StyledProductSpecification className={'ProductSpecWrapper  pb-200'}>
            <Container>

                <Row className={'catalogs'}>
                    <Col md={4} className={'pdf-title'}>
                        {data?.title && <h3>{reactHtmlParser(data?.title)}</h3>}
                    </Col>

                    <Col md={{spna:6,offset:2}}>
                        {data?.description && <p className={''}>{reactHtmlParser(data?.description)}</p>}
                    </Col>
                </Row>



                <Row>
                    {data?.catalog?.map((item, index) => (
                        <Col md={4} className={'catalogs__body'}>
                            <div className={'pdf'}>
                                <div className={'pdf__body-text'}>
                                    <p>{reactHtmlParser(item?.title)}</p>
                                </div>

                                <div className={'pdf__body-svg'}>
                                    <a href={item?.file} target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                        <g id="Mask_Group_401" data-name="Mask Group 401" transform="translate(-394 -6810)" >
                                            <circle id="Ellipse_611" data-name="Ellipse 611" cx="22" cy="22" r="22" transform="translate(394 6810)" fill="#fff"/>
                                            <circle id="Ellipse_612" data-name="Ellipse 612" cx="22" cy="70" r="22" transform="translate(394 6810)" fill="#E50019"/>
                                            <g id="surface1" transform="translate(407.541 6821.953)">
                                                <path id="Path_6995" data-name="Path 6995" d="M11.328.155A.486.486,0,0,0,10.979,0H3.14A2.651,2.651,0,0,0,.5,2.635V17.458a2.652,2.652,0,0,0,2.64,2.636H13.779a2.652,2.652,0,0,0,2.64-2.636V5.688a.522.522,0,0,0-.141-.34Zm.141,1.558,3.315,3.48H12.629a1.156,1.156,0,0,1-1.16-1.155Zm2.31,17.41H3.14a1.681,1.681,0,0,1-1.67-1.665V2.635A1.681,1.681,0,0,1,3.14.971H10.5V4.038a2.123,2.123,0,0,0,2.131,2.126h2.82V17.458A1.678,1.678,0,0,1,13.779,19.123Zm0,0" transform="translate(0 0)" fill="#323232"/>
                                                <path id="Path_6996" data-name="Path 6996" d="M97.646,401.934H89.657a.485.485,0,1,0,0,.971h7.994a.485.485,0,1,0,0-.971Zm0,0" transform="translate(-85.192 -386.16)" fill="#323232"/>
                                                <path id="Path_6997" data-name="Path 6997" d="M121.6,177.645a.482.482,0,0,0,.709,0l2.844-3.053a.484.484,0,1,0-.709-.66l-2,2.15v-5.3a.485.485,0,0,0-.971,0v5.3l-2-2.15a.484.484,0,0,0-.709.66Zm0,0" transform="translate(-113.492 -163.614)" fill="#323232"/>
                                            </g>
                                        </g>
                                    </svg>
                                    </a>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>

            </Container>
        </StyledProductSpecification>
    );
};

const StyledProductSpecification = styled.section`
  .pdf {
    background-color: #323232;
    display: flex;
    justify-content: space-between;

    &__body-text {
      display: flex;
      align-items: center;
      p {
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        color: #FFFFFF;
        padding-left: 30px;
      }
    }

    &__body-svg {
      padding: 30px;

      svg {

        cursor: pointer;
        border-radius: 50px;

        #Ellipse_612 {
          transition: all 0.5s ease;
        }

        &:hover {
          #Ellipse_612 {
            cy: 22;
            r: 22;
            transition: all 0.5s ease;
          }

          #Path_6995, #Path_6996, #Path_6997 {
            fill: #fff;
          }
        }
      }
    }
  }

  .catalogs {
    padding-bottom: 80px;

    &__body {
      padding-bottom: 30px;
    }
    &__body:nth-last-child(-n+3){
      padding-bottom: 0;
    }
  }


  @media (max-width: 767px) {
    .catalogs {
      padding-bottom: 40px;

      &__body {
        padding-bottom: 20px;
      }

      &__body:nth-last-child(2) {
        padding-bottom: 20px;
      }

      &__body:nth-last-child(3) {
        padding-bottom: 20px;
      }

      .pdf-title {
        padding-bottom: 40px;
      }
    }
  }


  .pdf-title {
    h3 {
      span {
        color: #E50019;
      }
    }
  }


  .report-tab {
    iframe {
      border: 0;
      min-height: 550px;
    }

    .report-tab__single {
      background-color: #323232;
      padding: 30px;
      position: relative;
      height: 100%;
      min-height: 98px;

      h5 {
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        color: #FFFFFF;
        width: calc(100% - 80px);
      }

      p {
        font-size: 12px;
        font-weight: bold;
        line-height: 18px;
        width: calc(100% - 80px);
      }

      .download-icon {
        right: 30px;
        position: absolute;
        height: 50px;
        width: 50px;
        background-color: rgb(34, 31, 31);
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        border-radius: 50%;
        top: 22px;
        cursor: pointer;

        a {
          position: absolute;
          height: 100%;
          width: 100%;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          z-index: 3;

          &:after {
            content: "";
            position: absolute;
            height: 0px;
            width: 0px;
            inset: 0px;
            margin: auto;
            background-color: rgb(237, 27, 52);
            transition: all 0.5s ease 0s;
            border-radius: 19px;
          }
        }
      }
    }
  }


`;

export default ProductSpecification;