import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Link, Route, useHistory, useLocation, useParams} from 'react-router-dom';

import {HelmetProvider, Helmet} from 'react-helmet-async';
import InnerBanner from '../../components/InnerBanner';
import ListPdfDownloader from '../../components/ProductList/ListPdfDownloader';
import ProductListDropDown from '../../components/ProductList/ProductListDropDown';
import ProductBody from '../../components/ProductList/ProductBody';
import {useDispatch, useSelector} from 'react-redux';
import {apiEndPoints} from '../../api/network/apiEndPoints';
import {fetchTvc} from '../../api/redux/Tvc';
import {fetchProductlist} from '../../api/redux/ProductList';
import {ApiParam as ApiParams} from '../../api/network/apiParams';
import {fetchConcernDetail} from '../../api/redux/Concern';
import reactHtmlParser from 'react-html-parser';
import InnerBannerThree from '../../components/InnerBannerThree';

const ProductList = () => {
    const location = useLocation();
    const history = useHistory();

    const dispatch = useDispatch();

    // Clear state on component unmount


    // Update the useEffect to listen to changes in the location object
    useEffect(() => {
        const api_url = apiEndPoints.PRODUCTLIST;
        const param = {};

        // Fetch data when the component mounts initially
        dispatch(fetchProductlist([api_url, param]));
    }, [dispatch, history, location.search]);

    let getPost = useSelector((state) => state.productlist); //store
    const allProduct = getPost?.posts?.[0]?.list;
    const searchParams = new URLSearchParams(location.search);
    const productType = searchParams.get('product_type');

    const ProductData = allProduct?.filter((i) => i?.product_type_slug === productType);
    let data = getPost?.posts?.[0];
    let innerBanner = data?.banner?.image;
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${data?.meta?.meta_title ? data?.meta?.meta_title : 'Partex Star Group'}`}</title>
                {
                    data?.meta?.meta_desc &&
                    <meta name="description" content={data?.meta?.meta_desc}/>

                }
                {
                    data?.meta?.og_title &&
                    <meta property="og:title" content={data?.meta?.og_title}/>

                }
                {
                    data?.meta?.og_desc &&
                    <meta property="og:description" content={data?.meta?.og_desc}/>
                }
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={innerBanner?.image}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>
                {/*<ProductListDropDown/>*/}
                <ProductBody data={ProductData}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
    
`;

export default ProductList;
