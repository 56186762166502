import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import Button from "../Button";
import {Img} from "../Img";
import Select, {components} from "react-select";
import {useLocation} from "react-router-dom";
import reactHtmlParser from "react-html-parser";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import ProductPopUp from "../Product/ProductPopUp";


const ProductListing = ({data}) => {
    ScrollTrigger.refresh();
    const [filterState, setFilterState] = useState('')
    const [searchValue, setSearchValue] = useState('')

    const [itemsToShow, setItemsToShow] = useState(8);
    const [isCompleted, setIsCompleted] = useState(false);
    const [filteredData, setFilteredData] = useState();
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const productType = searchParams.get('product_type');

    const [show, setShow] = useState(false);
    const [winWidth, setWinWidth] = useState(true);
    const [popupId, setPopupId] = useState();
    const [selectedData, setSelectedData] = useState({});
    const handleClose = () => setShow(false);
    const handleShow = (item) => {
        setShow(true);
        setSelectedData(item);
    }

    // Filter out duplicate values based on the "value" property
    const uniqueTypes = Array.from(new Set(data?.map((option) => option.product_sub_type)))?.map((value) => data.find((option) => option.product_sub_type === value));

    const options = [(uniqueTypes?.map((element) => ({
        value: element?.product_sub_type,
        label: element?.product_sub_type
    })) || []),];
    const handleFilter = (e) => {
        setFilterState(e)
    }

    useEffect(() => {
        let finalData = data;
        if (filterState) {
            finalData = data?.filter((i) => i?.product_sub_type === filterState);
        }
        console.log('FinalData', finalData);
        setFilteredData(finalData);
    }, [filterState, data]);


    useEffect(() => {
        const finalData = data?.filter(
            (item) =>
                (!filterState || item.product_sub_type === filterState) &&
                (item.post_name.toLowerCase().includes(searchValue.toLowerCase()) ||
                    item.product_sub_type.toLowerCase().includes(searchValue.toLowerCase()))
        );
        setFilteredData(finalData);
    }, [filterState, searchValue, data]);

    const handleLoadMore = () => {
        setItemsToShow(prevItemsToShow => prevItemsToShow + 8);
        if (itemsToShow + 8 >= filteredData.length) {
            setIsCompleted(true);
        } else {
            setIsCompleted(false);
        }
    };

    const customStyles = {
        dropdownIndicator: (base, state) => ({
            ...base, transition: "all .2s ease", transform: state.selectProps.menuIsOpen && "rotate(180deg)"
        }), control: (base, state) => ({
            ...base,
            backgroundColor: 'transparent',
            borderColor: "rgb(50, 50, 50)",
            borderRadius: 50,
            paddingLeft: 20,
            paddingRight: 20, // height: 60,
            boxShadow: state.isFocused ? null : null,

            "&:hover": {
                // borderColor: "#222222",
                cursor: 'pointer'
            },
        }), option: (styles, state) => ({
            ...styles, // backgroundColor: state.isSelected ? 'rgb(50, 50, 50)' : '#FFFFFF',
            color: state.isSelected ? '#ffffff' : 'rgb(50, 50, 50)', "&:hover": {
                backgroundColor: "transparent", // color: '#ffffff',
                cursor: 'pointer'
            },
        }), indicatorContainer: (base, state) => ({
            ...base, transform: state.selectProps.menuIsOpen && "rotate(180deg)"
        })
    };


    const CaretDownIcon = () => {
        return <img src={'/images/static/indicator-black.svg'} style={{width: 20, height: 10}}/>;
    };


    const DropdownIndicator = props => {
        return (<components.DropdownIndicator {...props}>
            <CaretDownIcon/>
        </components.DropdownIndicator>);
    };

    return (
        <StyledComponent className={'pt-200 pb-200'}>
        <Container>
            <Row>
                <Col md={3} className={'filter '}>
                    <Select styles={customStyles} onChange={oc => handleFilter(oc.value)}
                        // defaultValue={selectedOption}

                            options={options?.[0]?.slice()?.reverse()}
                            components={{DropdownIndicator}}
                            classNamePrefix={'react-select'}
                            defaultValue={{label: 'Select Category', value: 'Select Category'}}
                    />
                </Col>
                <Col md={{span: 3, offset: 6}} className={'filter-search'}>
                    <form>
                        <div className="search-input">
                            <div className="global-image">
                                <img src="/images/static/search-black.svg" alt="search-icon"/>
                            </div>
                            <input
                                type="text"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                                placeholder="Search"
                                className="form-control"
                            />
                            {/*<button type="submit">Search</button>*/}
                        </div>
                    </form>
                </Col>
            </Row>
            <Row>

                {filteredData?.length > 0 && filteredData?.slice(0, itemsToShow)?.map?.((i) => (
                    <Col md={3} className={'product-list'} onClick={() => handleShow(i)}>

                            <div className={'product-list__image-wrapper'}>
                                <Img src={i?.post_feature_image}/>
                            </div>

                        <div className={'legacy-left__image-text'}>
                            <p>{reactHtmlParser(i?.product_sub_type)}</p>
                            <h3>{reactHtmlParser(i?.post_title)}</h3>
                        </div>
                    </Col>))}
            </Row>
            <Row>
                {itemsToShow < filteredData?.length && (<Col>
                    <div className={'product-list__button'} onClick={handleLoadMore}>
                        <Button color={'white'} text={'Load More'} background={'#323232'}/>
                    </div>
                </Col>)}
            </Row>
        </Container>
            <ProductPopUp show={show} handleClose={handleClose} data={selectedData}/>
    </StyledComponent>
    );
};

const StyledComponent = styled.section`

  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 150px;
    width: 100%;
    background: #323232;
  }

  .filter-search {
    cursor: pointer;
    position: relative;

    form {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: 1px solid #323232;
      border-radius: 50px;
      padding: 0 15px;
      z-index: 1;
      background-color: transparent;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      .search-input {
        display: flex;
        align-items: center;
        height: 100%;

        .form-control {
          width: 100%;
          height: 100%;
          padding: 14px 0px 6px 0px;
          font-size: 20px;
          line-height: 24px;
          font-weight: 500;
          color: #ffffff;
          background-color: transparent;
          border: none;
          border-radius: 0;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          margin: 0 0 0 30px;
        }

        input {
          color: black !important;

          &::placeholder {
            color: #323232;
          }
        }
      }

      .global-image {
        position: absolute;
        height: 18px;
        width: 18px;
        margin: 0px;
      

        img {
          cursor: pointer;
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          inset: 0px;
        }
      }
    }

    @media (max-width: 767px) {
      height: 55px;
      margin-top: 30px;

      form {
        right: 15px;
        left: 15px;
        width: calc(100% - 30px);
      }
    }

  }

  .react-select {

    &__menu-list {
      padding: 0;
    }

    &__menu {
      border-radius: 0;
      z-index: 10;
    }

    &__control {
      padding: 12px 20px 8px 8px;
    }

    &__input-container {
      margin: 0;
    }

    &__single-value {
      color: #323232;
      margin: 0;
      font-size: 20px;
      line-height: 24px;
      font-weight: 300;
    }

    &__indicator {
      //color:#323232;
    }

    &__option {
      background-color: #ffffff;
      border-radius: 0;
      border-bottom: 1px solid rgba(50, 50, 50, 0.2);
      font-size: 20px;
      line-height: 24px;
      font-weight: 300;

      &:hover {
        background-color: #E50019;
        color: #ffffff;
      }

      &--is-focused {
        //color: white;
        //background-color: black;
      }

      &--is-selected {
        font-weight: 500;
        background-color: rgba(229, 0, 25, 0.5);
      }
    }

    &__indicator-separator {
      background-color: transparent;
    }

  }

  .row:first-child {
    padding-bottom: 100px;
  }

  .product-list {
    padding-bottom: 30px;

    &__image-wrapper {
      //position: relative;
      //padding-top: calc(500 / 230 * 100%);
      .global-image{
        position: unset;
        cursor: pointer;
      }
      img {
        position: unset;
        border: 20px solid #F5F5F5;
      }
    }
  

    &__button {
      padding-top: 100px;

      .dc-btn {
        display: flex;
        justify-content: center;
      }
    }
  }

  .legacy-left {
    &__image-text {
      margin-top: 30px;

      h3 {
        font-size: 24px;
        line-height: 28px;
        font-weight: 500;
      }
    }
  }

  @media (max-width: 767px) {
    .product-list {
      width: 50%;
    }
    .filter{
      padding-top: 40px;
    }
  }

`;

export default ProductListing;
