import React, {useLayoutEffect} from 'react';
import styled from 'styled-components';
import * as Website from './animations/website.json'
import Lottie from 'react-lottie';

import {gsap} from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

const AnimatedIconMoon = ({data}) => {
    const getData = useSelector((state) => state);
    const location = useLocation();
    const website = {
        loop: true,
        autoplay: true,
        animationData: Website,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    // sticky navbar
    gsap.registerPlugin(ScrollTrigger);

    useLayoutEffect(() => {
        const col4Element = document.querySelector('.website-icon');

        if (!col4Element) return;

        // Check if the screen width is greater than 1024px (desktop)
        if (window.innerWidth > 991) {
            const animation = gsap.to(col4Element, {
                scrollTrigger: {
                    trigger: col4Element,
                    start: 'top top',
                    end: 'max',
                    pin: true,
                    pinSpacing: false
                }
            });

            return () => {
                // Cleanup ScrollTrigger instances when the component unmounts
                ScrollTrigger.getAll().forEach(trigger => trigger.kill());
                animation.kill();
            };
        }
    }, [location.pathname,getData]);


    return (
        <>
            <StyledMessage className={'website-icon'}>
                <a href={data} target={'_blank'} className={'theme-button'}>
                    <div className="website">
                        <Lottie options={website} height={50} width={50}/>
                    </div>
                    <span>Visit Website</span>
                </a>
            </StyledMessage>
        </>
    );

};
const StyledMessage = styled.div`
  z-index: 101;

  .theme-button {
    height: 50px;
    width: 50px;
    border-top-left-radius: 35px;
    border-bottom-left-radius: 35px;
    background: #E50019;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 1s all cubic-bezier(.25, .74, .22, .99);
    overflow: hidden;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    margin-left: auto;
    position: absolute;
    top: 300px;
    right: 0px;
    z-index: 101;


    @media (min-width: 1900px) {
      top: 500px;
    }

    @media (max-width: 767px) {
    }

    .website {
      opacity: 1;
      transition: 1s all cubic-bezier(.25, .74, .22, .99);
      position: absolute;
      margin: auto;
      height: 100%;
      width: 50px;
      top: 0;
      left: 0;
      right: unset;
      bottom: 0;
      display: flex;
      align-items: center;
      //justify-content: center;
    }

    span {
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      color: #FFFFFF;
      height: 20px;
      margin-left: 30px;
      overflow: hidden;
      word-break: break-all;
      opacity: 0;
    }

    &:hover {
      span {
        opacity: 1;
      }
    }
  }

  //background: red;

  a {
    //background: #00aced;
    //height: 100%;
    //display: inline;

    &:hover {
      width: 185px;
    }
  }
`;


export default AnimatedIconMoon;
