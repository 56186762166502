import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Link, Route, useParams} from "react-router-dom";

import {HelmetProvider, Helmet} from 'react-helmet-async';
import InnerBannerTwo from "../../components/InnerBannerTwo";
import Menu from "../../components/Product/Menu";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchConcernDetail} from "../../api/redux/Concern";
import FloatingButton from "../../components/FloatingButton";
import {ApiParam as ApiParams} from "../../api/network/apiParams";
import {fetchDealerList} from "../../api/redux/DealerList";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const Product = ({offset}) => {
    const dispath = useDispatch();
    let {slug} = useParams();
    // ScrollTrigger.refresh();

    // for dealer list

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.DEALERLIST
        // Update the URL with the selected values
        dispath(fetchDealerList([api_url]))
    }, [dispath])



    let getPost = useSelector(state => state.dealerlist); //store
    // const founderImage = getPost?.posts?.page_data?.[0]?.founder;
    const dealerlist = getPost?.posts?.list;




    // api call
    useEffect(() => {
        let api_url = apiEndPoints?.CONCERN
        dispath(fetchConcernDetail([api_url + `/${slug}`]))
    }, [slug])

    // let getPost = useSelector(state => state.concern);
    const getData = useSelector(store => store?.concern);

    const product_type = getData?.detail?.[0]?.product_one?.type


    const data = getData?.detail?.[0];
    const banner = data?.banner;



    // Define the images for different viewports
    const largeImage = banner?.image;
    const mediumImage = banner?.image_tab;
    const smallImage = banner?.image_mobile;

    // Determine the viewport size and set the image accordingly
    let imgUrl;
    if (window.innerWidth >= 992) {
        imgUrl = largeImage;
    } else if (window.innerWidth >= 768) {
        imgUrl = mediumImage;
    } else {
        imgUrl = smallImage;
    }


    useEffect(() => {
        const body = document.body;
        const scrollUp = 'nav-bar';
        const scrollDown = 'nav-bar-down';
        let lastScroll = 0;
        let howMuchScroll = 900;
        if (window.screen.width < 991) {
            howMuchScroll = 0;
        } else {
            howMuchScroll = 900;
        }

        window.addEventListener('scroll', () => {
            let currentScroll = window.pageYOffset;
            if (currentScroll <= howMuchScroll) {
                body.classList.remove(scrollUp);
                return;
            }
            if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
                // down
                body.classList.remove(scrollUp);
                body.classList.add(scrollDown);
            } else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) {
                // up
                body.classList.remove(scrollDown);
                body.classList.add(scrollUp);
            }
            lastScroll = currentScroll;
        });
    }, [getData]);


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${data?.post_title} | Partex Star Group`}</title>
                <meta name="description" content="Description"/>
            </Helmet>

            <StyledComponent>

                { banner?.link !== "" && <FloatingButton data={banner?.link} />}

                {
                    data &&
                    <InnerBannerTwo
                        img={imgUrl}
                        breadcrumbs={banner?.breadcrumbs}
                        title={banner?.title}
                        subtitle={banner?.description}
                        text={banner?.text_left}
                    />
                }
                {
                    data &&
                    <Menu link={banner?.link} offset={offset} data={data} dealerList={dealerlist} productType={product_type}/>
                }
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
`;

export default Product;
