import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

// Styled Components
const SliderWrapper = styled.div`
  margin-bottom: -7px;
`;

const SlideWrapper = styled.div`
  padding-top: calc(768 / 1366 * 100%);
  overflow: hidden;
  position: relative;
`;

const SlideImage = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const SliderButton = styled.button`
  position: absolute;
  height: 100%;
  width: 50%;
  //left: 0;
  //right: 0;
  top: 0;
  bottom: 0;
  z-index: 2000;
  cursor: none;
  transform: none;
  /* Add styling for the left and right buttons */
  
  &.slick-prev {
    left: 0;
    cursor: none;
  }
  
  &.slick-next {
    right: 0;
    cursor: none;
  }
  
  &:before {
    content: none;
  }
  
  .slick-slider {
    position: relative;
  }
`;




const SliderComponent = ({offset, data}) => {
    const [current, setCurrent] = useState(0);

    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        prevArrow: <SliderButton id="gallery-prev" />,
        nextArrow: <SliderButton id="gallery-next" />,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
        beforeChange: (current, next) => {
            setCurrent(next);
        }
    };

    /*useEffect(() => {
        console.log(current);
    }, [current]);*/

    useEffect(() => {
        const boxA = document.querySelectorAll('.slick-slider .slick-arrow, #institutions-gallery-prev');
        const leftBtn = document.querySelector('.slick-slider .slick-prev');
        const rightBtn = document.querySelector('.slick-slider .slick-next');

        const handleMouseEnter = (event) => {
            const follower = document.querySelector('.cursor-follower');
            follower?.classList.add('visible-it');
        };

        const handleMouseLeave = (event) => {
            const follower = document.querySelector('.cursor-follower');
            follower?.classList.remove('visible-it');
        };

        const handleLeftBtnEnter = (event) => {
            const follower = document.querySelector('.cursor-follower');
            follower?.classList.add('left-btn');
        };

        const handleLeftBtnLeave = (event) => {
            const follower = document.querySelector('.cursor-follower');
            follower?.classList.remove('left-btn');
        };

        const handleRightBtnEnter = (event) => {
            const follower = document.querySelector('.cursor-follower');
            follower?.classList.add('right-btn');
        };

        const handleRightBtnLeave = (event) => {
            const follower = document.querySelector('.cursor-follower');
            follower?.classList.remove('right-btn');
        };

        boxA.forEach((a) => {
            a?.addEventListener('mouseenter', handleMouseEnter);
            a?.addEventListener('mouseleave', handleMouseLeave);
        });

        leftBtn?.addEventListener('mouseenter', handleLeftBtnEnter);
        leftBtn?.addEventListener('mouseleave', handleLeftBtnLeave);

        rightBtn?.addEventListener('mouseenter', handleRightBtnEnter);
        rightBtn?.addEventListener('mouseleave', handleRightBtnLeave);

        return () => {
            boxA.forEach((a) => {
                a?.removeEventListener('mouseenter', handleMouseEnter);
                a?.removeEventListener('mouseleave', handleMouseLeave);
            });

            leftBtn?.removeEventListener('mouseenter', handleLeftBtnEnter);
            leftBtn?.removeEventListener('mouseleave', handleLeftBtnLeave);

            rightBtn?.removeEventListener('mouseenter', handleRightBtnEnter);
            rightBtn?.removeEventListener('mouseleave', handleRightBtnLeave);
        };
    }, [data]);

    // console.log(data?.slider?.leng)

    return (

        <StyledComponent className='pb-200'>
            <div className={'counter-name'} style={{paddingLeft:offset+'px'}}>
                <p className={'sec-title'}>Gallery</p>
                <p className={'counter-no'}><span>{current+1}</span>/<span>{data?.slider?.length > 0 ? data?.slider?.length : 0}</span></p>
            </div>
            <SliderWrapper>
                {data?.slider?.length > 0 &&
                    <Slider {...settings}>
                        {data?.slider?.map((item, index) => (
                            <SlideWrapper key={index}>
                                <SlideImage src={item?.image ? item?.image : 'images/static/blur.jpg'} alt="" />
                            </SlideWrapper>
                        ))}

                    </Slider>
                }

            </SliderWrapper>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  
  .counter-name {
    position: absolute;
    z-index: 2;
    top: 80px;
    
    .sec-title {
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      padding: 0 0 10px 0;
      color: #FFFFFF;
    }
    
    .counter-no {
      font-size: 72px;
      line-height: 84px;
      font-weight: 400;
      padding: 0;
      color: #FFFFFF;
    }

    @media (max-width: 767px) {
      top: 62px;
      padding: 15px !important;
    }
  }
  
`;

export default SliderComponent;
